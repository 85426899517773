import 'flagpack/dist/flagpack.css';
import { useTranslate } from 'services/i18n/useTranslate';
import { Tooltip } from 'components/common/Tooltip';
import type { FlattenedTranslationKeys } from 'types/translations';

export type FlagProps = {
  code: string;
  type?: 'default' | 'rounded' | 'square';
  size?: 'default' | 'medium' | 'large';
  showTooltip?: boolean;
};

export const Flag = ({ code, type = 'default', size = 'default', showTooltip = true }: FlagProps) => {
  const t = useTranslate();
  if (!code) {
    return null;
  }

  const element = <span className={`fp fp-${size} fp-${type} ${code.toLowerCase()}`} />;

  return showTooltip ? (
    <Tooltip content={t(`countries.${code.toUpperCase()}` as FlattenedTranslationKeys)} delayDuration={125}>
      {element}
    </Tooltip>
  ) : (
    element
  );
};
