import { createFileRoute } from '@tanstack/react-router';
import { HomeScene } from 'components/scenes/Home';

export const Route = createFileRoute('/_app/')({
  component: HomeScene,
  meta: () => [
    {
      name: 'pageTitle',
      content: 'home.page_title',
    },
    {
      name: 'metaTitle',
      content: 'home.page_title',
    },
    {
      name: 'pageDescription',
      content: 'home.page_description',
    },
  ],
});
