import { useCallback } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import { type components, type paths } from 'types/schemas/api-schema';
import { api, type APIError } from '.';

type EnrichmentResponse =
  paths['/enrichments/{type}/current']['get']['responses']['200']['content']['application/json'];
type EnrichmentError = APIError<
  paths['/enrichments/{type}/current']['get']['responses']['400']['content']['application/json']
>;

type CombinedError = Error | EnrichmentError;
type QueryResult = UseQueryResult<EnrichmentResponse, CombinedError>;

const enrichmentTypes = ['geo', 'intel'] as const;
type UsedEnrichmentType = (typeof enrichmentTypes)[number];

export const usePlatformEnrichments = () => {
  const combineResults = useCallback((results: QueryResult[]) => {
    return {
      data: results.reduce<Partial<Record<UsedEnrichmentType, EnrichmentResponse>>>((acc, result, index) => {
        const type = enrichmentTypes[index];
        if (type && result.data) {
          acc[type] = result.data;
        }
        return acc;
      }, {}),
      isLoading: results.some((result) => result.isLoading),
      isError: results.some((result) => result.isError),
      error: results.find((result) => result.error)?.error as CombinedError | undefined,
    };
  }, []);

  return useQueries({
    queries: enrichmentTypes.map((type) => ({
      queryKey: ['enrichments', 'platform', type],
      queryFn: () => {
        return api.url(`/enrichments/${type}/current`).get().json<EnrichmentResponse>();
      },
    })),
    combine: combineResults,
  });
};

export const usePlatformEnrichment = (type: UsedEnrichmentType) => {
  return useQuery<EnrichmentResponse, CombinedError>({
    queryKey: ['enrichments', 'platform', type],
    queryFn: () => {
      return api.url(`/enrichments/${type}/current`).get().json<EnrichmentResponse>();
    },
  });
};

type PlatformEnrichment = {
  type: components['schemas']['enrichmentType'];
  profile: components['schemas']['idString'];
  schema: components['schemas']['idString'];
  dataset: string | ArrayBuffer;
};

export const useUpdatePlatformEnrichment = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    APIError<
      paths['/enrichments/{type}/{profile}/dataset/{schema}']['post']['responses']['400']['content']['application/json']
    >,
    PlatformEnrichment
  >({
    mutationFn: async ({ type, profile, schema, dataset }: PlatformEnrichment) => {
      try {
        await api
          .url(`/enrichments/${type}/${profile}/dataset/${schema}`)
          .headers({
            'Content-Type': 'application/octet-stream',
          })
          .body(dataset)
          .post()
          .res();

        await queryClient.invalidateQueries({
          queryKey: ['enrichments', 'platform', type],
        });
      } catch (error) {
        if ((error as { json?: any }).json) {
          throw error;
        } else {
          const errorResponse = new Error('The dataset is invalid.') as Error & {
            json: {
              errors?: {
                status: number;
                message: string;
                errorId?: string;
                errorCode?: string;
                errorMsg?: string;
                context?: string;
              }[];
            };
          };

          errorResponse.json = {
            errors: [
              {
                status: 413,
                message: 'The dataset is invalid.',
                errorId: 'dataset_invalid',
                errorCode: 'INVALID_DATASET',
                errorMsg: 'The dataset is invalid.',
              },
            ],
          };

          throw errorResponse;
        }
      }
    },
  });
};
