import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import * as z from 'zod';

import { Tenants } from 'components/scenes/Settings/Platform/Tenants';

export const Route = createFileRoute('/_app/settings/platform/tenants')({
  component: Tenants,
  validateSearch: zodSearchValidator(
    z
      .object({})
      .or(
        z.object({
          tenantSidebarIntent: z.enum(['create']),
        }),
      )
      .or(
        z.object({
          tenantSidebarIntent: z.enum(['edit']),
          tenantId: z.string(),
          tab: z.enum(['settings', 'users', 'pipelines']).catch('settings'),
        }),
      ),
  ),
});
