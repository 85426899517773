import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Text } from 'components/common/Text';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { AdminUserIcon, MinusCircledIcon, PlusCircledIcon } from 'components/common/Icons';

import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useTenantUsersWithRoles, type UsersWithTenantAccess } from 'services/api/tenants';
import { useAddRoleToUser, useRemoveRoleFromUser, useTenantRoles } from 'services/api/roles';

export const UsersTabItem = () => {
  return <AdminUserIcon className="size-4" />;
};

type RoleValues = 'admin' | 'analyst' | null;

const SelectUserRole = ({
  email,
  value,
  onValueChange,
  onAdd,
  onRemove,
}: {
  email: string;
  value: RoleValues;
  onValueChange?: (value: RoleValues) => void;
  onAdd?: () => void;
  onRemove?: () => void;
}) => {
  const t = useTranslate();
  return (
    <div className="flex items-center">
      <div className="flex w-full items-center justify-between bg-solid px-2 py-1">
        <Text size="2" className="leading-5">
          {email}
        </Text>

        <Select.Root
          value={value || 'none'}
          size="1"
          disabled={!onValueChange}
          onValueChange={(value) => {
            onValueChange?.(value === 'none' ? null : (value as 'admin' | 'analyst'));
          }}
        >
          <Select.Trigger variant="soft" color="gray" className="w-20" />
          <Select.Content>
            <Select.Item value="analyst">{t('common.analyst')}</Select.Item>
            <Select.Item value="admin">{t('common.admin')}</Select.Item>
            {onAdd && <Select.Item value="none">{t('common.none')}</Select.Item>}
          </Select.Content>
        </Select.Root>
      </div>
      {onAdd && (
        <Button variant="soft" size="1" onClick={onAdd} disabled={value === null}>
          <PlusCircledIcon />
          {t('common.add')}
        </Button>
      )}
      {onRemove && (
        <Button variant="soft" size="1" onClick={onRemove}>
          <MinusCircledIcon />
        </Button>
      )}
    </div>
  );
};

const Content = ({
  data,
  tenantAdminRoleId,
  tenantAnalystRoleId,
  onSuccess,
}: {
  data: UsersWithTenantAccess;
  tenantAdminRoleId: string;
  tenantAnalystRoleId: string;
  onSuccess: () => void;
}) => {
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const [draftData, setDraftData] = useState(data);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addRoleToUserMutation = useAddRoleToUser();
  const removeRoleFromUserMutation = useRemoveRoleFromUser();

  useEffect(() => {
    setDraftData(data);
  }, [data]);

  const saveMutation = useMutation({
    mutationFn: async () => {
      const changedUsersAccess = draftData.filter((x) => {
        const original = data.find((y) => y.user.id === x.user.id);

        return original?.role !== x.role || original?.accessLevel !== x.accessLevel;
      });

      if (changedUsersAccess.length === 0) {
        void navigate({
          search: undefined,
        });

        return;
      }

      const accessRemovalList: { userId: string; roleId: string }[] = [];
      const accessAdditionList: { userId: string; roleId: string }[] = [];

      for (const currentAccess of changedUsersAccess) {
        const previousAccess = data.find((x) => x.user.id === currentAccess.user.id);

        if (previousAccess?.accessLevel === 'tenant' && currentAccess.accessLevel === null) {
          accessRemovalList.push({
            userId: currentAccess.user.id,
            roleId: previousAccess.role === 'admin' ? tenantAdminRoleId : tenantAnalystRoleId,
          });
        } else if (previousAccess?.accessLevel === null && currentAccess.accessLevel === 'tenant') {
          accessAdditionList.push({
            userId: currentAccess.user.id,
            roleId: currentAccess.role === 'admin' ? tenantAdminRoleId : tenantAnalystRoleId,
          });
        } else if (
          previousAccess?.accessLevel === currentAccess.accessLevel &&
          previousAccess.role !== currentAccess.role
        ) {
          if (currentAccess.role === 'admin') {
            accessRemovalList.push({
              userId: currentAccess.user.id,
              roleId: tenantAnalystRoleId,
            });
            accessAdditionList.push({
              userId: currentAccess.user.id,
              roleId: tenantAdminRoleId,
            });
          } else {
            accessRemovalList.push({
              userId: currentAccess.user.id,
              roleId: tenantAdminRoleId,
            });
            accessAdditionList.push({
              userId: currentAccess.user.id,
              roleId: tenantAnalystRoleId,
            });
          }
        }
      }

      const promises = [
        ...accessRemovalList.map((x) => {
          return removeRoleFromUserMutation.mutateAsync({
            userId: x.userId,
            roleId: x.roleId,
          });
        }),
        ...accessAdditionList.map((x) => {
          return addRoleToUserMutation.mutateAsync({
            userId: x.userId,
            roleId: x.roleId,
          });
        }),
      ];

      await Promise.all(promises);
      await queryClient.invalidateQueries({ queryKey: ['users'] });
      await queryClient.invalidateQueries({ queryKey: ['tenants'] });

      void navigate({
        search: undefined,
      });

      onSuccess();
    },
  });

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  const handleRemoveTenantAccess = (userId: string) => {
    setDraftData((prev) => {
      return prev.map((x) => {
        if (x.user.id === userId) {
          return {
            ...x,
            accessLevel: null,
            role: null,
          };
        }

        return x;
      });
    });
  };

  const handleAddTenantAccess = (userId: string) => {
    setDraftData((prev) => {
      return prev.map((x) => {
        if (x.user.id === userId) {
          return {
            ...x,
            accessLevel: 'tenant',
          };
        }

        return x;
      });
    });
  };

  const handleChangeUserRole = (userId: string, role: RoleValues) => {
    setDraftData((prev) => {
      return prev.map((x) => {
        if (x.user.id === userId) {
          return {
            ...x,
            role,
          };
        }

        return x;
      });
    });
  };

  const globalUsers = draftData.filter((x) => x.accessLevel === 'global');
  const tenantUsers = draftData.filter((x) => x.accessLevel === 'tenant');
  const otherUsers = draftData.filter((x) => {
    return x.accessLevel === null;
  });
  const otherUsersFiltered = otherUsers.filter((x) => {
    if (search.length > 0) {
      return x.user.email.toLowerCase().includes(search.toLowerCase());
    }

    return true;
  });

  return (
    <div className="flex flex-1 flex-col">
      {globalUsers.length > 0 && (
        <div className="mb-6" data-test-id="global-access">
          <Text weight="medium" className="!mb-2" as="div">
            {t('settings.tabs.platform.subtabs.tenants.sidebar.global_access')}
          </Text>
          <div className="space-y-2">
            {globalUsers.map(({ user, role }) => (
              <SelectUserRole key={user.id} value={role} email={user.email} />
            ))}
          </div>
        </div>
      )}

      {tenantUsers.length > 0 && (
        <div className="mb-6" data-test-id="tenant-users">
          <Text weight="medium" className="!mb-2" as="div">
            {t('settings.tabs.platform.subtabs.tenants.sidebar.tenant_users')}
          </Text>
          <div className="space-y-2">
            {tenantUsers.map(({ user, role }) => (
              <SelectUserRole
                key={user.id}
                value={role}
                email={user.email}
                onValueChange={(role) => handleChangeUserRole(user.id, role)}
                onRemove={() => handleRemoveTenantAccess(user.id)}
              />
            ))}
          </div>
        </div>
      )}

      <div className="flex flex-1 flex-col" data-test-id="unassigned-users">
        {otherUsers.length > 0 && (
          <>
            <Text weight="medium" as="div">
              {t('settings.tabs.platform.subtabs.tenants.sidebar.unassigned_users')}
            </Text>
            <TextField
              placeholder={t('settings.tabs.platform.subtabs.tenants.sidebar.search_placeholder')}
              className="mb-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {otherUsersFiltered.length === 0 ? (
              <Text>{t('settings.tabs.platform.subtabs.tenants.sidebar.no_results')}</Text>
            ) : (
              <div className="flex-1">
                <div className="space-y-2">
                  {otherUsersFiltered.map(({ user, role }) => (
                    <SelectUserRole
                      key={user.id}
                      value={role}
                      email={user.email}
                      onValueChange={(role) => handleChangeUserRole(user.id, role)}
                      onAdd={() => handleAddTenantAccess(user.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {saveMutation.error && (
        <Callout status="error" onClose={saveMutation.reset}>
          <p className="break-words">{saveMutation.error.message}</p>
        </Callout>
      )}

      <div className="mb-2 mt-2 flex justify-end gap-2">
        <Button variant="soft" type="button" color="gray" onClick={handleCancel} disabled={saveMutation.isPending}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={saveMutation.isPending} type="submit" onClick={() => saveMutation.mutate()}>
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
};

const emptyArray: [] = [];

export const UsersTabContent = ({
  tenant,
  onSuccess,
}: {
  tenant: components['schemas']['tenant'];
  onSuccess: (tenant: components['schemas']['tenant']) => void;
}) => {
  const tenantUsersWithRolesQuery = useTenantUsersWithRoles(tenant.id);
  const tenantRolesQuery = useTenantRoles(tenant.id);

  if (!tenantUsersWithRolesQuery.data || !tenantRolesQuery.data) {
    return <div>Loading...</div>;
  }

  if (tenantUsersWithRolesQuery.isError || tenantRolesQuery.isError) {
    return <div>Error</div>;
  }

  if (!tenantRolesQuery.data.adminRole || !tenantRolesQuery.data.analystRole) {
    return <div>Couldn't load tenant roles</div>;
  }

  return (
    <Content
      data={tenantUsersWithRolesQuery.data || emptyArray}
      tenantAdminRoleId={tenantRolesQuery.data.adminRole.id}
      tenantAnalystRoleId={tenantRolesQuery.data.analystRole.id}
      onSuccess={() => onSuccess(tenant)}
    />
  );
};
