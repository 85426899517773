import { type ReactNode, type ComponentProps, forwardRef } from 'react';
import { TextField as RadixTextField } from '@radix-ui/themes';

import { cn } from 'utils/styles';

export type TextFieldProps = ComponentProps<typeof RadixTextField.Input> & {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, size, variant, color, radius, leftIcon, rightIcon, disabled, ...props }, ref) => {
    return (
      <RadixTextField.Root
        size={size}
        variant={variant}
        color={color}
        radius={radius}
        className={cn('w-full', disabled && '!cursor-not-allowed opacity-70', className)}
      >
        {leftIcon && <RadixTextField.Slot>{leftIcon}</RadixTextField.Slot>}

        <RadixTextField.Input ref={ref} disabled={disabled} {...props} />

        {rightIcon && <RadixTextField.Slot>{rightIcon}</RadixTextField.Slot>}
      </RadixTextField.Root>
    );
  },
);
