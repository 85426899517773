import { useState } from 'react';

import { useTranslate } from 'services/i18n/useTranslate';
import { useCurrentUser, useLogout } from 'services/api/auth';

import { Button } from 'components/common/Button';
import { CaretSortIcon } from 'components/common/Icons';
import { DropdownMenu } from 'components/common/DropdownMenu';

import { UserSettingsSidebar } from './UserSettingsSidebar';

export const UserMenu = () => {
  const t = useTranslate();
  const logoutMutation = useLogout();
  const { data: currentUser } = useCurrentUser();
  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false);

  const handleNotImplemented = () => {
    alert('Not implemented');
  };

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button
            size="3"
            variant="ghost"
            color="gray"
            highContrast
            className="text-base text-neutral-11 hover:bg-transparent hover:text-neutral-12"
            data-test-id="user-menu-button"
          >
            {currentUser?.data?.email}
            <CaretSortIcon aria-hidden />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content size="2">
          <DropdownMenu.Item onClick={() => setIsUserSettingsOpen(true)}>
            {t('user_menu.items.user_settings')}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={handleNotImplemented}>{t('user_menu.items.help')}</DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={handleLogout}>{t('user_menu.items.sign_out')}</DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <UserSettingsSidebar isOpen={isUserSettingsOpen} onOpenChange={setIsUserSettingsOpen} />
    </>
  );
};
