import { Skeleton as RadixSkeleton } from '@radix-ui/themes';

export const Skeleton = ({ children, isLoading }: { children: React.ReactNode; isLoading?: boolean }) => {
  if (isLoading) {
    return <RadixSkeleton>{children}</RadixSkeleton>;
  }

  return children;
};

Skeleton.displayName = 'Skeleton';
