// forked from https://github.com/elbywan/wretch/blob/master/src/addons/queryString.ts
// as wretch wasnt encoding URL params properly (i.e. encoding spaces as + instead of %20 even though its not a form encoded request)
// and it also wasnt handling complex objects at all.
// this is a forked version of the original that fixes these issues and gives us more control over how we want to handle query params

import qs from 'query-string';
import type { Wretch, WretchAddon } from 'wretch';

const appendQueryParams = (url: string, qp: object | string, replace: boolean) => {
  let queryString: string;

  if (typeof qp === 'string') {
    queryString = qp;
  } else {
    queryString = qs.stringify(qp, {});
  }

  const split = url.split('?');

  if (!queryString) {
    return replace ? split[0] : url;
  }

  if (replace || split.length < 2) {
    return split[0] + '?' + queryString;
  }

  return url + '&' + queryString;
};

export interface QueryStringAddon {
  query<T extends QueryStringAddon, C, R>(this: T & Wretch<T, C, R>, qp: object | string, replace?: boolean): this;
}

export const queryStringAddon: WretchAddon<QueryStringAddon> = {
  wretch: {
    query(qp, replace = false) {
      return { ...this, _url: appendQueryParams(this._url, qp, replace) };
    },
  },
};
