import { Logo } from 'components/common/Logo';

type LogoProps = {
  expanded: boolean;
};

export function MenuLogo({ expanded }: LogoProps) {
  return (
    <div className="mb-2 flex h-[67px] items-center px-2">
      <Logo isAnimated shouldDisplayText={expanded} />
    </div>
  );
}
