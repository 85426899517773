import type { LinkProps } from '@tanstack/react-router';

import type { IconName } from 'components/common/Icons';
import type { HasAccessFunction } from 'services/auth/useHasAccess';

import type { FlattenedTranslationKeys } from 'types/translations';

export type MenuIconName = (typeof menuConfig)[number]['items'][number]['icon'];

export type MenuItem = {
  badge?: number | null;
  icon: IconName;
  tkey: FlattenedTranslationKeys;
  getTo: (getHasAccess: HasAccessFunction) => NonNullable<LinkProps['to']>;
  getIsActive: (pathname: string) => boolean;
};

type MenuSection = {
  tkey: FlattenedTranslationKeys | null;
  items: MenuItem[];
};

type Menu = MenuSection[];

export const menuConfig = [
  {
    tkey: null,
    items: [
      {
        badge: null,
        icon: 'HomeIcon',
        tkey: 'menu.home',
        getTo: () => '/',
        getIsActive: (pathname: string) => pathname === '/',
      },
      {
        badge: null,
        icon: 'Crosshair2Icon',
        tkey: 'menu.threats',
        getTo: () => '/threats',
        getIsActive: (pathname: string) => pathname.startsWith('/threats'),
      },
      {
        badge: null,
        icon: 'MixIcon',
        tkey: 'menu.anomalies',
        getTo: () => '/anomalies',
        getIsActive: (pathname: string) => pathname === '/anomalies',
      },
      {
        badge: null,
        icon: 'GearIcon',
        tkey: 'menu.settings',
        getTo(getHasAccess) {
          if (getHasAccess({ scope: 'tenant:read' })) {
            return '/settings/platform/tenants';
          }

          return '/settings/platform/pipelines';
        },
        getIsActive: (pathname: string) => pathname.startsWith('/settings/'),
      },
    ],
  },
] as const satisfies Menu;
