import { useCallback, useMemo } from 'react';
import { format, addMilliseconds } from 'date-fns';
import { useNavigate } from '@tanstack/react-router';

import { groupScoresToRanges } from 'utils/detections';
import { timeFormatSimple } from 'consts/dateFormats';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';
import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useThreatsHistogram, useThreatsParams } from 'services/api/threats';

import {
  HistogramChart,
  type HistogramDataPoint,
  type HistogramTooltipProps,
  TooltipContainer,
} from 'components/common/HistogramChart';
import { HistogramPlaceholder } from 'components/common/HistogramChart/HistogramPlaceholder';

type HistogramData = {
  high: number;
  medium: number;
  low: number;
};

type ExtendedHistogramDataPoint = HistogramDataPoint<HistogramData> & {
  total: number;
};

const TooltipContent = ({
  active,
  payload,
  interval,
}: HistogramTooltipProps & {
  interval: number;
}) => {
  const t = useTranslate();
  const data = payload?.[0]?.payload as ExtendedHistogramDataPoint | undefined;

  if (!active || !data) {
    return null;
  }

  return (
    <TooltipContainer>
      <div className="text-neutral-1 dark:text-neutral-12">
        {t('anomalies.histogram.tooltip_interval', {
          time1: format(new Date(data.time), timeFormatSimple),
          time2: format(addMilliseconds(new Date(data.time), interval), timeFormatSimple),
        })}
      </div>

      <div className="text-neutral-10">
        <div className="text-red-9">
          {t('threats.histogram.tooltip_high_count', {
            count: data.data.high,
            high: HIGH_RISK_SCORES[HIGH_RISK_SCORES.length - 1],
            low: HIGH_RISK_SCORES[0],
          })}
        </div>
        <div className="text-accent-9">
          {t('threats.histogram.tooltip_medium_count', {
            count: data.data.medium,
            high: MEDIUM_RISK_SCORES[MEDIUM_RISK_SCORES.length - 1],
            low: MEDIUM_RISK_SCORES[0],
          })}
        </div>
        <div className="text-neutral-8">
          {t('threats.histogram.tooltip_low_count', {
            count: data.data.low,
            high: LOW_RISK_SCORES[LOW_RISK_SCORES.length - 1],
            low: LOW_RISK_SCORES[0],
          })}
        </div>

        {t('threats.histogram.tooltip_total_count', {
          count: data.total,
        })}
      </div>
    </TooltipContainer>
  );
};

const BUCKETS_TO_MILLISECONDS: Record<NonNullable<components['parameters']['detectionsBucketInterval']>, number> = {
  '5m': 5 * 60 * 1000,
  '20m': 20 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '2h': 2 * 60 * 60 * 1000,
  '6h': 6 * 60 * 60 * 1000,
  '12h': 12 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000,
};

const getBucketInterval = (from: Date, to: Date): components['parameters']['detectionsBucketInterval'] => {
  const TARGET_BUCKETS = 40;
  const diff = to.getTime() - from.getTime();
  const interval = diff / TARGET_BUCKETS;

  return Object.entries(BUCKETS_TO_MILLISECONDS)
    .sort((a, b) => a[1] - b[1])
    .find(([, value]) => value >= interval)?.[0] as components['parameters']['detectionsBucketInterval'];
};

const BAR_DEFINITIONS = {
  low: {
    color: 'var(--neutral-8)',
    activeColor: 'var(--neutral-a8)',
  },
  medium: {
    color: 'var(--accent-10)',
    activeColor: 'var(--accent-a10)',
  },
  high: {
    color: 'var(--red-9)',
    activeColor: 'var(--red-10)',
  },
};

export const Histogram = () => {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/threats' });
  const threatParams = useThreatsParams();
  const threatsHistogramQuery = useThreatsHistogram({
    ...threatParams,
    bucketInterval: getBucketInterval(new Date(threatParams.startTime), new Date(threatParams.endTime)),
  });

  const intervalMilliseconds =
    BUCKETS_TO_MILLISECONDS[
      (threatsHistogramQuery.data?.data?.bucketInterval as components['parameters']['detectionsBucketInterval']) || '5m'
    ];

  const chartData = useMemo(() => {
    const data = threatsHistogramQuery.data?.data?.histogram || [];

    return data.map(
      (point): ExtendedHistogramDataPoint => ({
        time: point.time,
        total: point.count,
        data: groupScoresToRanges(
          point.values,
          HIGH_RISK_SCORES,
          MEDIUM_RISK_SCORES,
          LOW_RISK_SCORES,
          (f) => parseInt(f.value, 10),
          (f) => f.count,
        ),
      }),
    );
  }, [threatsHistogramQuery.data?.data?.histogram]);

  const handleHistogramBrushChange = useCallback(
    (startTime: string, endTime: string) => {
      void navigate({
        search: {
          startTime,
          endTime,
        },
      });
    },
    [navigate],
  );

  if (threatsHistogramQuery.isError) {
    return <HistogramPlaceholder message={t('common.errors.error_loading_histrogram')} />;
  }

  const hasNoData = threatsHistogramQuery.data?.data?.histogram.every((point) => point.count === 0);
  if (hasNoData) {
    return <HistogramPlaceholder message={t('common.errors.no_data')} />;
  }

  return (
    <HistogramChart<HistogramData>
      isLoading={threatsHistogramQuery.isLoading}
      data={chartData}
      interval={intervalMilliseconds}
      barDefinitions={BAR_DEFINITIONS}
      TooltipContent={(props) => <TooltipContent {...props} interval={intervalMilliseconds} />}
      onChange={handleHistogramBrushChange}
    />
  );
};
