export const copyToClipboard = (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    return Promise.resolve();
  }

  return navigator.clipboard.writeText(text);
};
