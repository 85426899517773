import { useMemo } from 'react';
import _ from 'lodash';
import { getRouteApi } from '@tanstack/react-router';
import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { components, paths } from 'types/schemas/api-schema';
import { parseTimerange } from 'utils/dateTimeValidation';
import { mapParamsToAPIParams, mapParamsToQueryKey, type ParamsWithTimerange } from 'utils/detections';
import { type ValidTimerange } from 'consts/dateTime';
import { HIGH_RISK_SCORES, LOW_RISK_SCORES, MEDIUM_RISK_SCORES } from 'consts/threats';

import { api, type APIError } from '.';

const THREATS_CACHE_TIME = 30 * 60 * 1000;

const threatsQueryConfig = {
  staleTime: THREATS_CACHE_TIME,
  cacheTime: THREATS_CACHE_TIME,
};

const parseRiskScore = _.memoize((riskScore: ('high' | 'medium' | 'low' | undefined)[] = []) => {
  const result: number[] = [];

  if (riskScore.includes('high')) {
    result.push(...HIGH_RISK_SCORES);
  }
  if (riskScore.includes('medium')) {
    result.push(...MEDIUM_RISK_SCORES);
  }
  if (riskScore.includes('low')) {
    result.push(...LOW_RISK_SCORES);
  }

  return result;
});

const Route = getRouteApi('/_app/threats');

export const useThreatsParams = () => {
  const searchParams = Route.useSearch();

  const timeRange = 'timeRange' in searchParams ? searchParams.timeRange : undefined;
  const startTime = 'startTime' in searchParams ? searchParams.startTime : undefined;
  const endTime = 'endTime' in searchParams ? searchParams.endTime : undefined;

  const timeParams = useMemo(() => {
    if (timeRange) {
      return parseTimerange(timeRange as ValidTimerange);
    }

    return {
      startTime,
      endTime,
    } as { startTime: string; endTime: string };
  }, [timeRange, startTime, endTime]);

  const params = {
    riskScore: parseRiskScore(searchParams.riskScore),
    dimension: searchParams.dimension,
    entity: searchParams.entity,
    pipelineId: searchParams.pipelineId,
    tenantId: searchParams.tenantId,
    status: searchParams.status,
    geo: searchParams.geo,
    workflowAssignedTo: searchParams.workflowAssignedTo,
    workflowStatus: searchParams.workflowStatus,
    timeRange,
  } as Omit<typeof searchParams, 'timeRange' | 'startTime' | 'endTime' | 'riskScore'> & {
    riskScore: number[];
    timeRange?: string;
  };

  return {
    ...params,
    ...timeParams,
  };
};

export const useThreat = (threatId: string | undefined) => {
  return useQuery<
    paths['/detections/threats/{threatId}']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/{threatId}']['get']['responses']['404']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', threatId],
    queryFn: () => {
      return api
        .url(`/detections/threats/${threatId}`)
        .get()
        .json<paths['/detections/threats/{threatId}']['get']['responses']['200']['content']['application/json']>();
    },
    enabled: !!threatId,
  });
};

export const useThreats = (
  params: ParamsWithTimerange<paths['/detections/threats/query']['get']['parameters']['query']>,
) => {
  return useQuery<
    paths['/detections/threats/query']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/query']['get']['responses']['400']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', 'query', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/query')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/query']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useThreatsFacets = (
  params: ParamsWithTimerange<paths['/detections/threats/facets']['get']['parameters']['query']>,
) => {
  return useQuery<
    paths['/detections/threats/facets']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/facets']['get']['responses']['400']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', 'facets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/facets']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useThreatsEntityFacets = (
  params: ParamsWithTimerange<paths['/detections/threats/entities/facets']['get']['parameters']['query']>,
) => {
  return useQuery<
    paths['/detections/threats/entities/facets']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/entities/facets']['get']['responses']['400']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', 'entityFacets', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/entities/facets')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/entities/facets']['get']['responses']['200']['content']['application/json']>();
    },
    placeholderData: (p) => p,
  });
};

export const useThreatsHistogram = (
  params: ParamsWithTimerange<paths['/detections/threats/histogram']['get']['parameters']['query']>,
) => {
  return useQuery<
    paths['/detections/threats/histogram']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/detections/threats/histogram']['get']['responses']['400']['content']['application/json']>
  >({
    ...threatsQueryConfig,
    queryKey: ['threats', 'histogram', mapParamsToQueryKey(params)],
    queryFn: () => {
      return api
        .url('/detections/threats/histogram')
        .query(mapParamsToAPIParams(params))
        .get()
        .json<paths['/detections/threats/histogram']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useUpdateThreatWorkflow = (threatId: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    paths['/detections/threats/{threatId}/workflow']['patch']['responses']['200']['content']['application/json'],
    APIError<
      paths['/detections/threats/{threatId}/workflow']['patch']['responses']['400']['content']['application/json']
    >,
    paths['/detections/threats/{threatId}/workflow']['patch']['requestBody']['content']['application/json']
  >({
    mutationKey: ['threats', threatId, 'workflow'],
    mutationFn: (data) => {
      return api
        .url(`/detections/threats/${threatId}/workflow`)
        .patch(data)
        .json<
          paths['/detections/threats/{threatId}/workflow']['patch']['responses']['200']['content']['application/json']
        >();
    },
    onSuccess: (workflowData) => {
      let oldThreat: components['schemas']['threat'] | undefined;

      queryClient.setQueriesData(
        {
          exact: false,
          queryKey: ['threats', 'query'],
        },
        (_oldData: unknown) => {
          const oldData =
            _oldData as paths['/detections/threats/query']['get']['responses']['200']['content']['application/json'];

          return {
            ...oldData,
            data: oldData.data.map((threat) => {
              if (threat.id === threatId) {
                oldThreat = threat;

                return {
                  ...threat,
                  workflow: {
                    ...threat.workflow,
                    ...workflowData.data,
                  },
                };
              }

              return threat;
            }),
          };
        },
      );

      queryClient.setQueriesData(
        {
          queryKey: ['threats', threatId],
        },
        (_oldData: unknown) => {
          const oldData =
            _oldData as paths['/detections/threats/{threatId}']['get']['responses']['200']['content']['application/json'];

          return {
            ...oldData,
            data: {
              ...oldData.data,
              workflow: {
                ...oldData.data.workflow,
                ...workflowData.data,
              },
            },
          };
        },
      );

      if (!oldThreat) {
        return;
      }

      queryClient.setQueriesData(
        {
          exact: false,
          queryKey: ['threats', 'facets'],
        },
        (_oldData: unknown) => {
          const oldData =
            _oldData as paths['/detections/threats/facets']['get']['responses']['200']['content']['application/json'];

          const facets = _.cloneDeep(oldData.data);

          if (workflowData.data.status) {
            const facetWithOldStatus = facets.workflowStatuses.find(
              (status) => status.value === oldThreat?.workflow.status,
            );

            if (facetWithOldStatus) {
              facetWithOldStatus.count -= 1;
            } else {
              facets.workflowStatuses.push({
                count: 1,
                filterParameterValue: oldThreat?.workflow.status || 'open',
                value: oldThreat?.workflow.status || 'open',
                format: 'string',
                percentage: 0,
              });
            }

            const facetWithNewStatus = facets.workflowStatuses.find(
              (status) => status.value === workflowData.data.status,
            );

            if (facetWithNewStatus) {
              facetWithNewStatus.count += 1;
            } else {
              facets.workflowStatuses.push({
                count: 1,
                filterParameterValue: workflowData.data.status,
                value: workflowData.data.status,
                format: 'string',
                percentage: 0,
              });
            }
          }

          if ('assignedTo' in workflowData.data) {
            const facetWithOldAssignedTo = facets.assignees.find(
              (assignedTo) => (assignedTo.value || 'null') === (oldThreat?.workflow.assignedTo || 'null'),
            );

            if (facetWithOldAssignedTo) {
              facetWithOldAssignedTo.count -= 1;
            } else {
              facets.assignees.push({
                count: 1,
                filterParameterValue: oldThreat?.workflow.assignedTo || 'null',
                value: oldThreat?.workflow.assignedTo || 'null',
                format: 'string',
                percentage: 0,
              });
            }

            const facetWithNewAssignedTo = facets.assignees.find(
              (assignedTo) => (assignedTo.value || 'null') === (workflowData.data.assignedTo || 'null'),
            );

            if (facetWithNewAssignedTo) {
              facetWithNewAssignedTo.count += 1;
            } else {
              facets.assignees.push({
                count: 1,
                filterParameterValue: workflowData.data.assignedTo || 'null',
                value: workflowData.data.assignedTo || 'null',
                format: 'string',
                percentage: 0,
              });
            }
          }

          const cleanUpFacets = (options: components['schemas']['detectionsFacets']) => {
            // clean up empty facets, calculate new percentages, sort by count
            const total = options.reduce((acc, facet) => acc + facet.count, 0);
            return options
              .filter((facet) => facet.count > 0)
              .map((facet) => {
                return {
                  ...facet,
                  percentage: (facet.count / total) * 100,
                };
              });
          };

          return {
            ...oldData,
            data: {
              ...facets,
              assignees: cleanUpFacets(facets.assignees),
              workflowStatuses: cleanUpFacets(facets.workflowStatuses),
            },
          };
        },
      );
    },
  });
};

export const useIsMutatingThreatWorkflow = (threatId: string): boolean => {
  const mutatingCount = useIsMutating({
    mutationKey: ['threats', threatId, 'workflow'],
  });

  return mutatingCount > 0;
};

export const useThreatAssignableUsers = (threatId: string | undefined) => {
  return useQuery<
    paths['/detections/threats/{threatId}/assignable-users']['get']['responses']['200']['content']['application/json'],
    APIError<
      paths['/detections/threats/{threatId}/assignable-users']['get']['responses']['400']['content']['application/json']
    >
  >({
    queryKey: ['threats', threatId, 'assignable-users'],
    queryFn: () => {
      return api
        .url(`/detections/threats/${threatId}/assignable-users`)
        .get()
        .json<
          paths['/detections/threats/{threatId}/assignable-users']['get']['responses']['200']['content']['application/json']
        >();
    },
    enabled: !!threatId,
  });
};
