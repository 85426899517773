export function decodeAuthToken<
  T = {
    sub: string;
    iat: number;
    exp: number;
    name: string;
  },
>(token: string): T {
  const tokenPayload = token.split('.')[1];

  if (!tokenPayload) {
    throw new Error('Invalid token');
  }

  return JSON.parse(atob(tokenPayload)) as T;
}
