import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';

import * as errorMessages from 'consts/errorMessages';
import { useTranslate } from 'services/i18n/useTranslate';
import { useStore, type AllSlices } from 'services/store';
import { useChangePassword, useCurrentUser } from 'services/api/auth';

import { Label } from 'components/common/Label';
import { Select } from 'components/common/Select';
import { Drawer } from 'components/common/Drawer';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { ButtonLink } from 'components/common/ButtonLink';
import { InlineDialog } from 'components/common/InlineDialog';
import { PasswordField } from 'components/common/PasswordField';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/common/Form';
import { PasswordRequirementsList, validateConditions } from 'components/common/PasswordRequirementsList';

const changePasswordSchema = z
  .object({
    oldPassword: z.string().min(1, errorMessages.required),
    newPassword: z.string().min(1, errorMessages.required),
    confirmPassword: z.string().min(1, errorMessages.required),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: errorMessages.passwordsDoNotMatch,
    path: ['confirmPassword'],
  });

type ChangePasswordFormFields = z.infer<typeof changePasswordSchema>;

const validContextFields = [
  'oldPassword',
  'newPassword',
  'confirmPassword',
] as const satisfies (keyof ChangePasswordFormFields)[];

function ChangePasswordInlineDialog({ onSuccess, onClose }: { onSuccess: () => void; onClose: () => void }) {
  const t = useTranslate();
  const form = useForm<ChangePasswordFormFields>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: zodResolver(changePasswordSchema),
  });
  const changePasswordMutation = useChangePassword();

  const newPassword = form.watch('newPassword');
  const validations = validateConditions(newPassword || '');

  const isDisabled = Object.keys(form.formState.errors || {}).length > 0 || validations.some((v) => !v.isValid);

  const handleSubmit = (data: ChangePasswordFormFields) => {
    changePasswordMutation.mutate(
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
      {
        onSuccess,
        onError: (error) => {
          if (error?.json?.errors) {
            for (const err of error.json.errors) {
              if (err.context && validContextFields.includes(err.context)) {
                form.setError(err.context as (typeof validContextFields)[number], {
                  type: 'manual',
                  message: err.errorMsg,
                });
              }
            }
          }
        },
      },
    );
  };

  const shouldDisplayError =
    changePasswordMutation.isError &&
    !validContextFields.includes(changePasswordMutation.error.json?.errors?.[0]?.context as string);

  return (
    <>
      {shouldDisplayError && (
        <Callout className="mt-2" status="error" onClose={changePasswordMutation.reset}>
          {changePasswordMutation.error?.json?.errors?.[0]?.errorMsg || changePasswordMutation.error.message}
        </Callout>
      )}

      <Form {...form}>
        <form className="mt-2 space-y-2" onSubmit={form.handleSubmit(handleSubmit)}>
          <InlineDialog>
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => {
                return (
                  <FormItem className="w-full">
                    <Label className="mb-2 inline-block">
                      {t('user_menu.user_settings_sidebar.current_password_input.label')}
                    </Label>
                    <FormControl>
                      <PasswordField
                        {...field}
                        placeholder={t('user_menu.user_settings_sidebar.current_password_input.placeholder')}
                        autoComplete="current-password"
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </InlineDialog>

          <InlineDialog
            footer={
              <>
                <Button type="button" size="1" variant="soft" color="gray" onClick={onClose}>
                  {t('common.cancel')}
                </Button>
                <Button
                  type="submit"
                  size="1"
                  variant={isDisabled ? 'soft' : 'solid'}
                  disabled={isDisabled}
                  isLoading={changePasswordMutation.isPending}
                >
                  {t('user_menu.user_settings_sidebar.change_password_button')}
                </Button>
              </>
            }
          >
            <div className="mb-4 space-y-4">
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full">
                      <Label className="mb-2 inline-block">
                        {t('user_menu.user_settings_sidebar.reset_password_input.label')}
                      </Label>
                      <FormControl>
                        <PasswordField
                          {...field}
                          placeholder={t('user_menu.user_settings_sidebar.reset_password_input.placeholder')}
                          autoComplete="new-password"
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full">
                      <Label className="mb-2 inline-block">
                        {t('user_menu.user_settings_sidebar.confirm_password_input.label')}
                      </Label>
                      <FormControl>
                        <PasswordField
                          {...field}
                          placeholder={t('user_menu.user_settings_sidebar.confirm_password_input.placeholder')}
                          autoComplete="new-password"
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>

            <PasswordRequirementsList validations={validations} />
          </InlineDialog>
        </form>
      </Form>
    </>
  );
}

function ChangePasswordAction({ onSuccess }: { onSuccess: () => void }) {
  const t = useTranslate();
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);

  return (
    <AnimatePresence initial={false}>
      {isChangePasswordDialogOpen ? (
        <motion.div key="password" initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 'auto' }}>
          <ChangePasswordInlineDialog
            onSuccess={() => {
              setIsChangePasswordDialogOpen(false);
              onSuccess();
            }}
            onClose={() => setIsChangePasswordDialogOpen(false)}
          />
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <ButtonLink onClick={() => setIsChangePasswordDialogOpen(true)}>
            {t('user_menu.user_settings_sidebar.change_password_action')}
          </ButtonLink>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const selector = (state: AllSlices) => ({
  theme: state.theme.theme,
  changeTheme: state.theme.changeTheme,
});

export function UserSettingsSidebar({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}) {
  const t = useTranslate();
  const currentUserQuery = useCurrentUser();
  const [hasPasswordChanged, setHasPasswordChanged] = useState(false);
  const { theme, changeTheme } = useStore(selector);

  useEffect(() => {
    if (!isOpen) {
      setHasPasswordChanged(false);
    }
  }, [isOpen]);

  if (!currentUserQuery.data) {
    return null;
  }

  const userData = currentUserQuery.data.data;

  return (
    <Drawer
      mode="modal"
      side="right"
      title={t('user_menu.user_settings_sidebar.title')}
      description={t('user_menu.user_settings_sidebar.description')}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <div className="space-y-2">
        <div>
          <Label className="mb-1 inline-block">{t('common.name')}</Label>
          <TextField readOnly value={`${userData.firstName} ${userData.lastName}`} />
        </div>

        <div>
          <Label className="mb-1 inline-block">{t('common.email')}</Label>
          <TextField readOnly value={userData.email} />
          <ChangePasswordAction onSuccess={() => setHasPasswordChanged(true)} />

          {hasPasswordChanged && (
            <Callout className="mt-2" status="success" onClose={() => setHasPasswordChanged(false)}>
              {t('user_menu.user_settings_sidebar.change_password_success')}
            </Callout>
          )}
        </div>

        <div>
          <Label className="mb-1 block">{t('user_menu.user_settings_sidebar.theme')}</Label>
          <Select.Root value={theme} onValueChange={(value) => changeTheme(value as 'light' | 'dark')}>
            <Select.Trigger color="orange" className="w-full" />
            <Select.Content className="max-w-[200px]">
              <Select.Item value="light">{t('user_menu.user_settings_sidebar.light')}</Select.Item>
              <Select.Item value="dark">{t('user_menu.user_settings_sidebar.dark')}</Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
      </div>
    </Drawer>
  );
}
