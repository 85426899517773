import type { ReactNode } from 'react';
import { cn } from 'utils/styles';

export type InlineDialogProps = {
  title?: string;
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
};

export const InlineDialog = ({ title, children, footer, className }: InlineDialogProps) => {
  return (
    <div className={cn('bg-neutral-3 p-3 text-sm', className)} data-test-id="inline-dialog">
      {title && <div className="mb-1 font-semibold">{title}</div>}
      <div>{children}</div>
      {footer && <div className="mt-3 flex items-center justify-end gap-2">{footer}</div>}
    </div>
  );
};
