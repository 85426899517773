import { Badge } from 'components/common/Badge';
import { InfoCircledIcon } from 'components/common/Icons';
import type { APIError } from 'services/api';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

type StatusBadgeProps = {
  message?: string;
  className?: string;
  error?: APIError<components['schemas']['errorEnvelope']> | null;
};

export const NoDataBadge = ({ message, className }: StatusBadgeProps) => {
  const t = useTranslate();
  return (
    <Badge size="2" color="gray" className={className}>
      <InfoCircledIcon /> {message ?? t('common.errors.no_records_matching_filter_selection')}
    </Badge>
  );
};

export const ErrorBadge = ({ message, className, error }: StatusBadgeProps) => {
  const t = useTranslate();

  const errorMessage = message ?? error?.message ?? t('common.errors.failed_to_retrieve_data');

  return (
    <Badge size="2" color="red" className={className}>
      <InfoCircledIcon /> {errorMessage}
    </Badge>
  );
};
