import { type Slice } from './_utils';

export type DashboardConfigurationSlice = {
  dashboardConfigurationSlice: {
    areThreatsFacetsOpen: boolean;

    toggleThreatsFacets: () => void;
  };
};

export const dashboardConfigurationSlice: Slice<DashboardConfigurationSlice> = {
  createSlice: (set) => ({
    dashboardConfigurationSlice: {
      areThreatsFacetsOpen: true,

      toggleThreatsFacets: () => {
        return set((prev) => {
          return {
            ...prev,
            dashboardConfigurationSlice: {
              ...prev.dashboardConfigurationSlice,
              areThreatsFacetsOpen: !prev.dashboardConfigurationSlice.areThreatsFacetsOpen,
            },
          };
        });
      },
    },
  }),
};
