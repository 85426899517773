import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type paths } from 'types/schemas/api-schema';

import { api, type APIError } from '.';

type SavedViewsResponse =
  paths['/settings/dashboards/saved-views']['get']['responses']['200']['content']['application/json'];

type SavedViewType = 'threats' | 'anomalies';

export const useSavedViews = (type: SavedViewType) => {
  return useQuery({
    queryKey: ['saved-views', type],
    queryFn: () => {
      return api
        .url('/settings/dashboards/saved-views')
        .query({
          type,
        })
        .get()
        .json<SavedViewsResponse>();
    },
  });
};

export const useCreateSavedView = (type: SavedViewType) => {
  const queryClient = useQueryClient();

  return useMutation<
    SavedViewsResponse,
    APIError<paths['/settings/dashboards/saved-views']['post']['responses']['400']['content']['application/json']>,
    Omit<paths['/settings/dashboards/saved-views']['post']['requestBody']['content']['application/json'], 'type'>
  >({
    mutationFn: async (
      data: Omit<
        paths['/settings/dashboards/saved-views']['post']['requestBody']['content']['application/json'],
        'type'
      >,
    ) => {
      return api
        .url('/settings/dashboards/saved-views')
        .post({ ...data, type })
        .json<SavedViewsResponse>();
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['saved-views', type],
      });
    },
  });
};

export const useDeleteSavedView = (type: SavedViewType, savedViewId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    APIError<
      paths['/settings/dashboards/saved-views/{savedViewId}']['delete']['responses']['401']['content']['application/json']
    >
  >({
    mutationFn: async () => {
      await api.url(`/settings/dashboards/saved-views/${savedViewId}`).delete().res();
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['saved-views', type],
      });
    },
  });
};
