import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { type components, type paths } from 'types/schemas/api-schema';

import { api, type APIError } from '.';

export const useEnrichments = () => {
  return useQuery({
    queryKey: ['enrichments'],
    queryFn: () => {
      return api
        .url('/enrichments/custom')
        .get()
        .json<paths['/enrichments/custom']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useCreateEnrichment = () => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/enrichments/custom']['post']['responses']['201']['content']['application/json'],
    APIError<paths['/enrichments/custom']['post']['responses']['400']['content']['application/json']>,
    paths['/enrichments/custom']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (enrichment) => {
      const response = await api
        .url('/enrichments/custom')
        .post(enrichment)
        .json<paths['/enrichments/custom']['post']['responses']['201']['content']['application/json']>();

      await queryClient.invalidateQueries({
        queryKey: ['enrichments'],
      });

      return response;
    },
  });
};

type EnrichmentSetFormat = components['schemas']['customEnrichmentSetFormat'];
type CustomEnrichment = components['schemas']['customEnrichment'];
type EnrichmentDatasetReturnType<T extends EnrichmentSetFormat> = T extends 'csv' ? string : CustomEnrichment[];

export const useEnrichmentDataset = <T extends EnrichmentSetFormat>(
  setId: components['schemas']['idString'],
  format: T,
) => {
  return useQuery<
    EnrichmentDatasetReturnType<T>,
    APIError<paths['/enrichments/custom/{setId}/dataset']['get']['responses']['400']['content']['application/json']>
  >({
    queryKey: ['enrichments', setId, 'dataset'],
    queryFn: async () => {
      const response = await api.url(`/enrichments/custom/${setId}/dataset`).query({ format }).get().res();

      if (format === 'csv') {
        const text = await response.text();
        return text as EnrichmentDatasetReturnType<T>;
      } else {
        const jsonData = await response.json();
        return jsonData as EnrichmentDatasetReturnType<T>;
      }
    },
    enabled: !!setId,
  });
};

export const useUpdateCustomPipelineEnrichments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/ingest/pipelines/{pipelineId}/enrichments']['put']['responses']['200']['content']['application/json'],
    APIError<
      paths['/ingest/pipelines/{pipelineId}/enrichments']['put']['responses']['400']['content']['application/json']
    >,
    {
      tenantId: components['schemas']['idString'];
      pipelineId: components['schemas']['idString'];
      customEnrichmentId: components['schemas']['idString'] | null;
    }
  >({
    mutationFn: async ({ tenantId, pipelineId, customEnrichmentId }) => {
      const results = await api
        .url(`/ingest/pipelines/full/${pipelineId}`)
        .get()
        .json<paths['/ingest/pipelines/full/{pipelineId}']['get']['responses']['200']['content']['application/json']>();

      const fullPipeline = results.data;

      const otherEnrichments: components['schemas']['enrichment'][] =
        fullPipeline.enrichmentSet?.enrichments?.filter((enrichment) => enrichment.type !== 'custom') || [];

      const enrichments = [
        ...otherEnrichments,
        {
          type: 'custom',
          enabled: customEnrichmentId !== null,
          id: customEnrichmentId,
        },
      ];

      const response = await api
        .url(`/ingest/pipelines/${pipelineId}/enrichments`)
        .put({
          tenantId,
          pipelineId,
          enrichments,
        })
        .json<
          paths['/ingest/pipelines/{pipelineId}/enrichments']['put']['responses']['200']['content']['application/json']
        >();

      await queryClient.invalidateQueries({
        queryKey: ['enrichments'],
      });

      return response;
    },
  });
};

export const useAssociatedEnrichmentsAndPipelines = (tenantId?: components['schemas']['idString']) => {
  const queryKey = tenantId ? ['enrichments', 'association', tenantId] : ['enrichments', 'association'];

  return useQuery<
    paths['/ingest/view/enrichments/custom']['get']['responses']['200']['content']['application/json'],
    APIError<paths['/ingest/view/enrichments/custom']['get']['responses']['400']['content']['application/json']>
  >({
    queryKey,
    queryFn: async () => {
      const query = tenantId ? { tenantId } : {};
      return api
        .url(`/ingest/view/enrichments/custom`)
        .query(query)
        .get()
        .json<paths['/ingest/view/enrichments/custom']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

export const useUpdateEnrichmentDataset = (setId: components['schemas']['idString'], mode: 'replace' | 'append') => {
  const queryClient = useQueryClient();

  return useMutation<
    | { status: 'success' }
    | {
        status: 'partial';
        errorStrings: string[];
      },
    APIError<
      paths['/enrichments/custom/{setId}/dataset/csv']['put']['responses']['400']['content']['application/json']
    >,
    string
  >({
    mutationFn: async (data) => {
      const request = api
        .headers({
          'Content-Type': 'text/csv',
        })
        .url(`/enrichments/custom/${setId}/dataset/csv`);

      const res = mode === 'replace' ? await request.put(data).res() : await request.patch(data).res();

      await queryClient.invalidateQueries({
        queryKey: ['enrichments'],
      });

      if (res.status === 207) {
        const responseData =
          (await res.json()) as paths['/enrichments/custom/{setId}/dataset/csv']['put']['responses']['207']['content']['application/json'];

        return {
          status: 'partial',
          errorStrings: (responseData.errors || [])?.map((e) => e.errorMsg).filter(Boolean),
        };
      }

      return {
        status: 'success',
      };
    },
  });
};

export const useDeleteEnrichment = (setId: components['schemas']['idString']) => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/enrichments/custom/{setId}']['delete']['responses']['204']['content'],
    APIError<paths['/enrichments/custom/{setId}']['delete']['responses']['401']['content']['application/json']>
  >({
    mutationFn: async () => {
      await api.url(`/enrichments/custom/${setId}`).delete().res();

      return undefined;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['enrichments'] });
    },
  });
};
