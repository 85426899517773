import { useLayoutEffect, type ReactNode } from 'react';
import { Theme } from '@radix-ui/themes';

import { useStore } from 'services/store';
import { cn } from 'utils/styles';
import { useKeyboardShortcut } from 'utils/hooks';

type ThemeOptions = 'light' | 'dark';

type ThemeProviderProps = {
  className?: string;
  children: ReactNode;
  // used for storybook
  forceTheme?: ThemeOptions;
};

export function ThemeProvider({ forceTheme, className, children }: ThemeProviderProps) {
  const { theme, changeTheme } = useStore((state) => ({
    theme: state.theme.theme,
    changeTheme: state.theme.changeTheme,
  }));

  const currentTheme = forceTheme ?? theme ?? 'light';

  useKeyboardShortcut(
    () => {
      changeTheme(currentTheme === 'light' ? 'dark' : 'light');
    },
    { key: 't', shiftKey: true, ctrlKey: true },
  );

  useLayoutEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(currentTheme);
  }, [currentTheme]);

  return (
    <Theme
      id="theme-container"
      scaling="100%"
      accentColor="orange"
      grayColor="gray"
      className={cn(
        'h-screen bg-neutral-2 supports-[height:100dvh]:h-[100dvh] dark:bg-neutral-5',
        currentTheme === 'dark' && 'dark',
        className,
      )}
      appearance={currentTheme}
    >
      {children}
    </Theme>
  );
}
