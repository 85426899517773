import { Outlet, createFileRoute, useMatch } from '@tanstack/react-router';
import { AppLayout } from 'components/layouts/AppLayout';

export const Route = createFileRoute('/_app')({
  component: AppRoute,
});

function AppRoute() {
  const threatDetailsRoute = useMatch({ from: '/_app/threats/$threatId', shouldThrow: false });
  const displayHeader = !threatDetailsRoute;

  return (
    <AppLayout displayHeader={displayHeader}>
      <Outlet />
    </AppLayout>
  );
}
