import * as React from 'react';

import { CrashAndBurn } from './CrashAndBurn';

export class ErrorBoundary extends React.Component<{
  ErrorComponent?: React.ComponentType;
  children: React.ReactNode;
}> {
  state = {
    error: null,
  };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children, ErrorComponent = CrashAndBurn } = this.props;

    if (error) {
      return <ErrorComponent error={error} />;
    } else {
      return children;
    }
  }
}
