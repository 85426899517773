import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';

import { NotFoundPage } from 'components/scenes/NotFoundPage';
import { ErrorBoundary } from 'components/common/ErrorBoundary';

import { useStore } from 'services/store';
import { I18nProvider } from 'services/i18n';
import { ThemeProvider } from 'services/theme';
import { initializeSentry } from 'services/sentry';
import { queryClient } from 'services/api/queryClient';

import './style/importCss';
import { routeTree } from './routeTree.gen';

const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: 'intent',
  defaultPreloadDelay: 100,
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultNotFoundComponent: NotFoundPage,
});

const { maybeSaveLastSearch } = useStore.getState().lastPageSearchSlice;

router.subscribe('onResolved', (event) => {
  const location = event.toLocation;

  maybeSaveLastSearch(location.pathname, location.searchStr);
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface HistoryState {
    data:
      | {
          id: 'accountCreated';
          email: string;
        }
      | {
          id: 'passwordReset';
          email: string;
        };
  }
}

initializeSentry(router);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </I18nProvider>
  </React.StrictMode>,
);
