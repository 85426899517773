import type { components } from 'types/schemas/api-schema';

export const LOADING_ANOMALIES: components['schemas']['anomaly'][] = [...new Array<number>(10)].map((_, i) => ({
  id: i.toString(),
  createdAt: new Date().toISOString(),
  confidenceScore: 10,
  observationWindow: {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  },
  pipelineId: '_'.repeat(i % 3 ? 10 : 7),
  entities: [
    {
      value: 'entity' + '_'.repeat((i + 2) % 4),
      filterParameterValue: '',
      type: '',
    },
  ],
  tenantId: '_'.repeat(10),
  dimensions: [
    {
      format: '',
      name: 'dimension',
      values: [
        {
          value: 'value',
          filterParameterValue: '',
        },
        {
          value: 'value 2',
          filterParameterValue: '',
        },
        {
          value: 'value 3',
          filterParameterValue: '',
        },
      ].slice(0, (i % 4) + (i % 3 ? 2 : 1)),
    },
  ],
  facts: [],
}));
