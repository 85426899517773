import { useRef } from 'react';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { usePlatformEnrichment } from 'services/api/platform-enrichments';
import * as errorMessages from 'consts/errorMessages';

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/common/Form';
import { Callout } from 'components/common/Callout';
import { Button } from 'components/common/Button';
import { TextField } from 'components/common/TextField';
import { ClipboardIcon } from 'components/common/Icons';

import { Section } from '../../components/Section';

export type EnrichmentContentProps = {
  onSuccess: (data: ExtendedPlatformEnrichment) => void;
};

type PlatformEnrichment = components['schemas']['enrichmentProfile'];
type ExtendedPlatformEnrichment = PlatformEnrichment & {
  file?: string;
};

const editEnrichmentSchema = z.object({
  tenant: z.literal('Global'),
  name: z.string().min(3, errorMessages.minLength),
  file: z.union([z.string(), z.instanceof(File)]).optional(),
});

export type EditEnrichmentFormFields = z.infer<typeof editEnrichmentSchema>;

type EnrichmentFormProps = {
  isLoading?: boolean;
  error?: components['schemas']['errorEnvelope'];
  onSubmit: (data: EditEnrichmentFormFields) => void;
  onCloseCallout?: () => void;
} & {
  enrichment: ExtendedPlatformEnrichment;
};

export function PlatformEnrichmentForm({
  enrichment,
  isLoading,
  error,
  onSubmit,
  onCloseCallout,
}: EnrichmentFormProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const platformEnrichmentQuery = usePlatformEnrichment(enrichment.type as 'geo' | 'intel');
  const formSchema = editEnrichmentSchema;
  const schema = enrichment.datasets[0]?.schema;

  const form = useForm<EditEnrichmentFormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tenant: 'Global',
      name: enrichment?.type || '',
      file: enrichment?.file || '',
    },
  });

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  const handleDownloadFile = () => {
    // eslint-disable-next-line no-console
    console.log('not implemented. API call missing');
  };

  return (
    <Form {...form}>
      <form className="flex h-full w-full flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <Section title={t('common.tenant')}>
          <FormField
            control={form.control}
            name="name"
            render={() => {
              return (
                <FormItem className="w-40">
                  <FormControl>
                    <TextField value="Global" readOnly />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </Section>

        <Section icon={ClipboardIcon} title={t('settings.tabs.enrichments.subtabs.platform.sidebar.ruleset')}>
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="w-40">
                  <FormLabel>{t('common.name')}</FormLabel>
                  <FormControl>
                    <TextField {...field} readOnly />
                  </FormControl>

                  {fieldState.error && <FormMessage translationVars={{ min: 3 }} />}
                </FormItem>
              );
            }}
          />
        </Section>

        <Section
          title={
            enrichment.type === 'geo'
              ? t('settings.tabs.enrichments.subtabs.platform.sidebar.upload_maxMind_geo_file')
              : t('settings.tabs.enrichments.subtabs.platform.sidebar.upload_stix_2.1_intel_file')
          }
        >
          <div>
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => {
                const getFileName = () => {
                  if (field.value instanceof File) {
                    return field.value.name;
                  } else if (typeof field.value === 'string' && field.value) {
                    return field.value;
                  } else if (platformEnrichmentQuery?.data && schema) {
                    const enrichmentType = enrichment.type;
                    let fileExtension = '';

                    if (enrichmentType === 'geo') {
                      fileExtension = '.mmdb';
                    } else if (enrichmentType === 'intel') {
                      fileExtension = '.json';
                    }

                    return `${schema}${fileExtension}`;
                  } else {
                    return '';
                  }
                };

                return (
                  <FormItem>
                    <FormLabel>{t('common.file_name')}</FormLabel>
                    <div className="flex items-center space-x-2">
                      <FormControl>
                        <>
                          <TextField
                            {...field}
                            value={getFileName()}
                            readOnly
                            placeholder={t('common.no_file_chosen')}
                          />
                          <TextField
                            ref={fileInputRef}
                            className="!hidden"
                            type="file"
                            accept={enrichment.type === 'geo' ? '.mmdb' : '.json'}
                            onChange={(e) => {
                              if (e.target.files && e.target.files.length > 0) {
                                field.onChange(e.target.files[0]);
                              }
                            }}
                          />
                        </>
                      </FormControl>
                      <Button type="button" onClick={() => fileInputRef.current?.click()}>
                        {t('common.browse')}
                      </Button>
                    </div>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <Button
              size="2"
              variant="ghost"
              color="orange"
              className="!-mb-3 !mt-1.5 !font-extralight !underline"
              onClick={handleDownloadFile}
              disabled={!platformEnrichmentQuery?.data?.data}
            >
              {t('settings.tabs.enrichments.subtabs.platform.sidebar.download_platform_enrichment')}
            </Button>
          </div>
        </Section>

        {error && (
          <Callout status="error" onClose={onCloseCallout}>
            {error?.errors?.[0]?.message}
          </Callout>
        )}

        <div className="flex-1" />

        <div className="mb-2 flex justify-end gap-2">
          <Button variant="soft" type="button" color="gray" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button isLoading={isLoading} type="submit">
            {t('common.save')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
