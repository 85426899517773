import React, { useState } from 'react';

import { ScrollArea } from 'components/common/ScrollArea';
import { FacetSection, SearchableFacetSection, type Option } from 'components/common/FacetSection';

import { useDebouncedValue } from 'utils/hooks';
import { getPipelinesFacetOptions, getTenantFacetOptions, groupFacetConfidenceScores } from 'utils/detections';
import { HIGH_CONFIDENCE_SCORES, LOW_CONFIDENCE_SCORES, MEDIUM_CONFIDENCE_SCORES } from 'consts/anomalies';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useAnomalyEntityFacets, useAnomalyFacets, useAnomalyParams } from 'services/api/anomalies';

import { type AnomaliesFilterProperty, useAnomaliesContext } from '../AnomaliesContext';

const LoadingFacet = () => {
  return (
    <FacetSection
      isLoading
      title={'Loading___'}
      unit="entry"
      property=""
      onClick={() => {}}
      options={[
        { count: 0, label: 'Loading', percentage: 0, value: '1' },
        { count: 0, label: 'Loading More', percentage: 0, value: '2' },
        { count: 0, label: 'Another value', percentage: 0, value: '3' },
      ]}
    />
  );
};

const Content = React.memo(() => {
  const t = useTranslate();
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();
  const anomalyParams = useAnomalyParams();
  const anomalyFacetsQuery = useAnomalyFacets(anomalyParams);
  const [entitiesFilter, setEntitiesFilter] = useState<string>('');
  const [entitiesSort, setEntitiesSort] = useState<'asc' | 'desc'>('desc');
  const { addFilter } = useAnomaliesContext();

  const debouncedEntitiesFilter = useDebouncedValue(entitiesFilter, 500);
  const anomalyEntityFacetsQuery = useAnomalyEntityFacets({
    ...anomalyParams,
    limit: 100,
    sort: entitiesSort,
    ...(debouncedEntitiesFilter.length >= 2 && {
      entityPrefix: debouncedEntitiesFilter,
    }),
  });

  if (
    anomalyFacetsQuery.isLoading ||
    pipelinesQuery.isLoading ||
    tenantsQuery.isLoading ||
    anomalyEntityFacetsQuery.isLoading
  ) {
    return [...Array<number>(10)].map((_, i) => <LoadingFacet key={i} />);
  }

  if (anomalyFacetsQuery.isError || pipelinesQuery.isError || tenantsQuery.isError) {
    return <div className="px-3">Error loading facets</div>;
  }

  const facetsData = anomalyFacetsQuery.data!.data;

  const confidenceScoreOptions = groupFacetConfidenceScores(facetsData.confidenceScores || []);
  const tenantOptions = getTenantFacetOptions(facetsData.tenantIds || [], tenantsQuery.data!.data);
  const pipelineOptions = getPipelinesFacetOptions(facetsData.pipelineIds || [], pipelinesQuery.data!.data);

  const handleFacetClick = (property: string, option: Option) => {
    addFilter(property as AnomaliesFilterProperty, option.value.toString());
  };

  return (
    <>
      <FacetSection
        title={t('common.confidence_score')}
        unit="range"
        property="confidenceScore"
        onClick={handleFacetClick}
        options={[
          {
            ...confidenceScoreOptions.high,
            label: t('common.score.high_range', {
              low: HIGH_CONFIDENCE_SCORES[0],
              high: HIGH_CONFIDENCE_SCORES[HIGH_CONFIDENCE_SCORES.length - 1]!,
            }),
          },
          {
            ...confidenceScoreOptions.medium,
            label: t('common.score.medium_range', {
              low: MEDIUM_CONFIDENCE_SCORES[0],
              high: MEDIUM_CONFIDENCE_SCORES[MEDIUM_CONFIDENCE_SCORES.length - 1]!,
            }),
          },
          {
            ...confidenceScoreOptions.low,
            label: t('common.score.low_range', {
              low: LOW_CONFIDENCE_SCORES[0],
              high: LOW_CONFIDENCE_SCORES[LOW_CONFIDENCE_SCORES.length - 1]!,
            }),
          },
        ]}
      />
      <FacetSection
        title={t('common.tenants')}
        unit="entry"
        property="tenantId"
        onClick={handleFacetClick}
        options={tenantOptions}
      />
      <FacetSection
        title={t('common.pipelines')}
        unit="entry"
        property="pipelineId"
        onClick={handleFacetClick}
        options={pipelineOptions}
      />
      {!(!anomalyEntityFacetsQuery.data?.data.entities?.length && debouncedEntitiesFilter === '') && (
        <SearchableFacetSection
          title={t('common.entities')}
          unit="entry"
          property="entity"
          sort={entitiesSort}
          filter={entitiesFilter}
          onClick={handleFacetClick}
          isFetching={anomalyEntityFacetsQuery.isFetching}
          onSortClick={() => setEntitiesSort(entitiesSort === 'asc' ? 'desc' : 'asc')}
          onFilterChange={setEntitiesFilter}
          options={(anomalyEntityFacetsQuery.data?.data.entities || []).map((e) => ({
            label: e.value,
            value: e.value,
            count: e.count,
            percentage: e.percentage,
          }))}
        />
      )}
      {facetsData.dimensions?.map((dim) => (
        <FacetSection
          key={dim.name}
          title={dim.name}
          unit="entry"
          property={dim.name}
          onClick={(prop, option) => addFilter('dimension', `${prop}:${option.value}`)}
          options={(dim.facets || []).map((e) => ({
            label: e.value,
            value: e.value,
            count: e.count,
            percentage: e.percentage,
          }))}
        />
      ))}
    </>
  );
});

export const Facets = () => {
  return (
    <div className="flex-1" data-test-id="anomalies-facets-tree">
      <ScrollArea
        style={{
          height: 'calc(100vh - 141px)',
        }}
      >
        <Content />
      </ScrollArea>
    </div>
  );
};
