import { useMemo, type ReactNode } from 'react';
import { getRouteApi } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { usePlatformEnrichments } from 'services/api/platform-enrichments';

import { Drawer } from 'components/common/Drawer';
import { VerticalTabContent, VerticalTabs, VerticalTabsList, VerticalTabTrigger } from 'components/common/VerticalTabs';

import { EditEnrichmentTabItem, PlatformEnrichmentEditContent } from './tabs/PlatformEnrichmentGeneralDataTab';
import {
  PlatformEnrichmentsAssignPipelineContent,
  AssignPipelineTabItem,
} from './tabs/PlatformEnrichmentAssignPipelineTab';
import type { EnrichmentContentProps } from './PlatformEnrichmentForm';

const Route = getRouteApi('/_app/settings/enrichments/platform');

export type EnrichmentSidebarProps = {
  onSuccess: EnrichmentContentProps['onSuccess'];
};

export function EditPlatformEnrichmentSidebar({ onSuccess }: EnrichmentSidebarProps) {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const platformEnrichmentQuery = usePlatformEnrichments();

  const tab =
    (('enrichmentSidebarIntent' in searchParams &&
      searchParams.enrichmentSidebarIntent === 'edit' &&
      searchParams.tab) as string) || 'settings';

  const isOpen = 'enrichmentSidebarIntent' in searchParams && searchParams.enrichmentSidebarIntent === 'edit';

  const enrichmentType = ('enrichmentSidebarIntent' in searchParams &&
    searchParams.enrichmentSidebarIntent === 'edit' &&
    searchParams.enrichmentType) as 'geo' | 'intel';

  const enrichment = useMemo(() => {
    if (!platformEnrichmentQuery.data || !enrichmentType) {
      return null;
    }

    return platformEnrichmentQuery.data[enrichmentType]?.data ?? null;
  }, [platformEnrichmentQuery.data, enrichmentType]);

  const onOpenChange = (value: boolean) => {
    void navigate({
      search: value ? { enrichmentSidebarIntent: 'edit' } : {},
    });
  };

  const errorOrContent = (node: ReactNode) => {
    if (platformEnrichmentQuery.error) {
      return <p>{t('common.errors.failed_to_retrieve_data')}</p>;
    }

    if (platformEnrichmentQuery.isLoading) {
      return <p>{t('common.loading')}</p>;
    }

    if (!enrichment) {
      return <p>{t('settings.tabs.enrichments.subtabs.platform.sidebar.enrichment_not_found')}</p>;
    }

    return node;
  };

  return (
    <Drawer
      title={t('settings.tabs.enrichments.subtabs.platform.sidebar.edit_title')}
      description={t('settings.tabs.enrichments.subtabs.platform.sidebar.edit_description', {
        enrichmentType: enrichment?.type,
      })}
      mode="modal"
      side="right"
      size="large"
      insetContent
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <VerticalTabs
        value={tab}
        onValueChange={(tab) => {
          void navigate({
            search: (prev) => ({
              ...prev,
              tab: tab as 'edit-enrichment' | 'assign-pipelines',
            }),
          });
        }}
        size="2"
        orientation="vertical"
        paddingTop={8}
      >
        <VerticalTabsList>
          <VerticalTabTrigger value="edit-enrichment">
            <EditEnrichmentTabItem />
          </VerticalTabTrigger>
          <VerticalTabTrigger value="assign-pipelines">
            <AssignPipelineTabItem />
          </VerticalTabTrigger>
        </VerticalTabsList>

        <VerticalTabContent value="edit-enrichment">
          {errorOrContent(
            enrichment && <PlatformEnrichmentEditContent onSuccess={onSuccess} enrichment={enrichment} />,
          )}
        </VerticalTabContent>
        <VerticalTabContent value="assign-pipelines">
          {errorOrContent(enrichment && <PlatformEnrichmentsAssignPipelineContent enrichment={enrichment} />)}
        </VerticalTabContent>
      </VerticalTabs>
    </Drawer>
  );
}
