type HandleKeyDown = <K>(onAction?: React.KeyboardEventHandler<K>) => (ev: React.KeyboardEvent<K>) => void;

export const handleKeyDown: HandleKeyDown = (onAction) => (ev) => {
  if (ev.key === 'Enter') {
    if (onAction) {
      onAction(ev);
    }
  } else if (ev.key === ' ') {
    ev.preventDefault();
    if (onAction) {
      onAction(ev);
    }
  }
};
