import { z } from 'zod';
import type { FlattenedTranslationKeys } from 'types/translations';

export const validTimeRanges = ['1h', '12h', '24h', 'today', 'yesterday'] as const;
export const defaultTimeRange = '1h';

export type ValidTimerange = (typeof validTimeRanges)[number];

type RangeOption = {
  label: FlattenedTranslationKeys;
  value: ValidTimerange;
  shortLabel: string;
};

export const BASE_RANGE_OPTIONS = [
  {
    label: 'common.date_picker.last_hour',
    value: '1h',
    shortLabel: '1h',
  },
  {
    label: 'common.date_picker.last_12_hours',
    value: '12h',
    shortLabel: '12h',
  },
  {
    label: 'common.date_picker.last_24_hours',
    value: '24h',
    shortLabel: '24h',
  },
  {
    label: 'common.date_picker.today',
    value: 'today',
    shortLabel: '1d',
  },
  {
    label: 'common.date_picker.yesterday',
    value: 'yesterday',
    shortLabel: '1d',
  },
] as const satisfies RangeOption[];

export type DateTimeFilter = { startTime: string; endTime: string } | { timeRange: ValidTimerange };

export const dateTimeSchema = z.union([
  z.object({
    startTime: z.string(),
    endTime: z.string(),
  }),
  z.object({
    timeRange: z.enum(validTimeRanges).default(defaultTimeRange).catch(defaultTimeRange),
  }),
]);
