import { Handle, Position, type NodeProps } from '@xyflow/react';

import { Flag } from 'components/common/Flag';
import { Badge } from 'components/common/Badge';
import { DestIPIcon } from 'components/common/Icons';
import type { components } from 'types/schemas/api-schema';
import { Skeleton } from 'components/common/Skeleton';

export type ThreatEntityNodeProps = {
  data: {
    entity: string;
    geo: components['schemas']['threatEntity']['geo'];
    isLoading?: boolean;
  };
};

export function ThreatEntityNode({ data }: ThreatEntityNodeProps & NodeProps) {
  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Left} className="bg-transparent fill-transparent text-transparent" />
      <Skeleton isLoading={data.isLoading}>
        <div>
          <Badge color="gray" size="2" variant="soft">
            {data.geo?.countryIsoCode && data.geo.countryIsoCode !== 'lo' ? (
              <Flag code={data.geo.countryIsoCode} />
            ) : (
              <DestIPIcon />
            )}
            {data.entity}
          </Badge>
        </div>
      </Skeleton>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}
