import { useCurrentUser } from 'services/api/auth';
import type { Scope } from 'types/scopes';

type SimplePolicy = {
  scope: Scope;
  tenantId?: string;
};

export type Policy = SimplePolicy | { allPolicies: SimplePolicy[] } | { anyPolicy: SimplePolicy[] };

export type HasAccessFunction = (policy: Policy) => boolean;

export const useLazyHasAccess = (): ((policy: Policy) => boolean) => {
  const { data } = useCurrentUser();
  const userRoles = data?.data?.roles || [];

  const globalScopes = userRoles.flatMap((r) => r.scopes.global);
  const tenantRoleScopes = userRoles.map((role) => role.scopes.tenant).flat();

  const hasAccess = (p: SimplePolicy) => {
    if (globalScopes.includes(p.scope)) {
      return true;
    }
    if (p.tenantId) {
      return tenantRoleScopes
        .filter((s) => s.tenantId === p.tenantId)
        .flatMap((s) => s.scopes)
        .includes(p.scope);
    }

    const allTenantScopes = tenantRoleScopes.flatMap((s) => s.scopes);

    return allTenantScopes.includes(p.scope);
  };

  return (policy: Policy) => {
    if ('allPolicies' in policy) {
      return policy.allPolicies.every((p) => hasAccess(p));
    }

    if ('anyPolicy' in policy) {
      return policy.anyPolicy.some((p) => hasAccess(p));
    }

    return hasAccess(policy);
  };
};

export const useHasAccess = (policy: Policy): boolean => {
  const hasAccess = useLazyHasAccess();

  return hasAccess(policy);
};
