import { useState } from 'react';
import { endOfDay, format, isAfter, isValid, set } from 'date-fns';
import type { DateRange } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import './style.css';

import { useTranslate } from 'services/i18n/useTranslate';

import { Button } from 'components/common/Button';

import { TimePicker, type Time, type Period } from './TimePicker';

type DateTimeRange = {
  from: Date | undefined;
  to: Date | undefined;
  fromTime: Time;
  toTime: Time;
};

const DEFAULT_FROM_TIME: Time = { hour: '12', min: '00', period: 'AM' };
const DEFAULT_TO_TIME: Time = { hour: '11', min: '59', period: 'PM' };

const parsePartsFromDate = (date: Date | undefined, label: 'from' | 'to'): Time => {
  if (!date || !isValid(date)) {
    return label === 'from' ? DEFAULT_FROM_TIME : DEFAULT_TO_TIME;
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();
  return {
    hour: String(hours % 12 || 12).padStart(2, '0'),
    min: String(minutes).padStart(2, '0'),
    period: (hours >= 12 ? 'PM' : 'AM') as Period,
  };
};

const Footer = ({
  onConfirm,
  fromTime,
  toTime,
  setFromTime,
  setToTime,
  isDisabled,
}: {
  onConfirm: () => void;
  fromTime: Time;
  toTime: Time;
  setFromTime: (time: Time) => void;
  setToTime: (time: Time) => void;
  isDisabled: boolean;
}) => {
  const t = useTranslate();
  return (
    <div className="space-y-2 py-2">
      <div className="flex flex-col gap-2 rounded-2 border border-neutral-7 p-2">
        <div className="flex flex-col gap-2">
          <TimePicker
            label={t('common.date_picker.from')}
            hour={fromTime.hour}
            min={fromTime.min}
            period={fromTime.period}
            onTimeChange={(hour, min, period) => setFromTime({ hour, min, period })}
          />
          <TimePicker
            label={t('common.date_picker.to')}
            hour={toTime.hour}
            min={toTime.min}
            period={toTime.period}
            onTimeChange={(hour, min, period) => setToTime({ hour, min, period })}
          />
        </div>
      </div>
      <Button size="1" variant="surface" onClick={onConfirm} disabled={isDisabled}>
        {t('common.date_picker.confirm')}
      </Button>
    </div>
  );
};

const customFormatWeekdayName = (weekday: Date) => {
  return format(weekday, 'EEE');
};

function adjustDate(date: Date, time: Time, label: 'from' | 'to') {
  return set(new Date(date), {
    hours: time.period === 'PM' ? (parseInt(time.hour) % 12) + 12 : parseInt(time.hour) % 12,
    minutes: parseInt(time.min),
    seconds: label === 'from' ? 0 : 59,
  });
}

export function DateTimePicker({
  from,
  to,
  onChange,
}: {
  from: Date | undefined;
  to: Date | undefined;
  onChange: (range: { from: Date; to: Date }) => void;
}) {
  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    from,
    to,
    fromTime: parsePartsFromDate(from, 'from'),
    toTime: parsePartsFromDate(to, 'to'),
  });

  const isTimeRangeValid = () => {
    const { from, to, fromTime, toTime } = dateTimeRange;

    const baseToDate = to || endOfDay(from!);

    const adjustedFrom = adjustDate(from!, fromTime, 'from');
    const adjustedTo = adjustDate(baseToDate, toTime, 'to');

    return isAfter(adjustedTo, adjustedFrom);
  };

  const handleConfirm = () => {
    const { from, to, fromTime, toTime } = dateTimeRange;

    const adjustedFrom = adjustDate(from!, fromTime, 'from');

    let adjustedTo;
    if (to) {
      adjustedTo = adjustDate(to, toTime, 'to');
    } else {
      adjustedTo = adjustDate(from!, toTime, 'to');
    }

    onChange({ from: adjustedFrom, to: adjustedTo });
  };

  return (
    <DayPicker
      mode="range"
      showOutsideDays
      selected={{ from: dateTimeRange.from, to: dateTimeRange.to }}
      onSelect={(range: DateRange | undefined) => {
        if (range) {
          setDateTimeRange((prev) => ({
            ...prev,
            from: range.from,
            to: range.to,
          }));
        } else {
          setDateTimeRange((prev) => ({
            ...prev,
            from: undefined,
            to: undefined,
          }));
        }
      }}
      disabled={{ after: new Date() }}
      footer={
        <Footer
          onConfirm={handleConfirm}
          fromTime={dateTimeRange.fromTime}
          toTime={dateTimeRange.toTime}
          setFromTime={(time) => setDateTimeRange((prev) => ({ ...prev, fromTime: time }))}
          setToTime={(time) => setDateTimeRange((prev) => ({ ...prev, toTime: time }))}
          isDisabled={!isTimeRangeValid()}
        />
      }
      formatters={{ formatWeekdayName: (weekday) => customFormatWeekdayName(weekday) }}
    />
  );
}
