import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { useStore } from 'services/store';
import { Button } from 'components/common/Button';
import mixmodeLogotypeDark from 'static/images/mixmode-logo-text-dark.svg';
import mixmodeLogotypeLight from 'static/images/mixmode-logo-text-light.svg';

export const CrashAndBurn = ({ error }: { error: Error }) => {
  const currentTheme = useStore((state) => state.theme.theme);

  useEffect(() => {
    if (import.meta.env.PROD && import.meta.env.VITE_DEPLOYMENT_TYPE !== 'enterprise') {
      Sentry.withScope((scope) => {
        scope.setTag('hostname', window.location.hostname);

        Sentry.captureException(error);
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('ERROR', error);
    }
  }, [error]);

  return (
    <div className="fixed inset-0 grid place-content-center text-center">
      <div className="animate-hinge">
        <img
          src={currentTheme === 'light' ? mixmodeLogotypeDark : mixmodeLogotypeLight}
          alt="Mixmode Logo"
          className="mx-auto mb-6 w-96"
        />
      </div>

      <div className="py-2 text-3xl">Oops. We broke something...</div>

      <div className="mb-1">Developers have been notified about their horrible mistake.</div>

      <Button
        variant="ghost"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Click here to try something else
      </Button>
    </div>
  );
};
