import { useMemo, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { LOADING_THREATS } from 'mocks/threat';
import { fullDateTimeFormat } from 'consts/dateFormats';
import { useTranslate } from 'services/i18n/useTranslate';
import type { components } from 'types/schemas/api-schema';

import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { Tabs, TabsList, TabTrigger } from 'components/common/Tabs';
import { Collapsible } from 'components/common/Collapsible';
import { Badge } from 'components/common/Badge';
import { Text } from 'components/common/Text';
import { ThreatEntityBadge } from 'components/scenes/Threats/common/ThreatsBadges';
import { Skeleton } from 'components/common/Skeleton';

type TabType = (typeof TABS)[number];
type Evidence = components['schemas']['threatEvidence'];
type Perception = components['schemas']['threatPerception'];

const TABS = ['findings', 'investigation-details'] as const;
const DEFAULT_DATA: Perception[] = [];
const PerceptionsColumnHelper = createColumnHelper<Perception>();

const PerceptionTable = ({ isLoading, perceptions = [] }: { isLoading: boolean; perceptions?: Perception[] }) => {
  const t = useTranslate();

  const columns = useMemo(() => {
    return [
      PerceptionsColumnHelper.accessor('createdAt', {
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.timestamp')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{format(info.getValue(), fullDateTimeFormat)}</Text>
          </Skeleton>
        ),
      }),
      PerceptionsColumnHelper.accessor((row) => row.entities?.[0], {
        id: 'source_entity',
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.source_entity')}</Text>,
        cell: (info) => {
          const sourceEntity = info.getValue();
          return sourceEntity ? (
            <Skeleton isLoading={isLoading}>
              <ThreatEntityBadge entity={sourceEntity} />
            </Skeleton>
          ) : null;
        },
      }),
      PerceptionsColumnHelper.accessor((row) => row.entities?.[1], {
        id: 'destination_entity',
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.destination_entity')}</Text>,
        cell: (info) => {
          const destinationEntity = info.getValue();
          return destinationEntity ? (
            <Skeleton isLoading={isLoading}>
              <ThreatEntityBadge entity={destinationEntity} />
            </Skeleton>
          ) : null;
        },
      }),
      PerceptionsColumnHelper.accessor((row) => row.entities?.[0]?.type, {
        id: 'type',
        header: () => <Text>{t('threats.evidence.tabs.findings.table.headers.type')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
    ];
  }, [t, isLoading]);

  const table = useReactTable({
    data: perceptions ?? DEFAULT_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table.Root variant="surface" className="!rounded-2" size="1">
      <Table.Header className="bg-neutral-a2">
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <SortableColumnHeaderCell key={header.id} header={header} />
            ))}
          </Table.Row>
        ))}
      </Table.Header>

      <Table.Body>
        {table.getRowModel().rows.map((row) => {
          return (
            <Table.Row key={row.id} align="center">
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};

const Findings = ({ isLoading, evidence }: { isLoading: boolean; evidence: Evidence }) => {
  return (
    <div className="bg-neutral-3">
      <Collapsible
        key={evidence.id}
        chevronPosition="left"
        variant="transparent"
        noBorder
        defaultOpen
        title={
          <Skeleton isLoading={isLoading}>
            <Badge size="1" variant="soft" color="sky" className="mr-1">
              {evidence.name}
            </Badge>
            <Text size="1" className="text-neutral-10">
              Observed unusual HTTP/S traffic that resembles beaconing behavior.
            </Text>
          </Skeleton>
        }
      >
        <div className="space-y-2 px-2 pb-2">
          <div className="max-w-3xl bg-neutral-4 p-2">
            <Text size="2" className="text-neutral-11">
              <Skeleton isLoading={isLoading}>{evidence.description}</Skeleton>
            </Text>
          </div>

          <PerceptionTable isLoading={isLoading} perceptions={evidence.observations[0]?.perceptions} />
        </div>
      </Collapsible>
    </div>
  );
};

const InvestigationDetails = () => {
  return <div className="bg-neutral-3 p-2">Investigation details</div>;
};

export const ThreatEvidence = ({ isLoading, evidence }: { isLoading: boolean; evidence?: Evidence[] }) => {
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState<TabType>(TABS[0]);

  const evidenceData = evidence ?? LOADING_THREATS[0]?.evidence ?? [];

  const handleTabChange = (value: string) => {
    setActiveTab(value as TabType);
  };

  return (
    <div className="mt-3 border-b border-neutral-a5 pb-3">
      <Tabs type="enclosed" size="1" value={activeTab} onValueChange={handleTabChange}>
        <TabsList className="border-b border-neutral-a5">
          <TabTrigger value={TABS[0]}>{t('threats.evidence.tabs.findings.label')}</TabTrigger>
          <TabTrigger value={TABS[1]}>{t('threats.evidence.tabs.investigation_details.label')}</TabTrigger>
        </TabsList>
        <div className="bg-neutral-2 p-4">
          {activeTab === TABS[0] ? (
            evidenceData.length > 0 &&
            evidenceData.map((e) => <Findings isLoading={isLoading} key={e.id} evidence={e} />)
          ) : (
            <InvestigationDetails />
          )}
        </div>
      </Tabs>
    </div>
  );
};
