import * as React from 'react';

import { cn } from 'utils/styles';
import { InfoCircledIcon, QuestionMarkCircledIcon, QuestionMarkCircledIconFilled } from 'components/common/Icons';
import { Tooltip, type TooltipProps } from 'components/common/Tooltip';

export type InfoTooltipProps = Omit<TooltipProps, 'children'> & {
  icon?: 'default' | 'filled' | 'info';
};

const iconMap = {
  default: QuestionMarkCircledIcon,
  filled: QuestionMarkCircledIconFilled,
  info: InfoCircledIcon,
};

export const InfoTooltip = React.forwardRef<HTMLDivElement, InfoTooltipProps>(
  ({ className, icon = 'default', ...props }, ref) => {
    const IconComponent = iconMap[icon];

    return (
      <Tooltip {...props} ref={ref} delayDuration={200}>
        <IconComponent className={cn('size-4', className)} />
      </Tooltip>
    );
  },
);
