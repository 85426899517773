import { useCallback } from 'react';
import { type TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

import type { FlattenedTranslationKeys } from 'types/translations';

export const useTranslate = () => {
  const { t } = useTranslation();

  return useCallback(
    (tkey: FlattenedTranslationKeys, options?: TOptions | Record<string, any>) => {
      return t(tkey, options);
    },
    [t],
  );
};
