import React, { type ButtonHTMLAttributes } from 'react';

import { cn } from 'utils/styles';

type ButtonLinkProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>((props, ref) => {
  return (
    <button
      ref={ref}
      role="button"
      {...props}
      className={cn('rt-Text rt-reset rt-Link rt-underline-auto !text-sm', props.className)}
      data-accent-color={props.color}
    />
  );
});
