import { useTranslate } from 'services/i18n/useTranslate';

export function CustomEnrichmentFormattingInstructions() {
  const t = useTranslate();
  return (
    <div className="px-3 py-4 text-sm">
      <div className="mb-6">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_header')}:</div>

      <code>
        <pre className="mb-6">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_body')}</pre>
      </code>

      <div className="mb-1">{t('settings.tabs.enrichments.subtabs.manage.sidebar.format_instructions_label')}:</div>

      <div className="w-[534px] border border-neutral-a7 bg-neutral-6 p-1">
        <pre>
          <code>
            {`destination.ip = 93.143.102.249, source.importance, 10
source.ip ISSUBSET 192.168.255.255/16, source.importance, 9
destination.mac = 00:1B:44:11:3A:B7, destination.importance, 6
source.mac = 00:1B:44:11:3A:B7, source.importance, 9
user.name MATCHES user21, destination.importance, 6`}
          </code>
        </pre>
      </div>
    </div>
  );
}
