import * as React from 'react';
import { Tooltip as RadixTooltip } from '@radix-ui/themes';
import { cn } from 'utils/styles';

export type TooltipProps = React.ComponentProps<typeof RadixTooltip>;

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(({ children, className, ...props }, ref) => {
  return (
    <RadixTooltip {...props} ref={ref} className={cn('max-w-[250px]', className)}>
      {children}
    </RadixTooltip>
  );
});
