import * as z from 'zod';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { ManageUsers } from 'components/scenes/Settings/UsersAndAccess/ManageUsers';

export const Route = createFileRoute('/_app/settings/users-and-access/users')({
  component: ManageUsers,
  validateSearch: zodSearchValidator(
    z
      .object({})
      .or(
        z.object({
          userSidebarIntent: z.enum(['create']),
        }),
      )
      .or(
        z.object({
          userSidebarIntent: z.enum(['edit', 'view']),
          userId: z.string(),
        }),
      ),
  ),
});
