import { useMutation, useQuery } from '@tanstack/react-query';

import { type paths } from 'types/schemas/api-schema';

import { api } from '.';

type RolesResponse = paths['/auth/roles']['get']['responses']['200']['content']['application/json'];

export const useRoles = () => {
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => {
      return api
        .url('/auth/roles')
        .get()
        .json((res: RolesResponse) => {
          return res;
        });
    },
  });
};

export const useGlobalRoles = () => {
  return useQuery({
    queryKey: ['globalRoles'],
    queryFn: () => {
      return api
        .url('/auth/roles')
        .get()
        .json((res: RolesResponse) => {
          const globalAdminRole = res.data.find(
            (role) => role.systemCreated && !role.ownedTenantId && role.roleType === 'admin',
          )!;
          const globalAnalystRole = res.data.find(
            (role) => role.systemCreated && !role.ownedTenantId && role.roleType === 'analyst',
          )!;

          return {
            globalAdminRole,
            globalAnalystRole,
          };
        });
    },
  });
};

export const useTenantRoles = (tenantId: string) => {
  return useQuery({
    queryKey: ['tenantRoles', tenantId],
    queryFn: () => {
      return api
        .url('/auth/roles')
        .get()
        .json((res: RolesResponse) => {
          const adminRole = res.data.find((role) => role.ownedTenantId === tenantId && role.roleType === 'admin')!;
          const analystRole = res.data.find((role) => role.ownedTenantId === tenantId && role.roleType === 'analyst')!;

          return {
            adminRole,
            analystRole,
          };
        });
    },
  });
};

export const useAddRoleToUser = () => {
  return useMutation({
    mutationFn: async ({
      userId,
      ...data
    }: {
      userId: string;
    } & paths['/auth/users/{userId}/roles']['post']['requestBody']['content']['application/json']) => {
      await api
        .url(`/auth/users/${userId}/roles`)
        .post(data)
        .json((res: paths['/auth/users/{userId}/roles']['post']['responses']['200']['content']['application/json']) => {
          return res;
        });
    },
  });
};

export const useRemoveRoleFromUser = () => {
  return useMutation({
    mutationFn: async ({
      userId,
      roleId,
    }: paths['/auth/users/{userId}/roles/{roleId}']['delete']['parameters']['path']) => {
      await api.delete(`/auth/users/${userId}/roles/${roleId}`).res();

      return null;
    },
  });
};
