import React, { useState } from 'react';

import { ScrollArea } from 'components/common/ScrollArea';
import { FacetSection, SearchableFacetSection, type Option } from 'components/common/FacetSection';

import type { FlattenedTranslationKeys } from 'types/translations';
import { HIGH_RISK_SCORES, MEDIUM_RISK_SCORES, LOW_RISK_SCORES } from 'consts/threats';
import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useThreatsEntityFacets, useThreatsFacets, useThreatsParams } from 'services/api/threats';
import { useDebouncedValue } from 'utils/hooks';
import { getPipelinesFacetOptions, getTenantFacetOptions, groupFacetRiskScores } from 'utils/detections';

import { useThreatsContext, type ThreatsFilterProperty } from './ThreatsContext';

const THREAT_STATUS_TRANSLATIONS: Record<string, FlattenedTranslationKeys> = {
  active: 'common.threat_active',
  complete: 'common.threat_complete',
};

const LoadingFacet = () => {
  return (
    <FacetSection
      isLoading
      title={'Loading___'}
      unit="entry"
      property=""
      onClick={() => {}}
      options={[
        { count: 0, label: 'Loading', percentage: 0, value: '1' },
        { count: 0, label: 'Loading More', percentage: 0, value: '2' },
        { count: 0, label: 'Another value', percentage: 0, value: '3' },
      ]}
    />
  );
};

const Content = React.memo(() => {
  const t = useTranslate();
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();
  const threatParams = useThreatsParams();
  const threatsFacetsQuery = useThreatsFacets({
    ...threatParams,
  });
  const [entitiesFilter, setEntitiesFilter] = useState<string>('');
  const [entitiesSort, setEntitiesSort] = useState<'asc' | 'desc'>('desc');
  const { toggleFilter } = useThreatsContext();
  const debouncedEntitiesFilter = useDebouncedValue(entitiesFilter, 500);
  const threatsEntityFacetsQuery = useThreatsEntityFacets({
    ...threatParams,
    limit: 100,
    sort: entitiesSort,
    ...(debouncedEntitiesFilter.length >= 2 && {
      entityPrefix: debouncedEntitiesFilter,
    }),
  });

  if (
    threatsFacetsQuery.isLoading ||
    pipelinesQuery.isLoading ||
    tenantsQuery.isLoading ||
    threatsEntityFacetsQuery.isLoading
  ) {
    return [...Array<number>(10)].map((_, i) => <LoadingFacet key={i} />);
  }

  if (threatsFacetsQuery.isError || pipelinesQuery.isError || tenantsQuery.isError) {
    return <div className="px-3">Error loading facets</div>;
  }

  const facetsData = threatsFacetsQuery.data!.data;

  const confidenceScoreOptions = groupFacetRiskScores(facetsData.riskScores || []);
  const tenantOptions = getTenantFacetOptions(facetsData.tenantIds || [], tenantsQuery.data!.data);
  const pipelineOptions = getPipelinesFacetOptions(facetsData.pipelineIds || [], pipelinesQuery.data!.data);

  const handleFacetClick = (property: string, option: Option) => {
    toggleFilter(property as ThreatsFilterProperty, option.value.toString());
  };

  return (
    <>
      <FacetSection
        title={t('common.risk_score')}
        unit="range"
        property="riskScore"
        onClick={handleFacetClick}
        options={[
          {
            ...confidenceScoreOptions.high,
            label: t('common.score.high_range', {
              low: HIGH_RISK_SCORES[0],
              high: HIGH_RISK_SCORES[HIGH_RISK_SCORES.length - 1]!,
            }),
          },
          {
            ...confidenceScoreOptions.medium,
            label: t('common.score.medium_range', {
              low: MEDIUM_RISK_SCORES[0],
              high: MEDIUM_RISK_SCORES[MEDIUM_RISK_SCORES.length - 1]!,
            }),
          },
          {
            ...confidenceScoreOptions.low,
            label: t('common.score.low_range', {
              low: LOW_RISK_SCORES[0],
              high: LOW_RISK_SCORES[LOW_RISK_SCORES.length - 1]!,
            }),
          },
        ]}
      />

      <FacetSection
        title={t('common.threat_status')}
        unit="entry"
        property="status"
        onClick={handleFacetClick}
        options={
          threatsFacetsQuery.data?.data.statuses.map((status) => ({
            label: t(THREAT_STATUS_TRANSLATIONS[status.value]!),
            value: status.value,
            count: status.count,
            percentage: status.percentage,
          })) || []
        }
      />

      <FacetSection
        title={t('common.tenants')}
        unit="entry"
        property="tenantId"
        hideWhenSingleOption
        onClick={handleFacetClick}
        options={tenantOptions}
      />

      <FacetSection
        title={t('common.pipelines')}
        unit="entry"
        property="pipelineId"
        hideWhenSingleOption
        onClick={handleFacetClick}
        options={pipelineOptions}
      />

      <FacetSection
        title={t('common.workflow_status')}
        unit="entry"
        property="workflowStatus"
        onClick={handleFacetClick}
        options={(threatsFacetsQuery.data?.data.workflowStatuses || []).map((ws) => ({
          label: t(`common.workflow_status_options.${ws.value}` as FlattenedTranslationKeys),
          value: ws.value,
          count: ws.count,
          percentage: ws.percentage,
        }))}
      />

      <FacetSection
        title={t('common.user_assignment')}
        unit="entry"
        property="workflowAssignedTo"
        onClick={handleFacetClick}
        options={(threatsFacetsQuery.data?.data.assignees || []).map((ws) => ({
          label: ws.value === 'null' ? t('common.unassigned') : ws.value,
          value: ws.value,
          count: ws.count,
          percentage: ws.percentage,
        }))}
      />

      {!(!threatsEntityFacetsQuery.data?.data.entities?.length && debouncedEntitiesFilter === '') && (
        <SearchableFacetSection
          title={t('common.entities')}
          unit="entry"
          property="entity"
          sort={entitiesSort}
          filter={entitiesFilter}
          onClick={handleFacetClick}
          isFetching={threatsEntityFacetsQuery.isFetching}
          onSortClick={() => setEntitiesSort(entitiesSort === 'asc' ? 'desc' : 'asc')}
          onFilterChange={setEntitiesFilter}
          options={(threatsEntityFacetsQuery.data?.data.entities || []).map((e) => ({
            label: e.value,
            value: e.value,
            count: e.count,
            percentage: e.percentage,
          }))}
        />
      )}

      <FacetSection
        title={t('common.geography')}
        unit="entry"
        property="geo"
        onClick={handleFacetClick}
        options={(threatsFacetsQuery.data?.data.geos || []).map((geo) => ({
          label: geo.value,
          value: geo.value,
          count: geo.count,
          percentage: geo.percentage,
        }))}
      />

      {facetsData.dimensions?.map((dim) => (
        <FacetSection
          key={dim.name}
          title={dim.name}
          unit="entry"
          property={dim.name}
          onClick={(prop, option) => toggleFilter('dimension', `${prop}:${option.value}`)}
          defaultOpen={false}
          options={(dim.facets || []).map((e) => ({
            label: e.value,
            value: e.value,
            count: e.count,
            percentage: e.percentage,
          }))}
        />
      ))}
    </>
  );
});

export const Facets = () => {
  return (
    <div className="flex-1" data-test-id="threats-facets-tree">
      <ScrollArea
        style={{
          height: 'calc(100vh - 171px)',
        }}
      >
        <Content />
      </ScrollArea>
    </div>
  );
};
