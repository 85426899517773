import { useState } from 'react';

import { useTranslate } from 'services/i18n/useTranslate';
import { useDeletePipeline } from 'services/api/pipelines';

import { Label } from 'components/common/Label';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { InlineDialog } from 'components/common/InlineDialog';

type DeletePipelineInlineDialogProps = {
  pipelineId: string;
  pipelineName: string | undefined;
  onCancel: () => void;
  onSuccess: () => void;
};

export const DeletePipelineInlineDialog = ({
  pipelineId,
  pipelineName,
  onCancel,
  onSuccess,
}: DeletePipelineInlineDialogProps) => {
  const t = useTranslate();
  const deletePipelineMutation = useDeletePipeline(pipelineId);
  const [pipelineNameConfirm, setPipelineNameConfirm] = useState('');

  const handleDeletePipeline = () => {
    deletePipelineMutation.mutate(undefined, {
      onSuccess,
    });
  };

  return (
    <InlineDialog
      title={t('settings.tabs.platform.subtabs.pipelines.sidebar.delete_prompt_title')}
      className="mb-3"
      footer={
        <>
          <Button size="1" color="gray" variant="soft" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            size="1"
            color="red"
            disabled={pipelineNameConfirm !== pipelineName}
            isLoading={deletePipelineMutation.isPending}
            onClick={handleDeletePipeline}
          >
            {t('common.permanently_delete')}
          </Button>
        </>
      }
    >
      <div className="mb-2">
        {t('settings.tabs.platform.subtabs.pipelines.sidebar.delete_prompt_text_1')}
        <ul className="mt-0.5 list-outside list-disc pl-8">
          <li>{pipelineName}</li>
        </ul>
      </div>

      <div>{t('settings.tabs.platform.subtabs.pipelines.sidebar.delete_prompt_text_2')}</div>

      <Label htmlFor="pipelineNameInput" className="mb-1 mt-3 inline-block">
        {t('settings.tabs.platform.subtabs.pipelines.sidebar.pipeline_name_label')}
      </Label>
      <TextField
        id="pipelineNameInput"
        size="2"
        placeholder={t('settings.tabs.platform.subtabs.pipelines.sidebar.pipeline_name_placeholder')}
        variant="surface"
        value={pipelineNameConfirm}
        onChange={(e) => setPipelineNameConfirm(e.target.value)}
      />

      {deletePipelineMutation.isError && (
        <Callout className="mt-3" variant="soft" status="error" size="1" onClose={() => deletePipelineMutation.reset()}>
          {t('settings.tabs.platform.subtabs.pipelines.sidebar.delete_error')}
        </Callout>
      )}
    </InlineDialog>
  );
};
