import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import * as CollapsiblePrimitives from 'components/common/CollapsiblePrimitive';
import { ChevronUpIcon, ChevronDownIcon, TriangleDownIcon, TriangleRightIcon } from 'components/common/Icons';
import { IconButton } from 'components/common/IconButton';
import { Badge } from 'components/common/Badge';
import { cn } from 'utils/styles';
import { useTranslate } from 'services/i18n/useTranslate';

type CollapsibleProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  Icon?: React.ElementType;
  badge?: number;
  variant?: 'default' | 'transparent';
  chevronPosition?: 'left' | 'right';
  noBorder?: boolean;
};

export const Collapsible = ({
  title,
  children,
  defaultOpen = false,
  onOpenChange,
  Icon,
  badge,
  variant = 'default',
  chevronPosition = 'right',
  noBorder = false,
}: CollapsibleProps) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  const variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  };

  const transition = {
    duration: 0.25,
    ease: 'easeInOut',
  };

  return (
    <CollapsiblePrimitives.Root
      open={isOpen}
      onOpenChange={handleOpenChange}
      className={cn(
        variant === 'default' && 'rounded-2 border border-neutral-a5 bg-solid',
        variant === 'transparent' && 'bg-transparent',
      )}
    >
      <CollapsiblePrimitives.Trigger asChild>
        <div
          className={cn(
            'flex cursor-pointer items-center justify-between gap-2 p-2',
            !noBorder && 'border-b border-neutral-a5',
          )}
        >
          {chevronPosition === 'left' && (
            <IconButton
              type="button"
              variant="ghost"
              color="gray"
              aria-label={t('common.toggle')}
              className="!-mr-4 hover:bg-transparent hover:text-neutral-12"
            >
              {isOpen ? <TriangleDownIcon /> : <TriangleRightIcon />}
            </IconButton>
          )}
          <div>{Icon && <Icon />}</div>
          <div className="mr-1 flex flex-1 items-center justify-between">
            <span>{title}</span>
            {badge && (
              <Badge size="1" variant="outline" radius="full" color="gray">
                {badge}
              </Badge>
            )}
          </div>
          {chevronPosition === 'right' && (
            <IconButton type="button" variant="ghost" color="gray" aria-label={t('common.toggle')}>
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          )}
        </div>
      </CollapsiblePrimitives.Trigger>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            style={{ overflow: 'hidden' }}
            transition={transition}
            variants={variants}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </CollapsiblePrimitives.Root>
  );
};
