import { type ReactNode, useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as DialogPrimitives from '@radix-ui/react-dialog';

import { cn } from 'utils/styles';
import { Text } from 'components/common/Text';
import { Heading } from 'components/common/Heading';
import { Cross1Icon } from 'components/common/Icons';

type Size = 'small' | 'medium' | 'large' | 'xLarge';
type Mode = 'embedded' | 'modal';

const SIZE_WIDTH_MAP: Record<Size, number> = {
  small: 300,
  medium: 480,
  large: 800,
  xLarge: 1024,
};

const TRANSITION_DURATION = 0.25;

const getContentMotionProps = (size: Size, mode: Mode) => {
  if (mode === 'modal') {
    return {
      initial: { opacity: 0, width: SIZE_WIDTH_MAP[size] },
      animate: { opacity: 1, width: SIZE_WIDTH_MAP[size] },
      exit: { opacity: 0, width: SIZE_WIDTH_MAP[size] },
      transition: { ease: 'easeInOut', duration: TRANSITION_DURATION },
    };
  }

  return {
    initial: { opacity: 1, width: 0 },
    animate: { opacity: 1, width: SIZE_WIDTH_MAP[size] },
    exit: { opacity: 1, width: 0 },
    transition: { ease: 'easeInOut', duration: TRANSITION_DURATION },
  };
};

const OVERLAY_MOTION_PROPS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeInOut', duration: TRANSITION_DURATION },
};

type DrawerProps = {
  size?: Size;
  mode?: 'embedded' | 'modal';
  side?: 'left' | 'center' | 'right';
  title: string;
  description?: string;
  trigger?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  insetContent?: boolean;
};

type ControlledDrawerProps = {
  isOpen: boolean;
  onOpenChange: (newValue: boolean) => void;
};

type UncontrolledDrawerProps = {
  isOpen?: never;
  onOpenChange?: never;
};

export const Drawer = ({
  size = 'medium',
  mode = 'embedded',
  side = 'center',
  title,
  description,
  actions,
  trigger,
  insetContent,
  isOpen,
  onOpenChange,
  children,
}: DrawerProps & (ControlledDrawerProps | UncontrolledDrawerProps)) => {
  const [_isOpen, _setIsOpen] = useState(false);
  const [embeddedContainer, setEmbeddedContainer] = useState<HTMLElement | null>(null);
  const [modalContainer, setModalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setEmbeddedContainer(document.getElementById('content'));
    setModalContainer(document.getElementById('theme-container'));
  }, []);

  const handleOpenChange = useCallback(
    (newValue: boolean) => {
      if (onOpenChange) {
        onOpenChange(newValue);
      } else {
        _setIsOpen(newValue);
      }

      if (!newValue) {
        // there seems to be a bug with radix ui where it sometimes doesn't reset pointer events
        // the delay duration is arbitrary
        setTimeout(() => {
          document.body.style.pointerEvents = '';
        }, 600);
      }
    },
    [onOpenChange],
  );

  const isDrawerOpen = isOpen || _isOpen;

  return (
    <DialogPrimitives.Root modal={mode === 'modal'} open={isDrawerOpen} onOpenChange={handleOpenChange}>
      <DialogPrimitives.Trigger asChild>{trigger}</DialogPrimitives.Trigger>
      <AnimatePresence>
        {isDrawerOpen ? (
          <DialogPrimitives.Portal
            forceMount
            container={(mode === 'embedded' ? embeddedContainer : modalContainer) || modalContainer}
          >
            <DialogPrimitives.Overlay forceMount asChild className="fixed inset-0 z-10 bg-overlay">
              <motion.div {...OVERLAY_MOTION_PROPS} className="fixed inset-0" />
            </DialogPrimitives.Overlay>

            <DialogPrimitives.Content
              className={cn(
                'fixed inset-y-0 z-50 flex h-screen flex-col overflow-clip bg-neutral-2 text-neutral-12 shadow-[inset_0_0_2px_0_#00000022]',
                {
                  'left-1/2 -translate-x-1/2': mode === 'modal' && side === 'center',
                  'right-0': side === 'right',
                },
              )}
              asChild
              forceMount
            >
              <motion.div {...getContentMotionProps(size, mode)}>
                <div
                  className="flex flex-1 flex-col overflow-y-auto"
                  style={{
                    width: SIZE_WIDTH_MAP[size],
                  }}
                  data-test-id="drawer"
                >
                  <div className="flex justify-between shadow-[inset_0_-1px_0_0_var(--gray-7)]">
                    <div className="my-2 ml-4">
                      <DialogPrimitives.Title asChild>
                        <Heading as="h2" weight="bold" size="3" className="py-0.5 font-bold !leading-[26px]">
                          {title}
                        </Heading>
                      </DialogPrimitives.Title>
                      {description && (
                        <DialogPrimitives.Description asChild>
                          <Text as="p" size="2" mt="2" className="!leading-6">
                            {description}
                          </Text>
                        </DialogPrimitives.Description>
                      )}
                    </div>

                    <div className="mr-5 mt-2.5 flex flex-col items-end justify-between">
                      <DialogPrimitives.Close data-test-id="drawer-close-button">
                        <Cross1Icon width="16px" height="16px" />
                      </DialogPrimitives.Close>

                      {actions ? <div className="mb-[12px]">{actions}</div> : <div />}
                    </div>
                  </div>

                  <div className={cn('flex flex-1 flex-col', insetContent ? '' : 'p-4')}>{children}</div>
                </div>
              </motion.div>
            </DialogPrimitives.Content>
          </DialogPrimitives.Portal>
        ) : null}
      </AnimatePresence>
    </DialogPrimitives.Root>
  );
};
