import { useTranslate } from 'services/i18n/useTranslate';

import { Text } from 'components/common/Text';
import { Badge } from 'components/common/Badge';

export const AccountSuspended = () => {
  const t = useTranslate();

  return (
    <>
      <Badge size="2" variant="solid" color="red" className="mb-4">
        {t('settings.common.suspended')}
      </Badge>
      <Text size="2" className="text-center">
        {t('login.suspended_message')}
      </Text>
    </>
  );
};
