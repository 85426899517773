import { useTranslate } from 'services/i18n/useTranslate';

import { Button } from 'components/common/Button';
import { Link2Icon } from 'components/common/Icons';

export const CopyInvitationLinkButton = ({
  isLinkClickable,
  handleRequestReset,
}: {
  isLinkClickable: boolean;
  handleRequestReset: () => void;
}) => {
  const t = useTranslate();

  return (
    <Button
      type="button"
      disabled={!isLinkClickable}
      variant="ghost"
      color="orange"
      size="1"
      onClick={handleRequestReset}
    >
      <Link2Icon />
      {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.copy_invitation_link')}
    </Button>
  );
};
