import { useEffect, useState } from 'react';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from '@tanstack/react-router';

import { setToken } from 'services/api';
import { useStore } from 'services/store';
import { useLogin } from 'services/api/auth';
import { useTranslate } from 'services/i18n/useTranslate';
import * as errorMessages from 'consts/errorMessages';

import { Text } from 'components/common/Text';
import { Logo } from 'components/common/Logo';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { TextField } from 'components/common/TextField';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/common/Form';

import { AccountSuspended } from './AccountSuspended';
import { ForgotPassword } from './ForgotPassword';

const loginSchema = z.object({
  email: z.string().email(errorMessages.email).min(1, errorMessages.required),
  password: z.string().min(1, errorMessages.required),
});

type LoginFields = z.infer<typeof loginSchema>;

const LoginSuccessCallout = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const [locationState, setLocationState] = useState(() => location.state || {});

  useEffect(() => {
    if (location.state.data) {
      setLocationState(location.state);
      void navigate({
        state: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!locationState.data?.id) {
    return null;
  }

  switch (locationState.data.id) {
    case 'accountCreated':
      return (
        <Callout size="1" variant="soft" status={'success'} className="mb-10 w-full">
          {t('login.callout_password_created', { email: locationState.data.email })}
        </Callout>
      );
    case 'passwordReset':
      return (
        <Callout size="1" variant="soft" status={'success'} className="mb-10 w-full">
          {t('login.callout_password_reset', { email: locationState.data.email })}
        </Callout>
      );
    default:
      return null;
  }
};

export const Login = () => {
  const t = useTranslate();
  const [isSuspended, setIsSuspended] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const form = useForm<LoginFields>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const setAuthToken = useStore((state) => state.auth.setAuthToken);

  const loginMutation = useLogin();

  const email = form.watch('email');
  const password = form.watch('password');

  const handleLoginClick = (data: LoginFields): void => {
    loginMutation.mutate(data, {
      onSuccess: (r) => {
        setToken(r.data.jwt);
        setAuthToken(r.data.jwt);
      },
      onError: (e) => {
        if (e.status === 403) {
          setIsSuspended(true);
          return;
        }
        const message = e?.json?.errors?.[0]?.message || e.message;

        form.setError('password', { message });
      },
    });
  };

  if (isForgotPassword) {
    return <ForgotPassword onBackToLogin={() => setIsForgotPassword(false)} />;
  }

  if (isSuspended) {
    return <AccountSuspended />;
  }

  return (
    <>
      <LoginSuccessCallout />

      <Logo className="mb-10 h-[61px]" />

      <Form {...form}>
        <form
          className="flex flex-col items-center space-y-4"
          onSubmit={form.handleSubmit(handleLoginClick)}
          data-test-id="login-form"
        >
          <Text weight="medium" size="5">
            {t('login.title')}
          </Text>

          <Text size="2" className="text-center">
            {t('login.subtitle')}
          </Text>

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormControl>
                    <TextField {...field} type="email" placeholder={t('common.email')} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormControl>
                    <TextField {...field} type="password" placeholder={t('common.password')} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <Button
            className="w-full"
            isLoading={loginMutation.isPending}
            variant={!email || !password ? 'soft' : 'solid'}
            type="submit"
          >
            {t('login.button')}
          </Button>
        </form>
      </Form>

      <Text
        size="1"
        className="!mt-4 block cursor-pointer hover:underline"
        role="button"
        onClick={() => setIsForgotPassword(true)}
      >
        {t('login.forgot_password')}
      </Text>
    </>
  );
};
