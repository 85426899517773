import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useForgotPassword } from 'services/api/auth';
import { useTranslate } from 'services/i18n/useTranslate';
import * as errorMessages from 'consts/errorMessages';

import { Text } from 'components/common/Text';
import { Logo } from 'components/common/Logo';
import { Button } from 'components/common/Button';
import { TextField } from 'components/common/TextField';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/common/Form';

const forgotPasswordSchema = z.object({
  email: z.string().email(errorMessages.email).min(1, errorMessages.required),
});

type ForgotPasswordFields = z.infer<typeof forgotPasswordSchema>;

export const ForgotPassword = ({ onBackToLogin }: { onBackToLogin: () => void }) => {
  const t = useTranslate();
  const form = useForm<ForgotPasswordFields>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const email = form.watch('email');

  const forgotPasswordMutation = useForgotPassword();

  const handleSubmit = (data: ForgotPasswordFields) => {
    forgotPasswordMutation.mutate({ email: data.email });
  };

  return (
    <>
      <Logo className="mb-10 h-[61px]" />

      {forgotPasswordMutation.isSuccess ? (
        <>
          <Text className="text-center">{t('forgot_password.success_message')}</Text>
          <Text
            size="1"
            className="!mt-4 block w-full cursor-pointer text-center hover:underline"
            role="button"
            onClick={onBackToLogin}
          >
            {t('forgot_password.back_to_login')}
          </Text>
        </>
      ) : (
        <div className="space-y-4">
          <Text as="div" weight="medium" size="5" className="text-center">
            {t('forgot_password.title')}
          </Text>

          <Text as="div" size="2" className="text-center">
            {t('forgot_password.description')}
          </Text>

          <Form {...form}>
            <form className="space-y-4" onSubmit={form.handleSubmit(handleSubmit)}>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full">
                      <FormControl>
                        <TextField {...field} type="email" placeholder={t('common.email')} />
                      </FormControl>

                      <FormMessage />
                      {forgotPasswordMutation.error && (
                        <FormMessage>
                          {forgotPasswordMutation.error.json?.errors?.[0]?.errorMsg ||
                            forgotPasswordMutation.error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  );
                }}
              />

              <Button
                className="w-full"
                isLoading={forgotPasswordMutation.isPending}
                variant={email ? 'solid' : 'soft'}
                type="submit"
              >
                {t('common.submit')}
              </Button>
            </form>
          </Form>

          <Text
            size="1"
            className="!mt-4 block w-full cursor-pointer text-center hover:underline"
            role="button"
            onClick={onBackToLogin}
          >
            {t('forgot_password.back_to_login')}
          </Text>
        </div>
      )}
    </>
  );
};
