import { useState } from 'react';

import { useTranslate } from 'services/i18n/useTranslate';
import { usePipelines } from 'services/api/pipelines';
import { useDeleteEnrichment } from 'services/api/custom-enrichments';

import { Label } from 'components/common/Label';
import { Button } from 'components/common/Button';
import { TextField } from 'components/common/TextField';
import { Translate } from 'components/common/Translate';
import { InlineDialog } from 'components/common/InlineDialog';
import { Callout } from 'components/common/Callout';

type DeleteCustomEnrichmentInlineDialogProps = {
  enrichmentId: string;
  enrichmentName: string | undefined;
  onCancel: () => void;
  onSuccess: () => void;
};

const EnrichmentDependentResources = () => {
  const t = useTranslate();
  const pipelinesQuery = usePipelines();

  if (pipelinesQuery.isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  if (!pipelinesQuery.data?.data.length) {
    return null;
  }

  return (
    <ul className="ml-2.5 list-inside list-disc">
      {pipelinesQuery.data.data.map((pipeline) => (
        <li key={pipeline.id}>
          {t('settings.tabs.enrichments.subtabs.manage.sidebar.pipeline_item', {
            pipelineName: pipeline.name,
            pipelineStatus: pipeline.status,
          })}
        </li>
      ))}
    </ul>
  );
};

export const DeleteCustomEnrichmentInlineDialog = ({
  enrichmentId,
  enrichmentName,
  onCancel,
  onSuccess,
}: DeleteCustomEnrichmentInlineDialogProps) => {
  const t = useTranslate();
  const deleteEnrichmentMutation = useDeleteEnrichment(enrichmentId);
  const [enrichmentNameConfirm, setEnrichmentNameConfirm] = useState('');

  const handleDeleteEnrichment = async () => {
    await deleteEnrichmentMutation.mutateAsync(undefined);

    onSuccess();
  };

  const isDisabled = !enrichmentName || enrichmentNameConfirm !== enrichmentName;

  return (
    <InlineDialog
      title={t('settings.tabs.enrichments.subtabs.manage.sidebar.delete_enrichment_set')}
      className="mb-2 mt-1 rounded-2 border border-neutral-3 bg-neutral-2"
      footer={
        <>
          <Button size="1" color="gray" variant="soft" onClick={onCancel} disabled={deleteEnrichmentMutation.isPending}>
            {t('common.cancel')}
          </Button>
          <Button
            size="1"
            color="red"
            disabled={isDisabled || deleteEnrichmentMutation.isPending}
            isLoading={deleteEnrichmentMutation.isPending}
            onClick={handleDeleteEnrichment}
          >
            {t('common.permanently_delete')}
          </Button>
        </>
      }
    >
      <div className="mb-1 leading-relaxed text-error-11">
        <Translate
          tkey="settings.tabs.enrichments.subtabs.manage.sidebar.delete_enrichment_subtitle"
          components={{
            newLine: <br />,
          }}
        />
      </div>

      <EnrichmentDependentResources />

      <Label htmlFor="enrichmentNameInput" className="mb-1 mt-5 inline-block">
        {t('settings.tabs.enrichments.subtabs.manage.sidebar.enrichment_set_name_input.label')}
      </Label>
      <TextField
        id="enrichmentNameInput"
        placeholder={t('settings.tabs.enrichments.subtabs.manage.sidebar.enrichment_set_name_input.placeholder')}
        value={enrichmentNameConfirm}
        onChange={(e) => setEnrichmentNameConfirm(e.target.value)}
        disabled={deleteEnrichmentMutation.isPending}
      />

      {deleteEnrichmentMutation.isError && (
        <Callout
          className="mt-3"
          variant="soft"
          status="error"
          size="1"
          onClose={() => deleteEnrichmentMutation.reset()}
        >
          {t('settings.tabs.enrichments.subtabs.manage.sidebar.delete_error')}
        </Callout>
      )}
    </InlineDialog>
  );
};
