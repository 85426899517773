import { useTranslate } from 'services/i18n/useTranslate';

import { Text } from 'components/common/Text';
import { Select } from 'components/common/Select';
import { IconButton } from 'components/common/IconButton';
import { TriangleLeftIcon, TriangleRightIcon, UpdateIcon } from 'components/common/Icons';
import { Tooltip } from 'components/common/Tooltip';

type PaginationControlPanelProps = {
  pageSize: number;
  onPageSizeChange: (value: string) => void;
  canPrevious: boolean;
  canNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  isLoading: boolean;
  isFetching: boolean;
  totalCount: number;
  pageIndex: number;
  onDataReload: () => void;
};

const SELECT_OPTIONS = [100, 50, 10];

export const PaginationControlPanel = ({
  pageSize,
  onPageSizeChange,
  canPrevious,
  canNext,
  onPrevious,
  onNext,
  isLoading,
  isFetching,
  totalCount,
  pageIndex,
  onDataReload,
}: PaginationControlPanelProps) => {
  const t = useTranslate();

  return (
    <div className="flex items-center gap-4 border-t border-neutral-8 bg-neutral-3 px-4 py-2">
      <div className="flex items-center gap-4">
        <Text size="2">{t('common.pagination_control_panel.items_per_page')}</Text>
        <Select.Root size="1" value={pageSize.toString()} onValueChange={onPageSizeChange}>
          <Select.Trigger variant="soft" color="gray" />
          <Select.Content>
            {SELECT_OPTIONS.map((value) => (
              <Select.Item key={value} value={value.toString()}>
                {value}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>

      {!isLoading && (
        <>
          <Text size="2">
            {t('common.pagination_control_panel.pagination_results', {
              from: pageIndex * pageSize + 1,
              to: Math.min((pageIndex + 1) * pageSize, totalCount),
              total: totalCount,
            })}
          </Text>

          <div className="flex items-center gap-2">
            <IconButton size="1" color="gray" onClick={onPrevious} disabled={!canPrevious || isFetching}>
              <TriangleLeftIcon />
            </IconButton>

            <IconButton size="1" color="gray" onClick={onNext} disabled={!canNext || isFetching}>
              <TriangleRightIcon />
            </IconButton>

            {pageIndex === 0 && (
              <>
                <div className="mr-2 h-full border-r-2 border-neutral-9">&nbsp;</div>

                <Tooltip content={t('common.date_picker.refresh_current_query')}>
                  <IconButton size="1" variant="ghost" onClick={onDataReload}>
                    <UpdateIcon color="orange" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
