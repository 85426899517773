import { Text } from 'components/common/Text';
import { CheckIcon } from 'components/common/Icons';

import { cn } from 'utils/styles';
import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

const MIN_PASSWORD_LENGTH = 10;

type Validation = { tkey: FlattenedTranslationKeys; isValid: boolean; values?: object };

type SetupPasswordProps = {
  validations: Validation[];
};

// eslint-disable-next-line react-refresh/only-export-components
export const validateConditions = (password: string): Validation[] => [
  {
    tkey: 'setup_password.password_requirements.length',
    isValid: password.length >= MIN_PASSWORD_LENGTH,
    values: { min: MIN_PASSWORD_LENGTH },
  },
  { tkey: 'setup_password.password_requirements.lowercase', isValid: /[a-z]/.test(password) },
  { tkey: 'setup_password.password_requirements.uppercase', isValid: /[A-Z]/.test(password) },
  { tkey: 'setup_password.password_requirements.numbers', isValid: /[0-9]/.test(password) },
];

export function PasswordRequirementsList({ validations }: SetupPasswordProps) {
  const t = useTranslate();

  return (
    <div className="flex flex-col gap-3 rounded-2 border border-neutral-5 px-4 py-2">
      <Text size="2">{t('setup_password.validation_header')}:</Text>

      <ul role="list" className="space-y-2">
        {validations.map((validation) => (
          <li key={validation.tkey} className="flex items-center gap-2">
            <div
              className={cn(
                'relative flex size-4 items-center rounded-full border text-neutral-5 transition-colors duration-100',
                validation.isValid ? 'border-green-9 bg-green-9' : 'border-neutral-6 bg-transparent',
              )}
            >
              <CheckIcon
                aria-hidden
                className={cn(
                  'size-4 stroke-white-a12 transition-opacity duration-100 dark:stroke-black-a12',
                  validation.isValid ? 'opacity-100' : 'opacity-0',
                )}
              />
            </div>

            <Text size="2" className="!text-neutral-10">
              {t(validation.tkey, validation.values)}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
}
