import { type ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { type FlattenedTranslationKeys } from 'types/translations';

type TransProps = ComponentProps<typeof Trans>;

type Props = Omit<TransProps, 'i18nKey' | 'components'> & {
  tkey: FlattenedTranslationKeys;
  components: { readonly [tagName: string]: React.ReactElement };
};

export const Translate = (props: Props) => {
  /**
    there's no mechanism to enforce "any string except X, Y, Z" in TypeScript
    but we can at least check it in development. Not perfect, but better than nothing
    this exists because react-i18next doesn't allow us to use self-closing tag names i.e. img, link as component keys
   */
  if (import.meta.env.DEV) {
    const SELF_CLOSING_TAGS = [
      'area',
      'base',
      'br',
      'col',
      'command',
      'embed',
      'hr',
      'img',
      'input',
      'keygen',
      'link',
      'meta',
      'param',
      'source',
      'track',
      'wbr',
    ];

    const componentKeys = Object.keys(props.components);
    if (componentKeys.some((key) => SELF_CLOSING_TAGS.includes(key))) {
      throw new Error(`Forbidden component names: ${SELF_CLOSING_TAGS.join(', ')}`);
    }
  }

  // @ts-expect-error the i18next types are **very** hard to work with
  return <Trans {...props} i18nKey={props.tkey} />;
};
