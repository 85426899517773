import { useTranslate } from 'services/i18n/useTranslate';

import { Label } from 'components/common/Label';
import { Text } from 'components/common/Text';

export type Period = 'AM' | 'PM';
export type Time = {
  hour: string;
  min: string;
  period: Period;
};

const PeriodToggle = ({ period, onToggle }: { period: string; onToggle: (newPeriod: Period) => void }) => {
  const t = useTranslate();

  return (
    <div className="relative ml-2 flex h-5 w-16 items-center">
      <div
        className={`absolute left-0 top-0 h-full w-1/2 rounded-2 bg-gray-a8 transition-transform duration-500 ${
          period === 'AM' ? 'translate-x-0' : 'translate-x-full'
        }`}
      />
      <button
        onClick={() => onToggle('AM')}
        className={`relative z-10 flex h-full w-1/2 items-center justify-center transition-opacity duration-500 focus-visible:rounded-2 focus-visible:outline focus-visible:outline-accent-8 ${
          period === 'AM' ? 'opacity-100' : 'opacity-50'
        }`}
        aria-pressed={period === 'AM'}
        aria-label={t('common.date_picker.select_period', { period: 'AM' })}
      >
        <Text className="text-[9px] uppercase">{t('common.date_picker.am')}</Text>
      </button>
      <button
        onClick={() => onToggle('PM')}
        className={`relative z-10 flex h-full w-1/2 items-center justify-center transition-opacity duration-500 focus-visible:rounded-2 focus-visible:outline focus-visible:outline-accent-8 ${
          period === 'PM' ? 'opacity-100' : 'opacity-50'
        }`}
        aria-pressed={period === 'PM'}
        aria-label={t('common.date_picker.select_period', { period: 'PM' })}
      >
        <Text className="text-[9px] uppercase">{t('common.date_picker.pm')}</Text>
      </button>
    </div>
  );
};

export const TimePicker = ({
  label,
  hour,
  min,
  period,
  onTimeChange,
}: {
  label: string;
  hour: string;
  min: string;
  period: Period;
  fromPeriod?: string;
  onTimeChange: (hour: string, min: string, period: Period) => void;
}) => {
  const t = useTranslate();

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (numericValue >= 0 && numericValue <= 12) {
      onTimeChange(value, min, period);
    } else {
      e.target.value = hour;
    }
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (numericValue >= 0 && numericValue <= 59) {
      onTimeChange(hour, value, period);
    } else {
      e.target.value = min;
    }
  };

  const handlePeriodChange = (newPeriod: Period) => {
    onTimeChange(hour, min, newPeriod);
  };

  const handleMinBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = value.padStart(2, '0');
    e.target.value = formattedValue;
    if (Number(value) >= 0 && Number(value) <= 59) {
      onTimeChange(hour, formattedValue, period);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <Label htmlFor={`${label}-h`}>{label}</Label>
      <div className="flex items-center">
        <div
          className="flex items-center rounded-2 border border-gray-6"
          role="group"
          aria-labelledby={`${label}-time`}
        >
          <input
            type="number"
            id={`${label}-h`}
            name={label}
            className="bg-white -offset-0 mr-px h-5 w-6 pr-0.5 text-right text-sm tabular-nums focus-visible:rounded-l-2 focus-visible:outline focus-visible:outline-offset-0 focus-visible:outline-accent-8 dark:bg-neutral-2"
            value={hour}
            onChange={handleHourChange}
            min={1}
            max={12}
            aria-label={t('common.date_picker.hour')}
          />
          <span className="bg-white text-center text-sm dark:bg-neutral-2" aria-hidden="true">
            :
          </span>
          <input
            type="number"
            id={`${label}-m`}
            name={label}
            className="bg-white ml-px h-5 w-6 pl-0.5 text-left text-sm tabular-nums focus-visible:rounded-r-2 focus-visible:outline focus-visible:outline-offset-0 focus-visible:outline-accent-8 dark:bg-neutral-2"
            value={min}
            onChange={handleMinChange}
            onBlur={handleMinBlur}
            min={0}
            max={59}
            aria-label={t('common.date_picker.hour')}
          />
        </div>
        <PeriodToggle period={period} onToggle={handlePeriodChange} />
      </div>
    </div>
  );
};
