import { useToasterStore } from 'utils/toast';

import { Toast, ToastProvider, ToastViewport } from './index';

export function Toaster() {
  const { toasts } = useToasterStore();

  return (
    <ToastProvider>
      {toasts.map(function (props) {
        return <Toast key={props.id} {...props} />;
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
