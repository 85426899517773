import { createContext, memo, useContext, useEffect, useId, useRef, useState, type ComponentProps } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from 'utils/styles';
import { Text } from 'components/common/Text';

type VerticalTabSize = '1' | '2';

const VerticalTabContext = createContext<{
  size: VerticalTabSize;
  tabId: string;
  paddingTop?: number;
}>({
  size: '1' as VerticalTabSize,
  tabId: 'tabId',
  paddingTop: 0,
});

export const VerticalTabs = ({
  size = '1',
  paddingTop = 0,
  ...props
}: ComponentProps<typeof RadixTabs.Root> & {
  size?: VerticalTabSize;
  paddingTop?: number;
}) => {
  const tabId = useId();

  return (
    <VerticalTabContext.Provider
      value={{
        size,
        paddingTop,
        tabId,
      }}
    >
      <RadixTabs.Root className="flex flex-1" {...props} />
    </VerticalTabContext.Provider>
  );
};

export const VerticalTabsList = ({ className, children, ...props }: ComponentProps<typeof RadixTabs.List>) => {
  const { paddingTop } = useContext(VerticalTabContext);
  return (
    <RadixTabs.List className={cn('flex flex-col', className)} {...props}>
      {!!paddingTop && <div className="border-x-[1px] border-neutral-5" style={{ height: paddingTop }} />}
      {children}
      <div className="h-full w-full flex-1 border-x-[1px] border-neutral-5" />
    </RadixTabs.List>
  );
};

export const VerticalTabContent = ({ className, ...props }: ComponentProps<typeof RadixTabs.Content>) => {
  return (
    <RadixTabs.Content
      className={cn('w-full px-4 py-2 data-[state=active]:flex data-[state=active]:flex-col', className)}
      {...props}
    />
  );
};

export const VerticalTabTrigger = memo(
  ({ children, className, ...props }: ComponentProps<typeof RadixTabs.Trigger>) => {
    const { size, tabId } = useContext(VerticalTabContext);
    const [isActive, setIsActive] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      const isTabActive = ref.current?.dataset?.state === 'active';

      setIsActive(isTabActive);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-state') {
            const isTabActive = ref.current?.dataset?.state === 'active';

            setIsActive(isTabActive);
          }
        });
      });

      observer.observe(ref.current as Node, {
        attributes: true,
        childList: false,
      });

      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <RadixTabs.Trigger
        ref={ref}
        className={cn(
          'group relative inline-flex cursor-pointer items-center border-0 border-solid border-neutral-6 bg-neutral-1 px-1 py-1.5 outline-none hover:bg-neutral-3',
          'not:last:border-b-[1px] border-[1px] border-b-0 border-t-[1px] border-neutral-5 first:border-t-0 last-of-type:border-b-[1px]',
          {
            'h-9': size === '1',
            'h-10': size === '2',
            'border-r-0 bg-neutral-3': isActive,
          },
        )}
        {...props}
      >
        {/* Focus ring */}
        <div className="absolute bottom-0 left-0 top-0 w-full overflow-clip rounded-1 border-2 border-solid border-accent-a8 opacity-0 group-focus-visible:opacity-100">
          <div className="absolute bottom-0 left-0 top-0 w-full border-2 border-solid border-accent-3" />
        </div>

        {/* Content */}
        <div
          className={cn('flex items-center rounded-1 px-2', {
            'py-0.5': size === '1',
            'py-1.5': size === '2',
          })}
        >
          <Text
            weight="medium"
            className={cn(
              'text-neutral-a11 group-disabled:text-neutral-8 group-data-[state=active]:text-neutral-12',
              {
                'text-xs': size === '1',
                'text-sm': size === '2',
              },
              className,
            )}
          >
            {children}
          </Text>
        </div>

        {/* Top/bottom border */}
        {/* <div className={cn('absolute bottom-0 left-0 h-px w-full bg-neutral-6', {})} /> */}

        {/* Active border */}
        <AnimatePresence>
          {isActive && (
            <motion.div
              layoutId={tabId}
              className={cn('absolute -left-px bottom-0 z-10 h-full w-0.5 bg-orange-10', {})}
              transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
            />
          )}
        </AnimatePresence>
      </RadixTabs.Trigger>
    );
  },
);
