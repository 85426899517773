import { clearAuthToken, getAuthToken, saveAuthToken } from 'utils/storage';

import { type Slice } from './_utils';

export type AuthSlice = {
  auth: {
    authToken: string | null;
    setAuthToken: (token: string) => void;
    clearAuthToken: () => void;
  };
};

export const authSlice: Slice<AuthSlice> = {
  createSlice: (set) => ({
    auth: {
      authToken: getAuthToken(),
      setAuthToken: (token) => {
        saveAuthToken(token);
        set((prev) => ({
          auth: {
            ...prev.auth,
            authToken: token,
          },
        }));
      },
      clearAuthToken: () => {
        clearAuthToken();
        set((prev) => ({
          auth: {
            ...prev.auth,
            authToken: null,
          },
        }));
      },
    },
  }),
};
