import type { components } from 'types/schemas/api-schema';

export const LOADING_CUSTOM_ENRICHMENT_SET_METADATA: components['schemas']['customEnrichmentSetMetadata'][] = [
  ...new Array<number>(10),
].map((_, i) => ({
  id: i.toString(),
  name: 'Loading...',
  description: 'Loading...',
  tenantId: '_'.repeat(10),
  updatedAt: new Date().toISOString(),
  updatedBy: {
    id: '_'.repeat(10),
    type: 'Loading...',
  },
}));

type LoadingPlatformEnrichmentProps = components['schemas']['enrichmentProfile'] & {
  assignedPipelines: string;
};
export const LOADING_PLATFORM_ENRICHMENTS: LoadingPlatformEnrichmentProps[] = [
  {
    type: 'geo',
    profile: 'Loading...',
    datasets: [{ schema: 'maxmind' }],
    assignedPipelines: '_'.repeat(10),
  },
  {
    type: 'intel',
    profile: 'Loading...',
    datasets: [{ schema: 'stix2_1' }],
    assignedPipelines: '_'.repeat(10),
  },
];
