import { cn } from 'utils/styles';

import { Text } from 'components/common/Text';
import { CHART_HEIGHT_CLASS } from './consts';

type HistogramPlaceholderProps = {
  message: string;
};

export const HistogramPlaceholder = ({ message }: HistogramPlaceholderProps) => (
  <div className={cn(CHART_HEIGHT_CLASS, `mb-[18px] mr-2 flex items-center justify-center bg-gray-6`)}>
    <Text>{message}</Text>
  </div>
);
