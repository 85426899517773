import type { components } from 'types/schemas/api-schema';

import { Badge } from 'components/common/Badge';
import { useTranslate } from 'services/i18n/useTranslate';

export const PipelineStatusBadge = ({ status }: { status: components['schemas']['pipeline']['status'] }) => {
  const t = useTranslate();

  if (status === 'active') {
    return (
      <Badge size="1" variant="soft" color="green">
        {t('settings.common.active')}
      </Badge>
    );
  }

  if (status === 'inactive') {
    return (
      <Badge size="1" variant="soft" color="red">
        {t('settings.common.inactive')}
      </Badge>
    );
  }

  return (
    <Badge size="1" variant="soft" color="amber">
      {t('settings.common.initial')}
    </Badge>
  );
};
