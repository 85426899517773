import { useCallback, useEffect, useState } from 'react';
import { getRouteApi } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { BASE_RANGE_OPTIONS, type DateTimeFilter } from 'consts/dateTime';
import { DashboardLayout } from 'components/common/DashboardLayout';
import { PresetDateTimePicker } from 'components/common/PresetDateTimePicker';
import { isValidTimeRange } from 'utils/dateTimeValidation';
import { resetRelativeQueriesPredicate } from 'utils/detections';

import { Facets } from './Facets';
import { Histogram } from './Histogram';
import { AnomaliesTable } from './Table';
import { FilterBar } from './FilterBar';
import { AnomalySidebar } from './AnomalySidebar';
import { AnomaliesProvider } from './AnomaliesContext';

const Route = getRouteApi('/_app/anomalies');
const ANOMALIES_LIVE_UPDATE_REFRESH_INTERVAL = 5 * 60 * 1000;

const AnomaliesSceneContent = () => {
  const routeSearch = Route.useSearch();
  const navigate = Route.useNavigate();
  const queryClient = useQueryClient();
  const [isLiveUpdateOn, setIsLiveUpdateOn] = useState(false);

  const timeRange = isValidTimeRange(routeSearch.timeRange) ? routeSearch.timeRange : undefined;

  const openAnomalyId = 'anomalyId' in routeSearch ? (routeSearch.anomalyId as string) : undefined;

  const toggleLiveUpdate = useCallback(() => {
    setIsLiveUpdateOn((prev) => !prev);
  }, []);

  const changeTimerange = useCallback(
    (data: DateTimeFilter) => {
      setIsLiveUpdateOn(false);

      void navigate({
        search: (previousParams) => {
          const withoutTimerange = _.omit(previousParams, ['startTime', 'endTime', 'timeRange']);

          return {
            ...withoutTimerange,
            ...data,
          };
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    void queryClient.removeQueries({
      predicate: (query) => {
        return resetRelativeQueriesPredicate('anomalies', timeRange, query.queryKey);
      },
    });
  }, [queryClient, timeRange]);

  const handleRefreshData = () => {
    void queryClient.resetQueries({ queryKey: ['anomalies'], exact: false });
  };

  return (
    <>
      <AnomalySidebar anomalyId={openAnomalyId} />
      <DashboardLayout
        leftSection={
          <>
            <PresetDateTimePicker
              rangeOptions={BASE_RANGE_OPTIONS}
              startTime={routeSearch.startTime}
              endTime={routeSearch.endTime}
              timeRange={timeRange}
              onChange={changeTimerange}
              isLiveUpdateOn={isLiveUpdateOn}
              onToggleLiveUpdate={toggleLiveUpdate}
              onRefreshData={handleRefreshData}
              refreshInterval={ANOMALIES_LIVE_UPDATE_REFRESH_INTERVAL}
            />
            <Facets />
          </>
        }
        rightSection={
          <>
            <FilterBar />
            <Histogram />
            <AnomaliesTable onRefreshData={handleRefreshData} />
          </>
        }
      />
    </>
  );
};

export const AnomaliesScene = () => {
  return (
    <AnomaliesProvider>
      <AnomaliesSceneContent />
    </AnomaliesProvider>
  );
};
