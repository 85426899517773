import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
  type SortingState,
} from '@tanstack/react-table';

import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { usePlatformEnrichments } from 'services/api/platform-enrichments';
import { LOADING_PLATFORM_ENRICHMENTS } from 'mocks/enrichments';

import { Authorize } from 'components/common/Authorize';
import { Button } from 'components/common/Button';
import { GearIcon, MixerHorizontalIcon } from 'components/common/Icons';
import { Text } from 'components/common/Text';
import { Skeleton } from 'components/common/Skeleton';
import { SortableColumnHeaderCell, Table } from 'components/common/Table';
import { Translate } from 'components/common/Translate';
import { Callout } from 'components/common/Callout';
import { Link } from 'components/common/Link';

import { EditPlatformEnrichmentSidebar } from './components/EditPlatformEnrichmentSidebar';
import { SettingsPageContainer } from '../components/SettingsPageContainer';
import { usePipelinesWithPlatformEnrichments } from './usePipelinesWithPlatformEnrichments';

type FullPipeline = components['schemas']['fullPipeline'];
type PipelinesAssignmentProps = {
  enrichmentType: string;
  enrichments: { geo: FullPipeline[]; intel: FullPipeline[] };
};
type PlatformEnrichment = components['schemas']['enrichmentProfile'];
type ExtendedPlatformEnrichment = PlatformEnrichment & {
  file?: string;
};

const enrichmentColumnHelper = createColumnHelper<PlatformEnrichment>();
const DEFAULT_DATA: PlatformEnrichment[] = [];

const EnrichmentUpdatedCallout = ({
  enrichment,
  onClose,
}: {
  enrichment: ExtendedPlatformEnrichment;
  onClose: () => void;
}) => {
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="platform-enrichment-updated-callout"
    >
      <Translate
        tkey="settings.tabs.enrichments.subtabs.platform.enrichment_updated_callout"
        values={{ file: enrichment.file, enrichmentType: enrichment.type }}
        components={{
          'assign-pipelines-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentType: enrichment.type,
                tab: 'assign-pipelines',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

const PipelinesAssignment = ({ enrichmentType, enrichments }: PipelinesAssignmentProps) => {
  const { geo, intel } = enrichments;

  const filteredPipelines = enrichmentType === 'geo' ? geo : enrichmentType === 'intel' ? intel : [];

  if (!filteredPipelines || !filteredPipelines.length) {
    return null;
  }

  return (
    <span>
      {filteredPipelines
        .filter((pipeline) =>
          pipeline.enrichmentSet?.enrichments?.some(
            (enrichment) => enrichment.type === enrichmentType && enrichment.enabled,
          ),
        )
        .map((pipeline) => pipeline?.name)
        .join(', ')}
    </span>
  );
};

export function Platform() {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/settings/enrichments/platform' });
  const [updatedPlatformEnrichment, setUpdatedPlatformEnrichment] = useState<ExtendedPlatformEnrichment | null>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const platformEnrichmentsQuery = usePlatformEnrichments();
  const platformPipelinesQuery = usePipelinesWithPlatformEnrichments();

  const isLoading = platformEnrichmentsQuery.isLoading || platformPipelinesQuery.isLoading;
  const isError = platformEnrichmentsQuery.isError || platformPipelinesQuery.isError;
  const errorMessage = platformEnrichmentsQuery.error?.message || platformPipelinesQuery.errors.map((e) => e.message);

  const combinedData = useMemo(() => {
    if (!platformEnrichmentsQuery.data) return [];

    return Object.entries(platformEnrichmentsQuery.data).flatMap(([type, enrichmentData]) => {
      if (!enrichmentData) return [];
      return {
        type,
        profile: enrichmentData.data.profile,
        datasets: enrichmentData.data.datasets,
      };
    });
  }, [platformEnrichmentsQuery.data]);

  const handleEnrichmentEditClick = useCallback(
    (enrichmentType: string, tab: 'edit-enrichment' | 'assign-pipelines') => {
      void navigate({
        search: {
          enrichmentSidebarIntent: 'edit',
          enrichmentType,
          tab,
        },
      });
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      enrichmentColumnHelper.accessor('type', {
        id: 'type',
        header: () => <Text>{t('common.name')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.display({
        id: 'globalTenant',
        header: () => <Text>{t('common.tenant')}</Text>,
        cell: () => (
          <Skeleton isLoading={isLoading}>
            <Text>{t('settings.tabs.enrichments.subtabs.platform.table.cell.global')}</Text>
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('type', {
        id: 'assignedPipelines',
        header: () => <Text>{t('settings.tabs.enrichments.subtabs.manage.table.headers.assigned_pipelines')}</Text>,
        enableSorting: false,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            {!isLoading && platformEnrichmentsQuery.data ? (
              <PipelinesAssignment
                enrichmentType={info.getValue()}
                enrichments={{
                  geo: platformPipelinesQuery.data.geo,
                  intel: platformPipelinesQuery.data.intel,
                }}
              />
            ) : (
              <Text>{t('common.errors.no_data')}</Text>
            )}
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('type', {
        id: 'actions',
        header: () => <Text className="w-full text-right">{t('common.actions_label')}</Text>,
        cell: (info) => {
          return (
            <div className="flex justify-end gap-2">
              <Authorize
                policy={{
                  scope: 'platform-enrichment:write',
                }}
              >
                <Skeleton isLoading={isLoading}>
                  <Button
                    size="1"
                    variant="outline"
                    color="gray"
                    onClick={() => handleEnrichmentEditClick(info.getValue(), 'assign-pipelines')}
                  >
                    <MixerHorizontalIcon /> {t('common.assignments')}
                  </Button>
                </Skeleton>
              </Authorize>
              <Authorize
                policy={{
                  scope: 'platform-enrichment:write',
                }}
              >
                <Skeleton isLoading={isLoading}>
                  <Button
                    size="1"
                    variant="outline"
                    color="gray"
                    onClick={() => handleEnrichmentEditClick(info.getValue(), 'edit-enrichment')}
                  >
                    <GearIcon /> {t('common.settings')}
                  </Button>
                </Skeleton>
              </Authorize>
            </div>
          );
        },
        enableSorting: false,
      }),
    ];
  }, [handleEnrichmentEditClick, isLoading, platformEnrichmentsQuery.data, platformPipelinesQuery.data, t]);

  const table = useReactTable({
    data: isLoading ? LOADING_PLATFORM_ENRICHMENTS : combinedData || DEFAULT_DATA,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return (
      <SettingsPageContainer>
        {t('common.errors.error')}: {errorMessage}
      </SettingsPageContainer>
    );
  }

  return (
    <SettingsPageContainer>
      <EditPlatformEnrichmentSidebar onSuccess={setUpdatedPlatformEnrichment} />

      {updatedPlatformEnrichment && (
        <EnrichmentUpdatedCallout
          enrichment={updatedPlatformEnrichment}
          onClose={() => setUpdatedPlatformEnrichment(null)}
        />
      )}

      <Table.Root variant="surface" data-test-id="settings-enrichments-table">
        <Table.Header className="bg-neutral-a2">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <SortableColumnHeaderCell key={header.id} header={header} />
              ))}
            </Table.Row>
          ))}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map((row) => {
            return (
              <Table.Row key={row.id} align="center">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </SettingsPageContainer>
  );
}
