import { type ErrorComponentProps } from '@tanstack/react-router';
import * as z from 'zod';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Logo } from 'components/common/Logo';
import { Link } from 'components/common/Link';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Translate } from 'components/common/Translate';
import { PasswordField } from 'components/common/PasswordField';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/common/Form';
import { PasswordRequirementsList, validateConditions } from 'components/common/PasswordRequirementsList';

import { cn } from 'utils/styles';
import { decodeAuthToken } from 'utils/token';
import { useSetPassword } from 'services/api/auth';
import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

const schema = z.object({
  password: z.string(),
});

type FormFields = z.infer<typeof schema>;

class RouterError extends Error {
  routerCode: string;

  constructor(routerCode: string, message: string) {
    super(message);
    this.routerCode = routerCode;
  }
}

export function SetupPasswordErrorComponent(props: ErrorComponentProps) {
  const t = useTranslate();

  const error = props.error as RouterError;

  if (error.routerCode === 'VALIDATE_SEARCH') {
    return (
      <>
        <Logo className="mb-6 h-[61px]" />

        <div>{t('setup_password.token_error')}</div>
      </>
    );
  }

  return (
    <>
      <Logo className="mb-6 h-[61px]" />

      <div>{error.message}</div>
    </>
  );
}

type SetupPasswordProps = {
  token: string;
  title: FlattenedTranslationKeys;
  onSuccess: (email: string) => void;
};

export function SetupPasswordScene({ token, title, onSuccess }: SetupPasswordProps) {
  const t = useTranslate();
  const setPasswordMutation = useSetPassword(token);

  const { name: email } = decodeAuthToken(token);

  const form = useForm<FormFields>({
    defaultValues: {
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  });

  const password = form.watch('password');

  const validations = validateConditions(password);

  const allValid = validations.every((validation) => validation.isValid);

  const handleSubmit: SubmitHandler<FormFields> = ({ password }) => {
    setPasswordMutation.mutate(
      { password },
      {
        onSuccess: () => onSuccess(email),
        onError: (error) => {
          const errorObject = error.json?.errors?.[0];
          const message = errorObject?.errorMsg || errorObject?.message;

          if (message) {
            form.setError('password', { message });
          }
        },
      },
    );
  };

  return (
    <>
      <Logo className="mb-6 h-[61px]" />

      <Form {...form}>
        <form className="flex h-full w-full flex-col gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <Heading weight="medium" size="5" align="center">
              {t(title)}
            </Heading>
            <Text size="2" align="center">
              <Translate
                tkey="setup_password.subtitle"
                values={{ email }}
                components={{
                  'not-you-link': <Link color="sky" externalTo="/" />,
                }}
              />
            </Text>
          </div>

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => {
              return (
                <FormItem className="w-full">
                  <FormControl>
                    <PasswordField {...field} placeholder={t('common.password')} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <PasswordRequirementsList validations={validations} />

          <Button
            variant={form.formState.isSubmitting || allValid ? 'solid' : 'soft'}
            size="2"
            color="orange"
            type="submit"
            className={cn({
              'pointer-events-none': !allValid || form.formState.isSubmitting,
              'bg-accent-10': allValid && form.formState.isSubmitting,
            })}
            isLoading={setPasswordMutation.isPending}
          >
            {t('setup_password.button')}
          </Button>
        </form>
      </Form>
    </>
  );
}
