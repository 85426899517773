import type { CSSProperties } from 'react';
import { Table } from '@radix-ui/themes';
import { flexRender, type Header, type SortDirection } from '@tanstack/react-table';

import { CaretUpIcon, CaretDownIcon, CaretSortIcon } from 'components/common/Icons';

import { cn } from 'utils/styles';

type SortableColumnHeaderCellProps<T> = {
  header: Header<T, unknown>;
  style?: CSSProperties;
  className?: string;
};

type SortingTitleProps = {
  canSort: boolean;
  getNextSortingOrder: () => false | SortDirection;
};

function sortingTitle({ canSort, getNextSortingOrder }: SortingTitleProps) {
  if (!canSort) return undefined;

  const order = getNextSortingOrder();
  switch (order) {
    case 'asc':
      return 'Sort ascending';
    case 'desc':
      return 'Sort descending';
    default:
      return 'Clear sort';
  }
}

type SortingIconProps = {
  isSorted: false | SortDirection;
  canSort: boolean;
};

const SortingIcon = ({ isSorted, canSort }: SortingIconProps) => {
  if (!canSort) return null;
  if (!isSorted) return <CaretSortIcon className="size-5" />;

  return isSorted === 'asc' ? <CaretUpIcon className="size-5" /> : <CaretDownIcon className="size-5" />;
};

function SortableColumnHeaderCell<T>({ header, style, className }: SortableColumnHeaderCellProps<T>) {
  const canSort = header.column.getCanSort();

  return (
    <Table.ColumnHeaderCell style={style} className={className}>
      <div
        className={cn('flex items-center justify-between gap-2', canSort && 'cursor-pointer select-none')}
        onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
        title={sortingTitle({
          canSort,
          getNextSortingOrder: header.column.getNextSortingOrder,
        })}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {canSort && <SortingIcon isSorted={header.column.getIsSorted()} canSort={canSort} />}
      </div>
    </Table.ColumnHeaderCell>
  );
}

export { Table, SortableColumnHeaderCell };
