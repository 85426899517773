import { useRef, useState } from 'react';
import { z } from 'zod';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import * as errorMessages from 'consts/errorMessages';
import { downloadFile } from 'utils/downloadFile';
import { useEnrichmentDataset } from 'services/api/custom-enrichments';

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/common/Form';
import { Callout } from 'components/common/Callout';
import { Button } from 'components/common/Button';
import { TextField } from 'components/common/TextField';
import { ClipboardIcon, InfoCircledIcon } from 'components/common/Icons';
import { Select } from 'components/common/Select';
import { Collapsible } from 'components/common/Collapsible';
import { RadioGroup, Radio } from 'components/common/Radio';

import { Section } from '../../components/Section';
import { CustomEnrichmentFormattingInstructions } from './CustomEnrichmentFormattingInstructions';

type ViewMode = 'create' | 'edit';
type EnrichmentDataSet = components['schemas']['customEnrichmentSetMetadata'];
type ExtendedEnrichmentDataSet = EnrichmentDataSet & {
  file?: string;
};
export type EnrichmentContentProps = {
  onSuccess: (data: ExtendedEnrichmentDataSet) => void;
  onPartialSuccess: (data: ExtendedEnrichmentDataSet, errorMessages: string[]) => void;
  onDeletion: (data: ExtendedEnrichmentDataSet) => void;
};

const createEnrichmentSchema = z.object({
  tenantId: z.string().min(1, errorMessages.required),
  name: z.string().min(3, errorMessages.minLength),
  description: z.string(),
});

export type CreateEnrichmentFormFields = z.infer<typeof createEnrichmentSchema>;

const editEnrichmentSchema = z.object({
  tenantId: z.string().min(1, errorMessages.required),
  name: z.string().min(3, errorMessages.minLength),
  description: z.string(),
  file: z.union([z.string(), z.instanceof(File)]).optional(),
});

export type EditEnrichmentFormFields = z.infer<typeof editEnrichmentSchema>;

type EnrichmentFormFields = CreateEnrichmentFormFields | EditEnrichmentFormFields;

type EnrichmentFormProps = {
  isLoading?: boolean;
  error?: components['schemas']['errorEnvelope'];
  onSubmit: (data: EnrichmentFormFields) => void;
  viewMode: ViewMode;
  onCloseCallout?: () => void;
  tenants?: components['schemas']['tenant'][];
  updateMode: 'replace' | 'append';
  setUpdateMode: (mode: 'replace' | 'append') => void;
} & (
  | {
      viewMode: 'create';
      enrichment?: undefined;
    }
  | {
      viewMode: 'edit';
      enrichment: ExtendedEnrichmentDataSet;
    }
);

export function CustomEnrichmentForm({
  enrichment,
  isLoading,
  error,
  onSubmit,
  viewMode,
  onCloseCallout,
  tenants,
  updateMode,
  setUpdateMode,
}: EnrichmentFormProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [showInstructions, setShowInstructions] = useState(false);
  const enrichmentDatasetQuery = useEnrichmentDataset(enrichment?.id || '', 'csv');
  const formSchema = viewMode === 'create' ? createEnrichmentSchema : editEnrichmentSchema;

  const form = useForm<EnrichmentFormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tenantId: enrichment?.tenantId || '',
      name: enrichment?.name || '',
      description: enrichment?.description || ' ',
      file: enrichment?.file || '',
    },
  });

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  const handleDownloadFile = () => {
    const fileContent = enrichmentDatasetQuery?.data;

    if (fileContent) {
      downloadFile(fileContent, `${enrichment?.name}.csv`, 'text/csv');
    }
  };

  return (
    <Form {...form}>
      <form className="flex h-full w-full flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <Section required title={t('common.tenant')}>
          <FormField
            control={form.control}
            name="tenantId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <Select.Root
                      value={field.value}
                      defaultValue={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={viewMode === 'edit'}
                    >
                      <Select.Trigger
                        color="orange"
                        placeholder={t('settings.tabs.enrichments.subtabs.manage.sidebar.select_tenant')}
                      />
                      <Select.Content>
                        {tenants?.map((tenant) => (
                          <Select.Item key={tenant.id} value={tenant.id}>
                            {tenant.name}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </Section>

        <Section icon={ClipboardIcon} title={t('settings.tabs.enrichments.subtabs.manage.sidebar.enrichment_set')}>
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="w-96">
                  <FormLabel required>{t('common.name')}</FormLabel>
                  <FormControl>
                    <TextField {...field} disabled={viewMode === 'edit'} />
                  </FormControl>

                  {fieldState.error && <FormMessage translationVars={{ min: 3 }} />}
                </FormItem>
              );
            }}
          />
        </Section>

        {viewMode === 'edit' && (
          <>
            <Section title={t('settings.tabs.enrichments.subtabs.manage.sidebar.upload_csv_file')}>
              <div>
                <FormField
                  control={form.control}
                  name="file"
                  render={({ field }) => {
                    const getFileName = () => {
                      if (field.value instanceof File) {
                        return field.value.name;
                      } else if (typeof field.value === 'string' && field.value) {
                        return field.value;
                      } else if (enrichmentDatasetQuery?.data) {
                        return `${enrichment?.name}.csv`;
                      } else {
                        return '';
                      }
                    };

                    return (
                      <FormItem>
                        <FormLabel required>{t('common.file_name')}</FormLabel>
                        <div className="flex items-center space-x-2">
                          <FormControl>
                            <>
                              <TextField
                                {...field}
                                value={getFileName()}
                                readOnly
                                placeholder={t('common.no_file_chosen')}
                              />
                              <TextField
                                ref={fileInputRef}
                                className="!hidden"
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                  if (e.target.files && e.target.files.length > 0) {
                                    field.onChange(e.target.files[0]);
                                  }
                                }}
                              />
                            </>
                          </FormControl>
                          <Button type="button" onClick={() => fileInputRef.current?.click()}>
                            {t('common.browse')}
                          </Button>
                        </div>

                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />

                <div className="mt-4 w-20 pl-2">
                  <div className="w-24">
                    <RadioGroup
                      value={updateMode}
                      onValueChange={(value) => setUpdateMode(value as 'replace' | 'append')}
                      defaultValue="replace"
                    >
                      <Radio value="replace" label={t('common.replace')} />
                      <Radio value="append" label={t('common.append')} />
                    </RadioGroup>
                  </div>
                </div>

                <div>
                  <Button
                    size="2"
                    variant="ghost"
                    color="orange"
                    className="!-mb-3 !mt-1.5 !font-extralight !underline"
                    onClick={handleDownloadFile}
                    disabled={!enrichmentDatasetQuery?.data}
                  >
                    {t('settings.tabs.enrichments.subtabs.manage.sidebar.download_enrichment_set')}
                  </Button>
                </div>
              </div>

              {error && (
                <Callout status="error" onClose={onCloseCallout} className="mt-4">
                  {error?.errors?.[0]?.errorMsg}
                </Callout>
              )}
            </Section>

            <Collapsible
              title={t('settings.tabs.enrichments.subtabs.manage.sidebar.formatting_instructions')}
              defaultOpen={showInstructions}
              onOpenChange={() => setShowInstructions(!showInstructions)}
              Icon={InfoCircledIcon}
            >
              <CustomEnrichmentFormattingInstructions />
            </Collapsible>
          </>
        )}

        <div className="flex-1" />

        <div className="mb-2 flex justify-end gap-2">
          <Button variant="soft" type="button" color="gray" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button isLoading={isLoading} type="submit">
            {viewMode !== 'create' ? t('common.save') : t('common.create')}
          </Button>
        </div>
      </form>
    </Form>
  );
}
