import type { ReactNode } from 'react';

import { cn } from 'utils/styles';

import { Badge } from 'components/common/Badge';
import { Cross1Icon, MixerHorizontalIcon, LayersIcon, DestIPIcon } from 'components/common/Icons';
import { Code } from 'components/common/Code';

type FilterBadgeProps = {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
  className?: string;
};

export const ConfidenceScoreFilterBadge = ({ children, isActive, onClick, className }: FilterBadgeProps) => {
  return (
    <Badge onClick={onClick} className={cn('hover:cursor-pointer hover:bg-gray-6', className)} color="gray">
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const PipelineFilterBadge = ({ children, isActive, onClick, className }: FilterBadgeProps) => {
  return (
    <Badge onClick={onClick} className={cn('hover:cursor-pointer hover:bg-brown-6', className)} color="brown">
      <MixerHorizontalIcon />
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const TenantFilterBadge = ({ children, isActive, onClick, className }: FilterBadgeProps) => {
  return (
    <Badge onClick={onClick} className={cn('hover:cursor-pointer hover:bg-gray-6', className)} color="gray">
      <LayersIcon />
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const EntitiesFilterBadge = ({ children, isActive, onClick, className }: FilterBadgeProps) => {
  return (
    <Badge onClick={onClick} className={cn('hover:cursor-pointer hover:bg-sky-6', className)} color="sky">
      <DestIPIcon />
      {children}
      {isActive && <Cross1Icon className="h-3 w-3" />}
    </Badge>
  );
};

export const DimensionsFilterBadge = ({
  children,
  isActive,
  isInFilterBar,
  className,
  onClick,
}: FilterBadgeProps & { isInFilterBar?: boolean }) => {
  return (
    <Code
      size="1"
      weight="regular"
      onClick={onClick}
      className={cn('inline-flex items-center gap-1 hover:cursor-pointer hover:bg-accent-6', className)}
    >
      {children}
      {isActive && <Cross1Icon className={cn(isInFilterBar ? 'mr-1.5 size-3' : 'mr-1 size-2.5')} />}
    </Code>
  );
};
