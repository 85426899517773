import { useEffect, useMemo, useState } from 'react';
import { getRouteApi } from '@tanstack/react-router';

import type { components } from 'types/schemas/api-schema';
import type { FlattenedTranslationKeys } from 'types/translations';

import { useTranslate } from 'services/i18n/useTranslate';
import { usePipelines } from 'services/api/pipelines';

import { Drawer } from 'components/common/Drawer';
import { Button } from 'components/common/Button';
import { Authorize } from 'components/common/Authorize';

import { SettingsPageContainer } from '../../components/SettingsPageContainer';
import { DeletePipelineInlineDialog } from './DeletePipelineInlineDialog';
import { type PipelineContentProps, PipelineCreateContent, PipelineEditContent } from './PipelineForm';

type ViewMode = 'create' | 'edit';

export type PipelineSidebarProps = {
  viewMode: ViewMode;
  onSuccess: PipelineContentProps['onSuccess'];
  onDeleteSuccess: () => void;
};

const Content = ({
  isLoading,
  isError,
  viewMode,
  pipeline,
  onSuccess,
}: {
  isLoading: boolean;
  isError: boolean;
  viewMode: ViewMode;
  pipeline?: components['schemas']['pipeline'];
  onSuccess: PipelineContentProps['onSuccess'];
}) => {
  if (isLoading) {
    return <SettingsPageContainer>Loading...</SettingsPageContainer>;
  }
  if (isError || (!pipeline && viewMode !== 'create')) {
    return <SettingsPageContainer>Error or pipeline not found</SettingsPageContainer>;
  }
  if (viewMode === 'create') {
    return <PipelineCreateContent onSuccess={onSuccess} viewMode={viewMode} />;
  }
  if (pipeline) {
    return <PipelineEditContent onSuccess={onSuccess} viewMode={viewMode} pipeline={pipeline} />;
  }
};

const Route = getRouteApi('/_app/settings/platform/pipelines');

const TITLE_DESCRIPTION: Record<ViewMode, { title: FlattenedTranslationKeys; description: FlattenedTranslationKeys }> =
  {
    create: {
      title: 'settings.tabs.platform.subtabs.pipelines.sidebar.create_title',
      description: 'settings.tabs.platform.subtabs.pipelines.sidebar.create_description',
    },
    edit: {
      title: 'settings.tabs.platform.subtabs.pipelines.sidebar.edit_title',
      description: 'settings.tabs.platform.subtabs.pipelines.sidebar.edit_description',
    },
  };

export const PipelineSidebar = ({ viewMode, onSuccess, onDeleteSuccess }: PipelineSidebarProps) => {
  const t = useTranslate();
  const pipelinesQuery = usePipelines();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isOpen = 'pipelineSidebarIntent' in searchParams && searchParams.pipelineSidebarIntent === viewMode;
  const pipelineId = ('pipelineSidebarIntent' in searchParams &&
    searchParams.pipelineSidebarIntent !== 'create' &&
    searchParams.pipelineId) as string;
  const pipeline = useMemo(
    () => pipelinesQuery.data?.data.find((p) => p.id === pipelineId),
    [pipelineId, pipelinesQuery.data],
  );
  useEffect(() => {
    if (isOpen) {
      setIsDeleteDialogOpen(false);
    }
  }, [isOpen]);

  const { title, description } = TITLE_DESCRIPTION[viewMode] || {};

  const onDeletePipelineSuccess = () => {
    onDeleteSuccess();
    void navigate({
      search: {},
    });
  };

  return (
    <Drawer
      title={title && t(title)}
      description={description && t(description)}
      mode="modal"
      side="right"
      size="xLarge"
      isOpen={isOpen}
      onOpenChange={(value) => {
        void navigate({
          search: value ? { pipelineSidebarIntent: viewMode } : {},
        });
      }}
      actions={
        viewMode !== 'create' && (
          <Authorize
            policy={{
              scope: 'pipeline:delete',
            }}
          >
            <Button size="1" color="red" onClick={() => setIsDeleteDialogOpen(true)}>
              {t('settings.tabs.platform.subtabs.pipelines.sidebar.delete_pipeline')}
            </Button>
          </Authorize>
        )
      }
    >
      {isDeleteDialogOpen && (
        <DeletePipelineInlineDialog
          pipelineId={pipelineId}
          pipelineName={pipeline?.name}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onSuccess={onDeletePipelineSuccess}
        />
      )}

      <Content
        isLoading={pipelinesQuery.isLoading}
        isError={pipelinesQuery.isError}
        viewMode={viewMode}
        pipeline={pipeline}
        onSuccess={onSuccess}
      />
    </Drawer>
  );
};
