import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';

type TitleProps = {
  title: string;
  description?: string;
};

const Title = ({ title, description }: TitleProps) => {
  return (
    <div className="flex flex-col items-start">
      <Heading size="5" weight="regular" className="leading-7 tracking-tighter">
        {title}
      </Heading>
      <Text className="text-sm leading-6 text-neutral-11">{description || null}</Text>
    </div>
  );
};

type PageHeaderProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export function PageHeader({ children, title, description }: PageHeaderProps) {
  return (
    <div className="flex w-full items-center justify-between border-b border-neutral-5 bg-neutral-3 px-4 pb-6 pt-4 dark:bg-neutral-2">
      <Title title={title} description={description} />
      <>{children}</>
    </div>
  );
}
