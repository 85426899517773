import { type ComponentProps, type ReactNode, Children, cloneElement, isValidElement } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { Text } from 'components/common/Text';

import { cn } from 'utils/styles';

type SharedProps = {
  size?: '1' | '2';
  variant?: 'classic' | 'surface' | 'soft';
  color?: 'accent' | 'neutral';
  highContrast?: boolean;
};

type RadioGroupProps = ComponentProps<typeof RadioGroupPrimitive.Root> &
  SharedProps & {
    children: ReactNode;
  };

type RadioProps = SharedProps & {
  value: string;
  label: string;
  disabled?: boolean;
};

export const RadioGroup = ({
  size = '1',
  variant = 'surface',
  highContrast = false,
  children,
  className,
  ...props
}: RadioGroupProps) => {
  return (
    <RadioGroupPrimitive.Root {...props} className={cn('flex flex-col', size === '1' ? 'gap-2' : 'gap-3', className)}>
      {Children.map(children, (child) => {
        if (isValidElement<RadioProps>(child)) {
          return cloneElement(child, { size, variant, highContrast });
        }
        return child;
      })}
    </RadioGroupPrimitive.Root>
  );
};

export const Radio = ({ value, label, disabled, size, variant, highContrast = false }: RadioProps) => {
  return (
    <Text as="label" className="flex items-center gap-2">
      <RadioGroupPrimitive.Item
        value={value}
        disabled={disabled}
        className={cn('flex items-center justify-center rounded-full', {
          'h-4 w-4': size === '1',
          'h-6 w-6': size === '2',
          'data-[state=unchecked]:border data-[state=unchecked]:border-neutral-a7 data-[state=checked]:bg-accent-9 data-[state=unchecked]:bg-surface':
            variant === 'classic' || variant === 'surface',
          'shadow shadow-neutral-1': variant === 'classic',
          'border-none bg-accent-a5': variant === 'soft',
          'data-[state=checked]:bg-accent-12': highContrast && (variant === 'classic' || variant === 'surface'),
          'cursor-not-allowed opacity-50': disabled,
        })}
      >
        <RadioGroupPrimitive.Indicator
          className={cn('rounded-full bg-contrast-accent', {
            'h-1.5 w-1.5': size === '1',
            'h-2.5 w-2.5': size === '2',
            'bg-accent-11': variant === 'soft',
            'bg-accent-12': variant === 'soft' && highContrast,
          })}
        />
      </RadioGroupPrimitive.Item>
      <span className={size === '1' ? 'text-sm' : 'text-base'}>{label}</span>
    </Text>
  );
};
