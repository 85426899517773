import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type paths } from 'types/schemas/api-schema';

import { api, type APIError } from '.';

export const useUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => {
      return api
        .url('/auth/users')
        .get()
        .json((res: paths['/auth/users']['get']['responses']['200']['content']['application/json']) => {
          return res;
        });
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/auth/users']['post']['responses']['201']['content']['application/json'],
    APIError<paths['/auth/users']['post']['responses']['400']['content']['application/json']>,
    paths['/auth/users']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (user) => {
      const response = await api
        .url('/auth/users')
        .post(user)
        .json((res: paths['/auth/users']['post']['responses']['201']['content']['application/json']) => {
          return res;
        });

      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['tenants'],
      });

      return response;
    },
  });
};

export const useEditUser = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/auth/users/{userId}']['put']['responses']['200']['content']['application/json'],
    APIError<paths['/auth/users/{userId}']['put']['responses']['400']['content']['application/json']>,
    paths['/auth/users/{userId}']['put']['requestBody']['content']['application/json']
  >({
    mutationFn: async (data) => {
      const response = await api
        .url(`/auth/users/${userId}`)
        .put(data)
        .json((res: paths['/auth/users/{userId}']['put']['responses']['200']['content']['application/json']) => {
          return res;
        });

      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });

      return response;
    },
  });
};

export const useDeleteUser = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    paths['/auth/users/{userId}']['delete']['responses']['204']['content'],
    APIError<paths['/auth/users/{userId}']['delete']['responses']['401']['content']['application/json']>
  >({
    mutationFn: async () => {
      await api.url(`/auth/users/${userId}`).delete().res();

      return undefined;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
};
