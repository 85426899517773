import type { LinkProps } from '@tanstack/react-router';
import { getDashboardSearch, saveDashboardSearch } from 'utils/storage';

import { type Slice } from './_utils';

const PATHNAMES_TO_REMEMBER_SEARCH = ['/threats', '/anomalies'] as const satisfies LinkProps['to'][];

function shouldPageSaveSearchString(pathname: string): boolean {
  return PATHNAMES_TO_REMEMBER_SEARCH.includes(pathname);
}

export type LastPageSearchSlice = {
  lastPageSearchSlice: {
    searches: Record<string, string>;
    maybeSaveLastSearch: (pathname: string, search: string) => void;
  };
};

export const lastPageSearchSlice: Slice<LastPageSearchSlice> = {
  createSlice: (set) => ({
    lastPageSearchSlice: {
      searches: PATHNAMES_TO_REMEMBER_SEARCH.reduce(
        (acc, page) => {
          acc[page] = getDashboardSearch(page) || '';

          return acc;
        },
        {} as Record<string, string>,
      ),

      maybeSaveLastSearch: (pathname, searchString) => {
        if (!shouldPageSaveSearchString(pathname)) {
          return;
        }

        saveDashboardSearch(pathname, searchString);

        return set((prev) => {
          return {
            ...prev,
            lastPageSearchSlice: {
              ...prev.lastPageSearchSlice,
              searches: {
                ...prev.lastPageSearchSlice.searches,
                [pathname]: searchString,
              },
            },
          };
        });
      },
    },
  }),
};
