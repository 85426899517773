import { useCallback } from 'react';
import { useQueries, type UseQueryResult } from '@tanstack/react-query';

import { api, type APIError } from 'services/api';
import { usePipelines } from 'services/api/pipelines';
import type { GetFullPipelineResponse, GetFullPipelineError } from 'services/api/pipelines';

type CombinedError = Error | APIError<GetFullPipelineError>;
type PipelineQueryResult = UseQueryResult<GetFullPipelineResponse, CombinedError>;

export const usePipelinesWithPlatformEnrichments = () => {
  const pipelinesQuery = usePipelines();

  const queryFn = useCallback(
    (pipelineId: string) => () => api.url(`/ingest/pipelines/full/${pipelineId}`).get().json<GetFullPipelineResponse>(),
    [],
  );

  const combineResults = useCallback(
    (results: PipelineQueryResult[]) => {
      const isLoading = pipelinesQuery.isLoading || results.some((result) => result.isLoading);
      const isError = pipelinesQuery.isError || results.some((result) => result.isError);
      const errors = results
        .filter((result): result is { error: CombinedError } & typeof result => result.isError && result.error !== null)
        .map((result) => result.error);

      const fullPipelines = results
        .filter(
          (result): result is { data: GetFullPipelineResponse } & typeof result =>
            result.isSuccess && result.data !== undefined,
        )
        .map((result) => result.data.data);

      const geoData = fullPipelines.filter((pipeline) =>
        pipeline?.enrichmentSet?.enrichments?.some((e) => e.type === 'geo'),
      );

      const intelData = fullPipelines.filter((pipeline) =>
        pipeline?.enrichmentSet?.enrichments?.some((e) => e.type === 'intel'),
      );

      return {
        data: { geo: geoData.filter(Boolean), intel: intelData.filter(Boolean) },
        isLoading,
        isError,
        errors,
      };
    },

    [pipelinesQuery.isLoading, pipelinesQuery.isError],
  );

  const pipelineIds = pipelinesQuery.data?.data?.map((pipeline) => pipeline.id) || [];

  return useQueries({
    queries: pipelineIds.map((pipelineId) => ({
      queryKey: ['pipelines', 'full', pipelineId],
      queryFn: queryFn(pipelineId),
    })),
    combine: combineResults,
  });
};
