import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { createDateTimeValidationSchema } from 'utils/dateTimeValidation';

import { AnomaliesScene } from 'components/scenes/Anomalies';

const filterSchema = z.object({
  confidenceScore: z.array(z.enum(['low', 'medium', 'high'])).optional(),
  tenantId: z.array(z.string()).optional(),
  pipelineId: z.array(z.string()).optional(),
  entity: z.array(z.string()).optional(),
  dimension: z.array(z.string()).optional(),
});

const viewSchema = z.object({
  anomalyId: z.string().optional(),
});

const anomaliesSchema = z.intersection(filterSchema, createDateTimeValidationSchema(), viewSchema);

export const Route = createFileRoute('/_app/anomalies')({
  component: AnomaliesScene,
  validateSearch: zodSearchValidator(anomaliesSchema),
  meta: () => [
    {
      name: 'pageTitle',
      content: 'anomalies.page_title',
    },
    {
      name: 'pageDescription',
      content: 'anomalies.page_description',
    },
    {
      name: 'metaTitle',
      content: 'anomalies.page_title',
    },
  ],
});
