import { createContext, memo, useContext, useEffect, useId, useRef, useState, type ComponentProps } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from 'utils/styles';
import { Text } from 'components/common/Text';

type SubtabSize = '1' | '2';

const SubtabsContext = createContext<{
  size: SubtabSize;
  tabId: string;
}>({
  size: '1' as SubtabSize,
  tabId: 'tabId',
});

export const Subtabs = ({
  size,
  ...props
}: ComponentProps<typeof RadixTabs.Root> & {
  size?: SubtabSize;
}) => {
  const tabId = useId();

  return (
    <SubtabsContext.Provider
      value={{
        size: size || '1',
        tabId,
      }}
    >
      <RadixTabs.Root {...props} />
    </SubtabsContext.Provider>
  );
};

export const SubtabsList = RadixTabs.List;

export const SubtabContent = RadixTabs.Content;

export const SubtabTrigger = memo(({ children, className, ...props }: ComponentProps<typeof RadixTabs.Trigger>) => {
  const { size, tabId } = useContext(SubtabsContext);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const isTabActive = ref.current?.dataset?.state === 'active';

    setIsActive(isTabActive);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-state') {
          const isTabActive = ref.current?.dataset?.state === 'active';

          setIsActive(isTabActive);
        }
      });
    });

    observer.observe(ref.current as Node, {
      attributes: true,
      childList: false,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <RadixTabs.Trigger
      ref={ref}
      className={cn(
        'group relative inline-flex cursor-pointer items-center rounded-1 border-0 border-solid border-neutral-6 bg-transparent p-2 outline-none hover:bg-neutral-3',
        {
          'h-8': size === '1',
          'h-10': size === '2',
        },
      )}
      {...props}
    >
      {/* Focus ring */}
      <div className="absolute bottom-0 left-0 top-0 w-full overflow-clip rounded-1 border-2 border-solid border-accent-a8 opacity-0 group-focus-visible:opacity-100">
        <div className="absolute bottom-0 left-0 top-0 w-full border-2 border-solid border-accent-3" />
      </div>

      {/* Content */}
      <div className="px-2">
        <div
          className={cn('relative', {
            'py-1': size === '2',
          })}
        >
          <Text
            weight="medium"
            as="div"
            className={cn(
              'text-accent-12 group-disabled:text-neutral-8 group-data-[state=active]:text-accent-a11',
              {
                'text-sm !leading-6': size === '1',
                'text-base !leading-[26px]': size === '2',
              },
              className,
            )}
          >
            {children}
          </Text>

          <AnimatePresence>
            {isActive && (
              <motion.div
                layoutId={tabId}
                className={cn(
                  'absolute -bottom-px left-0 z-10 h-0.5 w-full bg-accent-a5 group-focus-visible:-bottom-1',
                )}
                transition={{ type: 'spring', bounce: 0.1, duration: 0.3 }}
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Active border */}
    </RadixTabs.Trigger>
  );
});
