import { Outlet, useMatchRoute, useNavigate } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { SubtabTrigger, Subtabs, SubtabsList } from 'components/common/Subtabs';

import { ManageTabActionButton } from './Manage';

const SUBTAB_ROUTES = ['/settings/enrichments/manage', '/settings/enrichments/platform'] as const;

export function Enrichments() {
  const t = useTranslate();
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const matchedRoute = SUBTAB_ROUTES.find((route) => matchRoute({ to: route })) || SUBTAB_ROUTES[0];

  function handleSubtabChange(to: string) {
    void navigate({ to });
  }

  return (
    <Subtabs value={matchedRoute} onValueChange={handleSubtabChange} className="my-1">
      <div className="mr-3 flex items-center justify-between" data-test-id="settings-subtabs-container">
        <SubtabsList data-test-id="settings-subtabs">
          <SubtabTrigger value={SUBTAB_ROUTES[0]}>{t('settings.tabs.enrichments.subtabs.manage.label')}</SubtabTrigger>
          <SubtabTrigger value={SUBTAB_ROUTES[1]}>
            {t('settings.tabs.enrichments.subtabs.platform.label')}
          </SubtabTrigger>
        </SubtabsList>

        {matchedRoute === '/settings/enrichments/manage' && <ManageTabActionButton />}
      </div>

      <Outlet />
    </Subtabs>
  );
}
