import { getRouteApi } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';
import { useEnrichments } from 'services/api/custom-enrichments';

import { Drawer } from 'components/common/Drawer';

import { type EnrichmentContentProps } from './CustomEnrichmentForm';
import { CustomEnrichmentCreateContent } from './tabs/CustomEnrichmentGeneralDataTab';

const Route = getRouteApi('/_app/settings/enrichments/manage');

type ViewMode = 'create' | 'edit';
type EnrichmentSidebarProps = {
  onSuccess: EnrichmentContentProps['onSuccess'];
};

export function CreateCustomEnrichmentSidebar({ onSuccess }: EnrichmentSidebarProps) {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const enrichmentQuery = useEnrichments();
  const viewMode: ViewMode = 'create';
  const isOpen = 'enrichmentSidebarIntent' in searchParams && searchParams.enrichmentSidebarIntent === viewMode;

  const onOpenChange = (value: boolean) => {
    void navigate({
      search: value ? { enrichmentSidebarIntent: viewMode } : {},
    });
  };

  if (enrichmentQuery.isError) {
    return <div>{t('common.errors.error')}</div>;
  }

  return (
    <Drawer
      title={t('settings.tabs.enrichments.subtabs.manage.sidebar.create_title')}
      description={t('settings.tabs.enrichments.subtabs.manage.sidebar.create_description')}
      mode="modal"
      side="right"
      size="large"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <CustomEnrichmentCreateContent onSuccess={onSuccess} viewMode={viewMode} />
    </Drawer>
  );
}
