import { forwardRef, type ComponentProps } from 'react';
import { Button as RadixButton } from '@radix-ui/themes';

import { cn } from 'utils/styles';
import { LoadingIcon } from 'components/common/Icons';

type ButtonProps = ComponentProps<typeof RadixButton> & {
  isLoading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, children, className, ...props }, ref) => {
    return (
      <RadixButton className={cn(className, 'relative')} {...props} ref={ref}>
        {isLoading && (
          <LoadingIcon
            className="absolute left-1/2 h-4 w-4 animate-spin"
            style={{
              translate: '-50%',
            }}
          />
        )}

        <div
          className={cn(
            'flex items-center justify-center gap-2',
            isLoading ? 'pointer-events-none opacity-0' : 'opacity-100 transition-opacity',
          )}
          aria-hidden={isLoading}
          aria-busy={isLoading}
        >
          {children}
        </div>
      </RadixButton>
    );
  },
);
