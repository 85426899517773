import { useTenants } from 'services/api/tenants';
import { usePipelines } from 'services/api/pipelines';
import { type components } from 'types/schemas/api-schema';

import { type ThreatsFilterProperty } from '../ThreatsContext';

const DEFAULT_TENANTS: components['schemas']['tenants'] = [];
const DEFAULT_PIPELINES: components['schemas']['pipeline'][] = [];

export const useGetDisplayValue = () => {
  const tenantsQuery = useTenants();
  const pipelinesQuery = usePipelines();

  const tenants = tenantsQuery.data?.data ?? DEFAULT_TENANTS;
  const pipelines = pipelinesQuery.data?.data ?? DEFAULT_PIPELINES;
  const isLoading = tenantsQuery.isLoading || pipelinesQuery.isLoading;

  const getDisplayValue = (paramKey: ThreatsFilterProperty, value: string): string => {
    switch (paramKey) {
      case 'pipelineId':
        const pipeline = pipelines.find((p) => p.id === value);
        return pipeline ? pipeline.name! : isLoading ? '_'.repeat(5) : value;
      case 'tenantId':
        const tenant = tenants.find((t) => t.id === value);
        return tenant ? tenant.name : isLoading ? '_'.repeat(5) : value;
      default:
        return value;
    }
  };

  return getDisplayValue;
};
