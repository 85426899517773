import { createFileRoute } from '@tanstack/react-router';

import { ThreatDetails } from 'components/scenes/ThreatDetails';

export const Route = createFileRoute('/_app/threats/$threatId')({
  component: Component,
  meta: () => [
    {
      name: 'pageTitle',
      content: 'threats.threat_details.page_title',
    },
    {
      name: 'metaTitle',
      content: 'threats.threat_details.page_title',
    },
    {
      name: 'pageDescription',
      content: 'threats.threat_details.page_description',
    },
  ],
});

function Component() {
  const { threatId } = Route.useParams();

  return <ThreatDetails threatId={threatId} />;
}
