const tokenKey = 'mmAuthToken';

export const getAuthToken = () => {
  return sessionStorage.getItem(tokenKey);
};

export const saveAuthToken = (token: string) => {
  sessionStorage.setItem(tokenKey, token);
};

export const clearAuthToken = () => {
  sessionStorage.removeItem(tokenKey);
};

export const saveDashboardSearch = (dashboard: string, queryString: string) => {
  sessionStorage.setItem(`lastSearchRegistry-${dashboard}`, queryString);
};

export const getDashboardSearch = (dashboard: string) => {
  return sessionStorage.getItem(`lastSearchRegistry-${dashboard}`) || null;
};
