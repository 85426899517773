import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
  type SortingState,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { downloadFile } from 'utils/downloadFile';
import type { components } from 'types/schemas/api-schema';
import { useTranslate } from 'services/i18n/useTranslate';
import { useTenant } from 'services/api/tenants';
import { useEnrichments, useAssociatedEnrichmentsAndPipelines } from 'services/api/custom-enrichments';
import { LOADING_CUSTOM_ENRICHMENT_SET_METADATA } from 'mocks/enrichments';
import { dateFormatMedium } from 'consts/dateFormats';

import { Link } from 'components/common/Link';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { Skeleton } from 'components/common/Skeleton';
import { Authorize } from 'components/common/Authorize';
import { Translate } from 'components/common/Translate';
import { ButtonLink } from 'components/common/ButtonLink';
import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { GearIcon, MixerHorizontalIcon, Pencil2Icon } from 'components/common/Icons';

import { CreateCustomEnrichmentSidebar } from './components/CreateCustomEnrichmentSidebar';
import { EditCustomEnrichmentSidebar } from './components/EditCustomEnrichmentSidebar';
import { SettingsPageContainer } from '../components/SettingsPageContainer';

type EnrichmentDataSet = components['schemas']['customEnrichmentSetMetadata'];
type ExtendedEnrichmentDataSet = EnrichmentDataSet & {
  file?: undefined | string;
};

const enrichmentColumnHelper = createColumnHelper<EnrichmentDataSet>();
const DEFAULT_DATA: EnrichmentDataSet[] = [];

const EnrichmentCreatedCallout = ({ enrichment, onClose }: { enrichment: EnrichmentDataSet; onClose: () => void }) => {
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="enrichment-created-callout"
    >
      <Translate
        tkey="settings.tabs.enrichments.subtabs.manage.enrichment_created_callout"
        values={{ enrichmentName: enrichment.name }}
        components={{
          'edit-enrichment-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'edit-enrichment',
              }}
            />
          ),
          'assign-pipelines-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'assign-pipelines',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

const EnrichmentUpdatedCallout = ({
  enrichment,
  onClose,
}: {
  enrichment: ExtendedEnrichmentDataSet;
  onClose: () => void;
}) => {
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="enrichment-updated-callout"
    >
      <Translate
        tkey="settings.tabs.enrichments.subtabs.manage.enrichment_updated_callout"
        values={{ file: enrichment.file, enrichmentName: enrichment.name }}
        components={{
          'assign-pipelines-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'assign-pipelines',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

const MAX_ERROR_MESSAGES = 10;

const EnrichmentPartiallyUpdatedCallout = ({
  enrichment,
  errorMessages,
  onClose,
}: {
  enrichment: ExtendedEnrichmentDataSet;
  errorMessages: string[];
  onClose: () => void;
}) => {
  const t = useTranslate();

  const isTooManyErrors = errorMessages.length > MAX_ERROR_MESSAGES;

  return (
    <Callout
      variant="soft"
      status="warning"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="enrichment-partially-updated-callout"
    >
      <span>
        {t('settings.tabs.enrichments.subtabs.manage.enrichment_partially_updated_callout_name', {
          file: enrichment.file,
          enrichmentName: enrichment.name,
        })}
      </span>

      <br />
      <br />

      <em>{t('settings.tabs.enrichments.subtabs.manage.enrichment_partially_updated_callout_info_message')}</em>

      <br />
      <br />

      <ul className="list-inside list-disc">
        {errorMessages.slice(0, MAX_ERROR_MESSAGES).map((errorMessage, index) => (
          <li key={index}>{errorMessage}</li>
        ))}
      </ul>

      {isTooManyErrors && (
        <>
          <br />
          <Translate
            tkey="settings.tabs.enrichments.subtabs.manage.enrichment_partially_updated_callout_too_many_errors"
            values={{ count: errorMessages.length - MAX_ERROR_MESSAGES }}
            components={{
              'download-all-errors': (
                <ButtonLink
                  onClick={() => {
                    downloadFile(errorMessages.join('\n'), `${enrichment.name}-data-errors.txt`, 'text/plain');
                  }}
                />
              ),
            }}
          />
        </>
      )}

      <br />
      <br />

      <Translate
        tkey="settings.tabs.enrichments.subtabs.manage.enrichment_partially_updated_callout_actions"
        values={{}}
        components={{
          'view-settings-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              onClick={onClose}
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'edit-enrichment',
              }}
            />
          ),
          'assign-pipelines-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              onClick={onClose}
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'assign-pipelines',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

const EnrichmentDeletedCallout = ({
  enrichment,
  onClose,
}: {
  enrichment: ExtendedEnrichmentDataSet;
  onClose: () => void;
}) => {
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="enrichment-deleted-callout"
    >
      <Translate
        tkey="settings.tabs.enrichments.subtabs.manage.enrichment_deleted_callout"
        values={{ enrichmentName: enrichment.name }}
        components={{
          'assign-pipelines-link': (
            <Link
              size="1"
              className="!text-[inherit] underline"
              search={{
                enrichmentSidebarIntent: 'edit',
                enrichmentId: enrichment.id,
                tab: 'assign-pipelines',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

export function ManageTabActionButton() {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/settings/enrichments/manage' });

  return (
    <Authorize policy={{ scope: 'custom-enrichment:write' }}>
      <Button
        size="1"
        variant="outline"
        onClick={() => {
          void navigate({
            search: {
              enrichmentSidebarIntent: 'create',
            },
          });
        }}
      >
        <Pencil2Icon /> {t('settings.tabs.enrichments.actions.create_enrichment')}
      </Button>
    </Authorize>
  );
}

const TenantAssignment = ({ tenantId }: { tenantId: string }) => {
  const t = useTranslate();
  const { data: tenant, isLoading, isError } = useTenant(tenantId);

  if (isLoading) {
    return <Text>{t('common.loading')}</Text>;
  }

  if (isError) {
    return <Text>{t('common.errors.error')}</Text>;
  }

  if (!tenant) {
    return null;
  }

  return <Text>{tenant.data.name}</Text>;
};

const PipelinesAssignment = ({ enrichmentId, tenantId }: { enrichmentId: string; tenantId: string }) => {
  const t = useTranslate();
  const { data, isLoading, isError } = useAssociatedEnrichmentsAndPipelines(tenantId);

  if (isLoading) {
    return <Text>{t('common.loading')}</Text>;
  }

  if (isError) {
    return <Text>{t('common.errors.error')}</Text>;
  }

  if (!data?.data || !data.data.length) {
    return null;
  }

  const enrichmentSet = data.data.find((set) => set.id === enrichmentId);

  if (!enrichmentSet) {
    return null;
  }

  return <span>{enrichmentSet.pipelines.map((pipeline) => pipeline.name).join(', ')}</span>;
};

export function Manage() {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/settings/enrichments/manage' });
  const enrichmentQuery = useEnrichments();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [createdEnrichment, setCreatedEnrichment] = useState<EnrichmentDataSet | null>(null);
  const [updatedEnrichment, setUpdatedEnrichment] = useState<ExtendedEnrichmentDataSet | null>(null);
  const [partiallyUpdatedEnrichment, setPartiallyUpdatedEnrichment] = useState<
    [ExtendedEnrichmentDataSet, string[]] | null
  >(null);
  const [deletedEnrichment, setDeletedEnrichment] = useState<ExtendedEnrichmentDataSet | null>(null);

  const isLoading = enrichmentQuery.isLoading;
  const isError = enrichmentQuery.isError;

  const handleEnrichmentEditClick = useCallback(
    (enrichmentId: string, tab: 'edit-enrichment' | 'assign-pipelines') => {
      void navigate({
        search: {
          enrichmentSidebarIntent: 'edit',
          enrichmentId,
          tab,
        },
      });
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      enrichmentColumnHelper.accessor('name', {
        header: () => <Text>{t('common.name')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <Text>{info.getValue()}</Text>
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('tenantId', {
        header: () => <Text>{t('common.tenant')}</Text>,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <div>{isLoading ? info.getValue() : <TenantAssignment tenantId={info.getValue()} />}</div>
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('id', {
        id: 'assignedPipelines',
        header: () => <Text>{t('settings.tabs.enrichments.subtabs.manage.table.headers.assigned_pipelines')}</Text>,
        enableSorting: false,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            <div>
              {isLoading ? (
                info.getValue()
              ) : (
                <PipelinesAssignment tenantId={info.row.original.tenantId} enrichmentId={info.getValue()} />
              )}
            </div>
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('updatedAt', {
        header: () => <Text>{t('common.last_updated')}</Text>,
        enableSorting: true,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>
            {info.getValue() && <Text>{format(info.getValue(), dateFormatMedium)}</Text>}
          </Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('updatedBy', {
        header: () => <Text>{t('common.updated_by')}</Text>,
        enableSorting: true,
        cell: (info) => (
          <Skeleton isLoading={isLoading}>{info.getValue() && <Text>{info.getValue().id}</Text>}</Skeleton>
        ),
      }),
      enrichmentColumnHelper.accessor('id', {
        header: () => <Text className="w-full text-right">{t('common.actions_label')}</Text>,
        cell: (info) => {
          return (
            <div className="flex justify-end gap-2">
              <Authorize
                policy={{
                  scope: 'custom-enrichment:write',
                  tenantId: info.row.original.tenantId,
                }}
              >
                <Skeleton isLoading={isLoading}>
                  <Button
                    size="1"
                    variant="outline"
                    color="gray"
                    onClick={() => handleEnrichmentEditClick(info.getValue(), 'assign-pipelines')}
                  >
                    <MixerHorizontalIcon /> {t('common.assignments')}
                  </Button>
                </Skeleton>
              </Authorize>
              <Authorize
                policy={{
                  scope: 'custom-enrichment:write',
                  tenantId: info.row.original.tenantId,
                }}
              >
                <Skeleton isLoading={isLoading}>
                  <Button
                    size="1"
                    variant="outline"
                    color="gray"
                    onClick={() => handleEnrichmentEditClick(info.getValue(), 'edit-enrichment')}
                  >
                    <GearIcon /> {t('common.settings')}
                  </Button>
                </Skeleton>
              </Authorize>
            </div>
          );
        },
        enableSorting: false,
      }),
    ];
  }, [handleEnrichmentEditClick, isLoading, t]);

  const table = useReactTable({
    data: isLoading ? LOADING_CUSTOM_ENRICHMENT_SET_METADATA : enrichmentQuery.data?.data || DEFAULT_DATA,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return <SettingsPageContainer>{t('common.errors.error')}...</SettingsPageContainer>;
  }

  return (
    <>
      <CreateCustomEnrichmentSidebar onSuccess={setCreatedEnrichment} />
      <EditCustomEnrichmentSidebar
        onSuccess={setUpdatedEnrichment}
        onPartialSuccess={(data, errorMessages) => {
          setPartiallyUpdatedEnrichment([data, errorMessages]);
        }}
        onDeletion={setDeletedEnrichment}
      />

      <SettingsPageContainer>
        {deletedEnrichment && (
          <EnrichmentDeletedCallout enrichment={deletedEnrichment} onClose={() => setDeletedEnrichment(null)} />
        )}

        {updatedEnrichment && (
          <EnrichmentUpdatedCallout enrichment={updatedEnrichment} onClose={() => setUpdatedEnrichment(null)} />
        )}

        {partiallyUpdatedEnrichment && (
          <EnrichmentPartiallyUpdatedCallout
            enrichment={partiallyUpdatedEnrichment[0]}
            errorMessages={partiallyUpdatedEnrichment[1]}
            onClose={() => setPartiallyUpdatedEnrichment(null)}
          />
        )}

        {createdEnrichment && (
          <EnrichmentCreatedCallout enrichment={createdEnrichment} onClose={() => setCreatedEnrichment(null)} />
        )}

        <Table.Root variant="surface" data-test-id="settings-enrichments-table">
          <Table.Header className="bg-neutral-a2">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SortableColumnHeaderCell key={header.id} header={header} />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Row key={row.id} align="center">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </SettingsPageContainer>
    </>
  );
}
