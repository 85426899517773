import logoDark from 'static/images/mixmode-logo-dark.svg';
import logoLight from 'static/images/mixmode-logo-light.svg';
import mixmodeTextDark from 'static/images/mixmode-logo-text-dark.svg';
import mixmodeTextLight from 'static/images/mixmode-logo-text-light.svg';

import { cn } from 'utils/styles';
import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';

type LogoProps = {
  className?: string;
  shouldDisplayText?: boolean;
  isAnimated?: boolean;
};

export function Logo({ className, shouldDisplayText = false, isAnimated = false }: LogoProps) {
  const t = useTranslate();
  const theme = useStore((state) => state.theme.theme);

  return (
    <div className="flex items-center">
      <img
        src={theme === 'dark' ? logoLight : logoDark}
        className={cn(isAnimated && 'mr-1', className)}
        alt={shouldDisplayText ? t('common.mixmode_logo') : undefined}
      />

      <img
        src={theme === 'dark' ? mixmodeTextLight : mixmodeTextDark}
        aria-hidden={!shouldDisplayText}
        alt={!shouldDisplayText ? t('common.mixmode_logo_text') : undefined}
        className={cn(isAnimated && 'transition-[opacity,visibility] duration-200', {
          'visible opacity-100': shouldDisplayText,
          'invisible opacity-0': !shouldDisplayText,
          hidden: !shouldDisplayText && !isAnimated,
        })}
      />
    </div>
  );
}
