import { useState, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useNavigate } from '@tanstack/react-router';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { dateFormatMedium } from 'consts/dateFormats';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useTenants } from 'services/api/tenants';

import type { components } from 'types/schemas/api-schema';

import { Text } from 'components/common/Text';
import { Link } from 'components/common/Link';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { Authorize } from 'components/common/Authorize';
import { Translate } from 'components/common/Translate';
import {
  AdminUserIcon,
  CheckCircledIcon,
  CrossCircledIcon,
  GearIcon,
  LayersIcon,
  TimerIcon,
} from 'components/common/Icons';
import { Badge } from 'components/common/Badge';
import { Table, SortableColumnHeaderCell } from 'components/common/Table';

import { CreateTenantSidebar } from './components/CreateTenantSidebar';
import { EditTenantSidebar } from './components/EditTenantSidebar';
import { SettingsPageContainer } from '../components/SettingsPageContainer';

const tenantColumnHelper = createColumnHelper<components['schemas']['tenant']>();

type PipelineBadgeProps = {
  status: components['schemas']['pipeline']['status'];
  name: components['schemas']['pipeline']['name'];
};

function PipelineBadge({ status, name }: PipelineBadgeProps) {
  if (status === 'initial') {
    return (
      <Badge key={status} size="1" variant="soft" color="amber">
        <TimerIcon aria-hidden /> {name}
      </Badge>
    );
  }

  if (status === 'inactive') {
    return (
      <Badge key={status} size="1" variant="soft" color="red">
        <CrossCircledIcon aria-hidden /> {name}
      </Badge>
    );
  }

  return (
    <Badge key={status} size="1" variant="soft" color="teal">
      <CheckCircledIcon aria-hidden /> {name}
    </Badge>
  );
}

const PermissionBadges = ({ tenantId }: { tenantId: string }) => {
  const { data: pipelines, isLoading, isError } = usePipelines(tenantId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (!pipelines) {
    return null;
  }

  return (
    <div className="flex gap-2">
      {pipelines.data.map((pipeline) => (
        <PipelineBadge key={pipeline.id} status={pipeline.status} name={pipeline.name} />
      ))}
    </div>
  );
};

const TenantCreatedCallout = ({
  tenant,
  onClose,
}: {
  tenant: components['schemas']['tenant'];
  onClose: () => void;
}) => {
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="tenant-created-callout"
    >
      <Translate
        tkey="settings.tabs.platform.subtabs.tenants.tenant_created_callout"
        values={{ tenantName: tenant.name }}
        components={{
          'settings-link': (
            <Link
              className="!text-[inherit]"
              search={{
                tenantSidebarIntent: 'edit',
                tenantId: tenant.id,
                tab: 'settings',
              }}
            />
          ),
          'users-link': (
            <Link
              className="!text-[inherit]"
              search={{
                tenantSidebarIntent: 'edit',
                tenantId: tenant.id,
                tab: 'users',
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

const TenantEditedCallout = ({ tenant, onClose }: { tenant: components['schemas']['tenant']; onClose: () => void }) => {
  const t = useTranslate();
  return (
    <Callout
      variant="soft"
      status="success"
      size="1"
      className="mb-2"
      onClose={onClose}
      data-test-id="tenant-updated-callout"
    >
      {t('settings.tabs.platform.subtabs.tenants.tenant_edited_callout', {
        tenantName: tenant.name,
      })}
    </Callout>
  );
};

export function TenantsTabActionButtons() {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/settings/platform/tenants' });

  return (
    <Authorize policy={{ scope: 'tenant:write' }}>
      <Button
        size="1"
        variant="outline"
        onClick={() => {
          void navigate({
            search: {
              tenantSidebarIntent: 'create',
            },
          });
        }}
      >
        <LayersIcon /> {t('settings.tabs.platform.subtabs.tenants.actions.create_tenant')}
      </Button>
    </Authorize>
  );
}

export function Tenants() {
  const t = useTranslate();
  const tenantsQuery = useTenants();
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate = useNavigate({ from: '/settings/platform/tenants' });
  const [createdTenant, setCreatedTenant] = useState<components['schemas']['tenant'] | null>(null);
  const [editedTenant, setEditedTenant] = useState<components['schemas']['tenant'] | null>(null);

  const handleTenantEditClick = useCallback(
    (tenantId: string, tab: 'settings' | 'users') => {
      void navigate({
        search: { tenantSidebarIntent: 'edit', tenantId, tab },
      });
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      tenantColumnHelper.accessor('name', {
        header: () => <Text>{t('common.tenant')}</Text>,
        cell: (info) => (
          <div className="flex flex-col">
            <Text>{info.row.original.name}</Text>
            <Text className="text-neutral-a11">{info.row.original.description}</Text>
          </div>
        ),
      }),
      tenantColumnHelper.accessor('id', {
        header: () => <Text>{t('common.pipelines')}</Text>,
        cell: (info) => <PermissionBadges tenantId={info.getValue()} />,
        enableSorting: false,
      }),
      tenantColumnHelper.accessor('updatedAt', {
        header: () => <Text>{t('common.last_updated')}</Text>,
        cell: (info) => <Text>{format(info.getValue(), dateFormatMedium)}</Text>,
      }),
      tenantColumnHelper.accessor('id', {
        id: 'actions',
        header: () => <Text className="w-full text-right">{t('common.actions_label')}</Text>,
        cell: (info) => (
          <div className="flex justify-end gap-2">
            <Authorize
              policy={{
                anyPolicy: [
                  { scope: 'role:write' },
                  {
                    tenantId: info.getValue(),
                    scope: 'role:write',
                  },
                ],
              }}
            >
              <Button
                size="1"
                variant="outline"
                color="gray"
                onClick={() => handleTenantEditClick(info.getValue(), 'users')}
              >
                <AdminUserIcon /> {t('settings.tabs.platform.subtabs.tenants.table.action_col.users')}
              </Button>
            </Authorize>
            <Authorize
              policy={{
                anyPolicy: [{ scope: 'tenant:write' }, { tenantId: info.getValue(), scope: 'tenant:write' }],
              }}
            >
              <Button
                size="1"
                variant="outline"
                color="gray"
                onClick={() => handleTenantEditClick(info.getValue(), 'settings')}
              >
                <GearIcon /> {t('common.settings')}
              </Button>
            </Authorize>
          </div>
        ),
        enableSorting: false,
      }),
    ];
  }, [handleTenantEditClick, t]);

  const data = useMemo(() => {
    return tenantsQuery.data?.data ?? [];
  }, [tenantsQuery.data?.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (tenantsQuery.isLoading) {
    return <SettingsPageContainer>Loading...</SettingsPageContainer>;
  }

  if (tenantsQuery.isError) {
    return <SettingsPageContainer>Error...</SettingsPageContainer>;
  }

  return (
    <>
      <CreateTenantSidebar onSuccess={setCreatedTenant} />
      <EditTenantSidebar onSuccess={setEditedTenant} />

      <SettingsPageContainer>
        {createdTenant && <TenantCreatedCallout tenant={createdTenant} onClose={() => setCreatedTenant(null)} />}
        {editedTenant && <TenantEditedCallout tenant={editedTenant} onClose={() => setEditedTenant(null)} />}

        <Table.Root variant="surface" data-test-id="settings-tenants-table">
          <Table.Header className="bg-neutral-a2">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SortableColumnHeaderCell key={header.id} header={header} />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Row key={row.id} align="center">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </SettingsPageContainer>
    </>
  );
}
