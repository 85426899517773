import { cn } from 'utils/styles';

type DashboardLayoutProps = {
  leftSection: React.ReactNode;
  rightSection: React.ReactNode;
  isCollapsed?: boolean;
  rightSectionDataTestId?: string;
};

export function DashboardLayout({
  leftSection,
  rightSection,
  isCollapsed,
  rightSectionDataTestId,
}: DashboardLayoutProps) {
  return (
    <div className="flex gap-2 bg-neutral-3">
      <div
        className={cn(
          'flex min-w-[314px] max-w-[314px] flex-col gap-1 overflow-hidden border-r border-neutral-5 transition-[min-width,max-width] duration-200 will-change-[min-width,max-width]',
          isCollapsed && 'min-w-[32px] max-w-[32px]',
        )}
      >
        <div className={cn('min-w-[314px]')}>{leftSection}</div>
      </div>
      <div
        className={cn(
          'w-[calc(100%-320px)] transition-[width] duration-200 will-change-[width]',
          isCollapsed && 'w-[calc(100%-38px)]',
        )}
        data-test-id={rightSectionDataTestId}
      >
        {rightSection}
      </div>
    </div>
  );
}
