import { createRootRouteWithContext } from '@tanstack/react-router';
import type { QueryClient } from '@tanstack/react-query';

import { Root } from 'components/layouts/Root';

export interface ExtendedRouteContext {
  queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<ExtendedRouteContext>()({
  component: Root,
});
