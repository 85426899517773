import { useMemo } from 'react';
import { useTenants } from 'services/api/tenants';
import { useLazyHasAccess } from 'services/auth/useHasAccess';

export const useWriteAccessTenants = () => {
  const tenantsQuery = useTenants();
  const hasAccess = useLazyHasAccess();

  const tenantsWithWriteAccess = useMemo(() => {
    if (tenantsQuery.isLoading || !tenantsQuery.data) {
      return [];
    }

    return tenantsQuery.data.data.filter((tenant) => hasAccess({ scope: 'tenant:write', tenantId: tenant.id }));
  }, [tenantsQuery.data, tenantsQuery.isLoading, hasAccess]);

  return {
    tenants: tenantsWithWriteAccess,
    isLoading: tenantsQuery.isLoading,
    error: tenantsQuery.error,
  };
};
