import { useTranslate } from 'services/i18n/useTranslate';

import { IconButton } from 'components/common/IconButton';
import { BellIcon, StarIcon } from 'components/common/Icons';

import { UserMenu } from './UserMenu';

export function PageHeaderActions() {
  const t = useTranslate();

  return (
    <div className="flex items-center gap-4">
      <div className="space-x-2">
        <IconButton
          size="4"
          variant="soft"
          color="gray"
          onClick={() => {}}
          aria-label={t('page_header.bookmarks.title')}
        >
          <StarIcon width="20" height="20" aria-hidden className="text-neutral-11" />
        </IconButton>

        <IconButton
          size="4"
          variant="soft"
          color="gray"
          onClick={() => {}}
          aria-label={t('page_header.notifications.title')}
        >
          <BellIcon width="20" height="20" aria-hidden className="text-neutral-11" />
        </IconButton>
      </div>

      <UserMenu />
    </div>
  );
}
