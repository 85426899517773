import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, type APIError } from 'services/api';

import { useStore } from 'services/store';
import type { paths } from 'types/schemas/api-schema';

type LoginResponse = paths['/auth/login-basic']['post']['responses']['200']['content']['application/json'];

export const useLogin = () => {
  return useMutation<
    LoginResponse,
    APIError<paths['/auth/login-basic']['post']['responses']['401']['content']['application/json']>,
    { email: string; password: string }
  >({
    mutationFn: (data) => {
      return api
        .url('/auth/login-basic')
        .headers({ Authorization: `Basic ${btoa(`${data.email}:${data.password}`)}` })
        .post()
        .json((r: LoginResponse) => r);
    },
  });
};

export const useCurrentUser = (options = {}) => {
  const authToken = useStore((state) => state.auth.authToken);

  return useQuery({
    ...options,
    queryKey: ['currentUser'],
    enabled: !!authToken,
    // TODO see if this makes sense when we actually implement auth
    retry: false,
    queryFn: () => {
      return api
        .url(`/auth/users/current`)
        .get()
        .json<paths['/auth/users/current']['get']['responses']['200']['content']['application/json']>();
    },
  });
};

type PasswordResetRequestBody =
  paths['/auth/password-reset-token']['post']['requestBody']['content']['application/json'];

export const useGetUserInviteLink = () => {
  return useMutation<
    string,
    paths['/auth/password-reset-token']['post']['responses']['400']['content']['application/json'],
    PasswordResetRequestBody
  >({
    mutationFn: async ({ email }: PasswordResetRequestBody) => {
      const response = await api
        .url('/auth/password-reset-token')
        .post({ email })
        .json<paths['/auth/password-reset-token']['post']['responses']['200']['content']['application/json']>();
      return `${window.location.origin}/create-account?token=${response.data.jwt}`;
    },
  });
};

export const useGetUserResetPasswordLink = () => {
  return useMutation<
    string,
    paths['/auth/password-reset-token']['post']['responses']['400']['content']['application/json'],
    PasswordResetRequestBody
  >({
    mutationFn: async ({ email }: PasswordResetRequestBody) => {
      await api.url('/auth/password-reset').post({ email }).res();

      const response = await api
        .url('/auth/password-reset-token')
        .post({ email })
        .json<paths['/auth/password-reset-token']['post']['responses']['200']['content']['application/json']>();

      return `${window.location.origin}/reset-password?token=${response.data.jwt}`;
    },
  });
};

export const useLogout = () => {
  const clearAuthToken = useStore((state) => state.auth.clearAuthToken);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      return new Promise((resolve) => {
        clearAuthToken();

        queryClient.clear();

        resolve(undefined);
      });
    },
  });
};

export const useSetPassword = (token: string) => {
  return useMutation<
    null,
    APIError<paths['/auth/password-set']['post']['responses']['400']['content']['application/json']>,
    paths['/auth/password-set']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (data) => {
      await api.url(`/auth/password-set`).auth(`Bearer ${token}`).post(data).res();

      return null;
    },
  });
};

export const useForgotPassword = () => {
  return useMutation<
    paths['/auth/forgot-password']['post']['responses']['204']['content'],
    APIError<paths['/auth/forgot-password']['post']['responses']['500']['content']['application/json']>,
    paths['/auth/forgot-password']['post']['requestBody']['content']['application/json']
  >({
    mutationFn: async (data) => {
      await api.url('/auth/forgot-password').post(data).res();

      return undefined;
    },
  });
};

export const useChangePassword = () => {
  return useMutation<
    null,
    APIError<paths['/auth/change-password']['patch']['responses']['400']['content']['application/json']>,
    NonNullable<paths['/auth/change-password']['patch']['requestBody']>['content']['application/json']
  >({
    mutationFn: async (data) => {
      await api.url('/auth/change-password').patch(data).res();

      return null;
    },
  });
};
