import { useNavigate } from '@tanstack/react-router';

import type { components } from 'types/schemas/api-schema';
import { useUpdatePlatformEnrichment } from 'services/api/platform-enrichments';
import { useTranslate } from 'services/i18n/useTranslate';

import { LaptopIcon } from 'components/common/Icons';

import {
  PlatformEnrichmentForm,
  type EditEnrichmentFormFields,
  type EnrichmentContentProps,
} from '../PlatformEnrichmentForm';

export const EditEnrichmentTabItem = () => {
  return <LaptopIcon className="size-4" />;
};

export function PlatformEnrichmentEditContent({
  onSuccess,
  enrichment,
}: {
  onSuccess: EnrichmentContentProps['onSuccess'];
  enrichment: components['schemas']['enrichmentProfile'];
}) {
  const t = useTranslate();
  const navigate = useNavigate();
  const updatePlatformEnrichmentMutation = useUpdatePlatformEnrichment();

  const handleSubmit = async (data: EditEnrichmentFormFields) => {
    if (!enrichment.type) {
      return;
    }

    if (!data.file || !(data.file instanceof File)) {
      return;
    }

    const type = enrichment.type;
    const profile = 'custom';
    const schema = enrichment.datasets[0]?.schema ?? '';

    let dataset: string | ArrayBuffer | undefined;

    if (type === 'intel' && data.file.type === 'application/json') {
      dataset = await data.file.text();
    } else if (type === 'geo' && data.file.type === '' && data.file.name.endsWith('.mmdb')) {
      dataset = await data.file.arrayBuffer();
    }

    if (!dataset) {
      // eslint-disable-next-line no-console
      console.error('Failed to process the dataset.');
      return;
    }

    const fileName = data.file.name;

    updatePlatformEnrichmentMutation.mutate(
      { type, profile, schema, dataset },
      {
        onSuccess: () => {
          onSuccess({ ...enrichment, file: fileName });
          void navigate({
            search: undefined,
          });
        },
      },
    );
  };

  if (!enrichment) {
    return <p>{t('settings.tabs.enrichments.subtabs.platform.sidebar.enrichment_not_found')}</p>;
  }

  return (
    <div className="mt-1 h-full">
      <PlatformEnrichmentForm
        enrichment={enrichment}
        isLoading={updatePlatformEnrichmentMutation.isPending}
        error={updatePlatformEnrichmentMutation.error?.json}
        onCloseCallout={updatePlatformEnrichmentMutation.reset}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
