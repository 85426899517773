import React, { type ComponentProps } from 'react';
import { Link as RadixLink } from '@radix-ui/themes';
import { Link as TanstackRouterLink, type LinkProps as TanstackLinkProps } from '@tanstack/react-router';

import { cn } from 'utils/styles';

type InternalLinkProps = TanstackLinkProps & {
  className?: string;
  color?: 'accent' | 'neutral';
};

type ExternalLinkProps = Omit<ComponentProps<typeof RadixLink>, 'href'> & {
  externalTo: string;
};

type LinkProps = InternalLinkProps | ExternalLinkProps;

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  if ('externalTo' in props) {
    const { externalTo, ...rest } = props;
    return <RadixLink ref={ref} href={externalTo} target="_blank" rel="noopener noreferrer" {...rest} />;
  }

  return (
    <TanstackRouterLink
      ref={ref}
      {...props}
      className={cn('rt-Text rt-reset rt-Link rt-underline-auto', props.className)}
      data-accent-color={props.color}
    />
  );
});
