import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Text } from 'components/common/Text';
import { Skeleton } from 'components/common/Skeleton';
import { TextField } from 'components/common/TextField';
import { IconButton } from 'components/common/IconButton';
import { ScrollArea } from 'components/common/ScrollArea';
import * as CollapsiblePrimitives from 'components/common/CollapsiblePrimitive';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon } from 'components/common/Icons';

import type { FlattenedTranslationKeys } from 'types/translations';
import { formatPercentage } from 'utils/numbers';
import { handleKeyDown } from 'utils/handleKeyDown';
import { useTranslate } from 'services/i18n/useTranslate';

export type Option = {
  label: string;
  value: string | number;
  count: number;
  percentage: number;
};

type Unit = 'range' | 'entry';

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const transition = {
  duration: 0.25,
  ease: 'easeInOut',
};

const UNIT_TO_TKEY: Record<Unit, FlattenedTranslationKeys> = {
  range: 'common.facets.units.range',
  entry: 'common.facets.units.entry',
};

const MAX_TABLE_HEIGHT = 300;

export type FacetSectionProps = {
  isLoading?: boolean;
  title: string;
  property: string;
  unit: Unit;
  options: Option[];
  defaultOpen?: boolean;
  hideWhenSingleOption?: boolean;
  onClick: (property: string, option: Option) => void;
};

const FacetCollapsible = ({
  isLoading,
  title,
  children,
  defaultOpen = true,
}: {
  isLoading?: boolean;
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <CollapsiblePrimitives.Root onOpenChange={() => setIsOpen((x) => !x)} className="border-b border-neutral-a5">
      <CollapsiblePrimitives.Trigger asChild>
        <div className="flex cursor-pointer items-center justify-between gap-2 px-4 py-2">
          <Skeleton isLoading={isLoading}>
            <Text size="1" className="capitalize">
              {title}
            </Text>
          </Skeleton>
          <IconButton type="button" variant="ghost" color="gray" aria-label={t('common.actions.toggle')}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </div>
      </CollapsiblePrimitives.Trigger>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            className="overflow-hidden"
            transition={transition}
            variants={variants}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </CollapsiblePrimitives.Root>
  );
};

export const FacetSection = ({
  isLoading,
  title,
  unit,
  property,
  options,
  defaultOpen,
  hideWhenSingleOption,
  onClick,
}: FacetSectionProps) => {
  const t = useTranslate();

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1 && hideWhenSingleOption) {
    return null;
  }

  const handleSelect = (option: Option) => {
    onClick(property, option);
  };

  return (
    <FacetCollapsible title={title} isLoading={isLoading} defaultOpen={defaultOpen}>
      <ScrollArea
        style={{
          maxHeight: MAX_TABLE_HEIGHT,
        }}
        className="pb-1.5"
      >
        <table className="mx-4 mb-1 w-[calc(100%-32px)] text-xs">
          <thead>
            <tr className="h-5">
              <th align="left" className="pb-0.5 font-normal text-neutral-11">
                <Skeleton isLoading={isLoading}>{t(UNIT_TO_TKEY[unit])}</Skeleton>
              </th>
              <th align="right" className="pb-0.5 font-normal text-neutral-11">
                <Skeleton isLoading={isLoading}>#</Skeleton>
              </th>
              <th align="right" className="pb-0.5 font-normal text-neutral-11">
                <Skeleton isLoading={isLoading}>%</Skeleton>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {options.map((o) => (
              <tr
                key={o.label}
                tabIndex={0}
                className="h-6 cursor-pointer hover:bg-neutral-4 focus:outline-accent-9 active:bg-neutral-6"
                onClick={() => handleSelect(o)}
                onKeyDown={handleKeyDown(() => handleSelect(o))}
              >
                <td className="max-w-10">
                  <Skeleton isLoading={isLoading}>
                    <div className="overflow-hidden truncate text-wrap">{o.label}</div>
                  </Skeleton>
                </td>
                <td align="right">
                  <Skeleton isLoading={isLoading}>{o.count}</Skeleton>
                </td>
                <td align="right" className="w-14 text-xs text-info-11">
                  <Skeleton isLoading={isLoading}>{formatPercentage(o.percentage)}</Skeleton>
                </td>
                <td align="right" className="w-6 pr-1">
                  <Skeleton isLoading={isLoading}>
                    <PlusIcon className="size-3" aria-hidden />
                  </Skeleton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollArea>
    </FacetCollapsible>
  );
};

const LOADING_OPTIONS = [
  { count: 0, label: 'Loading', percentage: 0, value: '1' },
  { count: 0, label: 'Loading More', percentage: 0, value: '2' },
  { count: 0, label: 'Another value', percentage: 0, value: '3' },
];

type SearchableFacetSectionProps = {
  isFetching?: boolean;
  title: string;
  property: string;
  unit: Unit;
  options: Option[];
  filter: string;
  onFilterChange: (filter: string) => void;
  sort: 'asc' | 'desc';
  onSortClick: () => void;
  onClick: (property: string, option: Option) => void;
};

export const SearchableFacetSection = ({
  isFetching,
  title,
  unit,
  property,
  options,
  filter,
  onFilterChange,
  sort,
  onSortClick,
  onClick,
}: SearchableFacetSectionProps) => {
  const t = useTranslate();

  const handleSelect = (option: Option) => {
    onClick(property, option);
  };

  return (
    <FacetCollapsible title={title}>
      <div className="mx-4">
        <TextField
          size="1"
          placeholder={t('anomalies.facets.search_placeholder')}
          className="mb-2 mt-px"
          value={filter}
          onChange={(e) => onFilterChange(e.target.value)}
        />

        <ScrollArea
          style={{
            maxHeight: MAX_TABLE_HEIGHT,
          }}
          className="pb-1.5"
        >
          <table className="mb-1 w-full text-xs">
            <thead>
              <tr className="h-5">
                <th align="left" className="pb-0.5 font-normal text-neutral-11">
                  {t(UNIT_TO_TKEY[unit])}
                </th>
                <th align="right" className="pb-0.5 font-normal text-neutral-11">
                  <IconButton
                    type="button"
                    variant="ghost"
                    color="gray"
                    aria-label={sort === 'asc' ? t('common.actions.sort_desc') : t('common.actions.sort_asc')}
                    onClick={onSortClick}
                  >
                    # {sort === 'asc' ? <ArrowUpIcon className="size-3" /> : <ArrowDownIcon className="size-3" />}
                  </IconButton>
                </th>
                <th align="right" className="pb-0.5 font-normal text-neutral-11">
                  %
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {isFetching
                ? LOADING_OPTIONS.map((o) => (
                    <tr key={o.label} className="h-6">
                      <td className="max-w-10">
                        <Skeleton isLoading>{o.label}</Skeleton>
                      </td>
                      <td align="right">
                        <Skeleton isLoading>{o.count}</Skeleton>
                      </td>
                      <td align="right" className="w-14 text-xs text-info-11">
                        <Skeleton isLoading>{formatPercentage(o.percentage)}</Skeleton>
                      </td>
                      <td align="right" className="w-6 pr-1">
                        <Skeleton isLoading>
                          <PlusIcon className="size-3" aria-hidden />
                        </Skeleton>
                      </td>
                    </tr>
                  ))
                : options.map((o) => (
                    <tr
                      key={o.label}
                      tabIndex={0}
                      className="h-6 cursor-pointer hover:bg-neutral-4 focus:outline-accent-9 active:bg-neutral-6"
                      onClick={() => handleSelect(o)}
                      onKeyDown={handleKeyDown(() => handleSelect(o))}
                    >
                      <td className="max-w-10">
                        <div className="overflow-hidden truncate text-wrap">{o.label}</div>
                      </td>
                      <td align="right">{o.count}</td>
                      <td align="right" className="w-14 text-xs text-info-11">
                        {formatPercentage(o.percentage)}
                      </td>
                      <td align="right" className="w-6 pr-1">
                        <PlusIcon className="size-3" aria-hidden />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </ScrollArea>
      </div>
    </FacetCollapsible>
  );
};
