import { type ReactNode } from 'react';

import { Card } from 'components/common/Card';

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid h-full w-full place-content-center">
      <Card size="5" className="w-[400px]">
        <div className="flex w-[320px] flex-col items-center justify-center">{children}</div>
      </Card>
    </div>
  );
};
