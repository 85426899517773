import { type Slice } from './_utils';

const EXPANDED_BY_DEFAULT_WIDTH = 1600;

export type MenuSlice = {
  menu: {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
  };
};

export const menuSlice: Slice<MenuSlice> = {
  createSlice: (set) => ({
    menu: {
      expanded: window.matchMedia(`(min-width: ${EXPANDED_BY_DEFAULT_WIDTH}px)`).matches,
      setExpanded: (expanded) => {
        set((prev) => ({
          menu: {
            ...prev.menu,
            expanded,
          },
        }));
      },
    },
  }),
  persist: (state) => ({
    menu: {
      expanded: state.menu.expanded,
    },
  }),
};
