import { useMemo, useState, type ReactNode, useCallback } from 'react';
import { format } from 'date-fns';
import { getRouteApi, Link } from '@tanstack/react-router';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { dateTimeFormatCommon } from 'consts/dateFormats';
import { copyToClipboard } from 'utils/copyToClipboard';
import { type components } from 'types/schemas/api-schema';

import { useUsers } from 'services/api/users';
import { useRoles } from 'services/api/roles';
import { useGetUserInviteLink } from 'services/api/auth';
import { useHasAccess } from 'services/auth/useHasAccess';
import { useTranslate } from 'services/i18n/useTranslate';

import { Text } from 'components/common/Text';
import { Badge } from 'components/common/Badge';
import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { Translate } from 'components/common/Translate';
import { Authorize } from 'components/common/Authorize';
import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { EyeOpenIcon, GearIcon, LockClosedIcon, PersonIcon } from 'components/common/Icons';

import { UserSidebar } from './components/UserSidebar';
import { UserStatusBadge } from './components/UserStatusBadge';
import { type ViewMode } from './components/types';

type RoleData = components['schemas']['role'];
type UserData = components['schemas']['user'];

const Route = getRouteApi('/_app/settings/users-and-access/users');

const userColumnHelper = createColumnHelper<UserData>();

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="mt-3 p-3">{children}</div>;
};

const Roles = ({ roleIds, roleDefinitions }: { roleIds: string[]; roleDefinitions: RoleData[] }) => {
  const rolesRender = roleDefinitions
    .filter((role) => roleIds.includes(role.id))
    .map((role) => (
      <Badge key={role.id} size="1" variant="soft" color="bronze">
        {role.roleType === 'analyst' ? <EyeOpenIcon /> : <LockClosedIcon />}
        {role.name}
      </Badge>
    ));
  return <div className="flex flex-wrap gap-2">{rolesRender}</div>;
};

const UserCreatedCallout = ({ user, onClose }: { user: UserData; onClose: () => void }) => {
  const getUserInviteLinkMutation = useGetUserInviteLink();

  return (
    <Callout variant="soft" status="success" size="1" className="mb-2" onClose={onClose}>
      <span className="flex items-center gap-2">
        {getUserInviteLinkMutation.isSuccess ? (
          <Translate
            tkey="settings.tabs.usersAndAccess.subtabs.manage_users.user_created_callout_copied_link"
            values={{ userName: `${user.firstName} ${user?.lastName}` }}
            components={{}}
          />
        ) : (
          <Translate
            tkey="settings.tabs.usersAndAccess.subtabs.manage_users.user_created_callout"
            values={{ userName: `${user.firstName} ${user?.lastName}` }}
            components={{
              'invite-link': (
                <Button
                  size="1"
                  className="!text-[inherit] underline"
                  variant="ghost"
                  onClick={() => {
                    void getUserInviteLinkMutation.mutateAsync(
                      { email: user.email },
                      {
                        onSuccess: (link) => {
                          void copyToClipboard(link);
                        },
                      },
                    );
                  }}
                  isLoading={getUserInviteLinkMutation.isPending}
                />
              ),
            }}
          />
        )}
      </span>
    </Callout>
  );
};

const UserEditedCallout = ({
  user,
  userSidebarIntent,
  onClose,
}: {
  user: UserData;
  userSidebarIntent: Extract<ViewMode, 'edit' | 'view'>;
  onClose: () => void;
}) => {
  return (
    <Callout variant="soft" status="success" size="1" className="mb-2" onClose={onClose}>
      <Translate
        tkey="settings.tabs.usersAndAccess.subtabs.manage_users.user_updated_callout"
        values={{ userName: `${user.firstName} ${user?.lastName}` }}
        components={{
          'users-link': (
            <Link
              className="!text-[inherit] underline"
              from="/settings/users-and-access/users"
              search={{
                userSidebarIntent,
                userId: user.id,
              }}
            />
          ),
        }}
      />
    </Callout>
  );
};

export function UsersTabActionButtons() {
  const t = useTranslate();
  const navigate = Route.useNavigate();

  return (
    <Authorize policy={{ scope: 'user:write' }}>
      <Button
        size="1"
        variant="outline"
        onClick={() => {
          void navigate({
            search: {
              userSidebarIntent: 'create',
            },
          });
        }}
      >
        <PersonIcon /> {t('settings.tabs.usersAndAccess.actions.create_user')}
      </Button>
    </Authorize>
  );
}

export function ManageUsers() {
  const t = useTranslate();
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const usersQuery = useUsers();
  const rolesQuery = useRoles();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [createdUser, setCreatedUser] = useState<UserData | null>(null);
  const [editedUser, setEditedUser] = useState<UserData | null>(null);

  const userSidebarIntent = 'userSidebarIntent' in searchParams ? searchParams.userSidebarIntent : null;
  const hasWriteAccess = useHasAccess({ scope: 'user:write' });

  const handleUserEditClick = useCallback(
    (userId: string) => {
      if (hasWriteAccess) {
        void navigate({
          search: { userSidebarIntent: 'edit', userId },
        });
      } else {
        void navigate({
          search: { userSidebarIntent: 'view', userId },
        });
      }
    },
    [navigate, hasWriteAccess],
  );

  const columns = useMemo(() => {
    if (!rolesQuery.data) {
      return [];
    }

    return [
      userColumnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        header: () => <Text>{t('settings.tabs.usersAndAccess.subtabs.manage_users.table.headers.user')}</Text>,
        id: 'fullName',
        cell: (info) => <Text>{info.getValue()}</Text>,
      }),
      userColumnHelper.accessor('roles', {
        header: () => <Text>{t('settings.tabs.usersAndAccess.subtabs.manage_users.table.headers.roles')}</Text>,
        cell: (info) => <Roles roleIds={info.getValue()} roleDefinitions={rolesQuery.data.data} />,
        enableSorting: false,
      }),
      userColumnHelper.display({
        header: () => <Text>{t('common.status')}</Text>,
        id: 'status',
        cell: (info) => (
          <UserStatusBadge
            status={info.row.original.status}
            passwordResetRequested={info.row.original.passwordResetRequested}
          />
        ),
      }),
      userColumnHelper.accessor('updatedAt', {
        header: () => <Text>{t('common.last_updated')}</Text>,
        cell: (info) => (
          <Badge size="1" variant="soft" color="gray">
            {format(info.getValue(), dateTimeFormatCommon)}
          </Badge>
        ),
      }),
      userColumnHelper.accessor('id', {
        id: 'actions',
        header: () => (
          <Text align="right" className="w-full">
            {t('common.actions_label')}
          </Text>
        ),
        cell: (info) => (
          <div className="flex justify-end">
            <Button size="1" variant="outline" color="gray" onClick={() => handleUserEditClick(info.getValue())}>
              <GearIcon /> Settings
            </Button>
          </div>
        ),
        enableSorting: false,
      }),
    ];
  }, [t, rolesQuery.data, handleUserEditClick]);

  const table = useReactTable({
    data: usersQuery.data?.data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (usersQuery.isLoading || rolesQuery.isLoading) {
    return <Container>Loading...</Container>;
  }

  if (usersQuery.isError || rolesQuery.isError) {
    return <Container>Error...</Container>;
  }

  return (
    <>
      <UserSidebar
        viewMode={userSidebarIntent || 'view'}
        onSuccess={userSidebarIntent === 'create' ? setCreatedUser : setEditedUser}
      />

      <Container>
        {createdUser && <UserCreatedCallout user={createdUser} onClose={() => setCreatedUser(null)} />}
        {editedUser && (
          <UserEditedCallout user={editedUser} userSidebarIntent="edit" onClose={() => setEditedUser(null)} />
        )}

        <Table.Root variant="surface" data-test-id="settings-users-table">
          <Table.Header className="bg-neutral-a2">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <SortableColumnHeaderCell key={header.id} header={header} />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Row key={row.id} align="center">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      </Container>
    </>
  );
}
