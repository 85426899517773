import { type Slice } from './_utils';

export type ThreatsSlice = {
  threats: {
    viewMode: 'list' | 'cards';
    setViewMode: (mode: 'list' | 'cards') => void;
  };
};

export const threatsSlice: Slice<ThreatsSlice> = {
  createSlice: (set) => ({
    threats: {
      viewMode: 'cards',
      setViewMode: (mode) => {
        set((prev) => ({
          threats: {
            ...prev.threats,
            viewMode: mode,
          },
        }));
      },
    },
  }),
  persist: (state) => ({
    threats: {
      viewMode: state.threats.viewMode,
    },
  }),
};
