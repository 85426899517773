export const downloadFile = (fileContent: string, fileName: string, fileType: string = 'text/csv') => {
  const blob = new Blob([fileContent], { type: `${fileType};charset=utf-8;` });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
