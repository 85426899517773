import { Link } from '@tanstack/react-router';

import { useStore } from 'services/store';
import { useTranslate } from 'services/i18n/useTranslate';
import { Button } from 'components/common/Button';
import { AppLayout } from 'components/layouts/AppLayout';

const FourSvg = () => {
  return (
    <svg width="133" height="167" viewBox="0 0 133 167" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M78.7442 166.787V133.969H7.71355L0.745361 116.212L51.7705 0H91.3319L46.8254 100.926H78.7442V72.6041L86.1619 52.1491H117.631V100.926H132.691V127.9L117.631 133.969V166.787H78.7442Z"
        fill="var(--neutral-11)"
      />
    </svg>
  );
};

export const NotFoundPage = () => {
  const t = useTranslate();
  const theme = useStore((s) => s.theme.theme);

  return (
    <div className="fixed left-0 z-10">
      <AppLayout displayHeader={false}>
        <div className="fixed inset-0 h-screen overflow-hidden bg-neutral-2 dark:bg-neutral-5">
          <img
            src={theme === 'light' ? '/images/404-waves-light.svg' : '/images/404-waves-dark.svg'}
            className="mt-5 min-h-screen min-w-[100vw]"
          />

          <div className="fixed inset-0 flex flex-col items-center justify-center">
            <div className="mb-6 flex gap-2">
              <FourSvg />
              <img src="/images/404-planet.svg" />
              <FourSvg />
            </div>
            <div className="mb-2 text-6xl font-bold">{t('404_page.header')}</div>
            <div className="mb-6 text-2xl font-medium">{t('404_page.description')}</div>

            <Link to="/">
              <Button variant="classic" size="3">
                {t('404_page.button')}
              </Button>
            </Link>
          </div>
        </div>
      </AppLayout>
    </div>
  );
};
