import { endOfDay, startOfDay, startOfMinute, subDays, subHours } from 'date-fns';
import { z } from 'zod';

import { defaultTimeRange, type ValidTimerange, validTimeRanges } from 'consts/dateTime';

type ValidationDateTimeFilter = {
  startTime?: string;
  endTime?: string;
  timeRange?: ValidTimerange;
};

const dateTimeSchema = z.union([
  z.object({
    startTime: z.string(),
    endTime: z.string(),
  }),
  z.object({
    timeRange: z.enum(validTimeRanges).default(defaultTimeRange).catch(defaultTimeRange),
  }),
]);

export const createDateTimeValidationSchema = () => {
  return dateTimeSchema.transform((data: ValidationDateTimeFilter) => {
    const hasTimeRange = 'timeRange' in data;
    const hasStartAndEndTime = 'startTime' in data && 'endTime' in data;

    if (!hasTimeRange && !hasStartAndEndTime) {
      return { ...data, timeRange: defaultTimeRange, startTime: undefined, endTime: undefined };
    }

    if (hasTimeRange) {
      return { ...data, startTime: undefined, endTime: undefined };
    }

    const startDate = data.startTime ? new Date(data.startTime) : null;
    const endDate = data.endTime ? new Date(data.endTime) : null;

    if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || startDate >= endDate) {
      return { ...data, timeRange: defaultTimeRange, startTime: undefined, endTime: undefined };
    }

    return data;
  });
};

export const isValidTimeRange = (value: unknown): value is ValidTimerange => {
  return typeof value === 'string' && validTimeRanges.includes(value as ValidTimerange);
};

function assertUnreachable(_: never): never {
  throw new Error(`Exhaustive check failed ${_}`);
}

export const parseTimerange = (timerange: ValidTimerange): { startTime: string; endTime: string } => {
  const now = startOfMinute(new Date());

  switch (timerange) {
    case '1h':
      return { startTime: subHours(now, 1).toISOString(), endTime: now.toISOString() };
    case '12h':
      return { startTime: subHours(now, 12).toISOString(), endTime: now.toISOString() };
    case '24h':
      return { startTime: subHours(now, 24).toISOString(), endTime: now.toISOString() };
    case 'today':
      return { startTime: startOfDay(now).toISOString(), endTime: now.toISOString() };
    case 'yesterday':
      const yesterday = subDays(now, 1);
      return { startTime: startOfDay(yesterday).toISOString(), endTime: endOfDay(yesterday).toISOString() };
  }

  return assertUnreachable(timerange);
};
