import { Handle, Position, type NodeProps } from '@xyflow/react';

import { Badge } from 'components/common/Badge';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { Skeleton } from 'components/common/Skeleton';

export type ThreatEvidenceNodeProps = {
  id: string;
  data: {
    name: string;
    observations: { name: string; description: string; count: number }[];
    isLoading?: boolean;
  };
};

const HANDLE_STYLE = {
  top: 40,
};

export function ThreatEvidenceNode({ data }: ThreatEvidenceNodeProps & NodeProps) {
  return (
    <div className="pointer-events-auto w-[350px]">
      <Handle type="target" position={Position.Left} style={HANDLE_STYLE} />
      <div className="">
        <Skeleton isLoading={data.isLoading}>
          <Badge size="1" variant="soft" color="blue" className="!text-xs !text-info-12">
            {data.name}
          </Badge>
        </Skeleton>

        {data.observations.map((observation) => (
          <Skeleton key={observation.name} isLoading={data.isLoading}>
            <div className="mt-0.5 w-full bg-neutral-4 px-2 py-1 text-gray-11">
              <div className="flex w-full items-center justify-between text-xs">
                <div className="flex flex-wrap items-center gap-1">
                  <Badge size="1" variant="outline" color="bronze" className="!text-xs">
                    DNS
                  </Badge>
                  <div>{observation.name}</div>
                </div>
                <div className="flex items-center gap-1 text-xs">
                  count: {observation.count}
                  <InfoTooltip content={observation.description} />
                </div>
              </div>
            </div>
          </Skeleton>
        ))}
      </div>
      <Handle type="source" position={Position.Right} id="b" style={HANDLE_STYLE} />
    </div>
  );
}
