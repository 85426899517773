import { useMemo } from 'react';
import { useMatches } from '@tanstack/react-router';

import { useTranslate } from 'services/i18n/useTranslate';

import { PageHeader } from 'components/common/PageHeader';
import { PageHeaderActions } from 'components/common/PageHeaderActions';
import type { FlattenedTranslationKeys } from 'types/translations';

export type MetaProps = {
  name: string;
  content: FlattenedTranslationKeys;
};

export function PageHeaderLayout() {
  const t = useTranslate();
  const matches = useMatches();

  const { title, description } = useMemo(() => {
    const reversedMatches = [...matches].reverse();
    let meta: MetaProps[] | undefined = undefined;

    for (const match of reversedMatches) {
      if (match.meta && Array.isArray(match.meta)) {
        meta = match.meta as MetaProps[];
        break;
      }
    }

    const pageTitle = meta?.find((item) => item.name === 'pageTitle')?.content;
    const pageDescription = meta?.find((item) => item.name === 'pageDescription')?.content;

    return {
      title: pageTitle ? t(pageTitle) : '',
      description: pageDescription ? t(pageDescription) : '',
    };
  }, [matches, t]);

  return (
    <PageHeader title={title} description={description}>
      <PageHeaderActions />
    </PageHeader>
  );
}
