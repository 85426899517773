import { createFileRoute } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import { AuthLayout } from 'components/layouts/AuthLayout';

export const AuthRoute = () => {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export const Route = createFileRoute('/_auth')({
  component: AuthRoute,
});
