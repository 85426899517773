import wretch, { type WretchError } from 'wretch';
import AbortAddon from 'wretch/addons/abort';

import { BASE_PATH } from 'consts/api';
import { getAuthToken } from 'utils/storage';
import { useStore } from 'services/store';
import { queryClient } from 'services/api/queryClient';
import { mockedAPI } from 'mocks/index';

import { queryStringAddon } from './wretch/queryStringAddon';
import { constructMockMiddleware } from './wretch/mockMiddleware';

const API_PATH = `${BASE_PATH}/v1`;

export let api = wretch(API_PATH)
  .addon(AbortAddon())
  .addon(queryStringAddon)
  .middlewares([constructMockMiddleware(mockedAPI, BASE_PATH)])
  .catcher(401, (error) => {
    useStore.getState().auth.clearAuthToken();
    queryClient.clear();
    throw error;
  });

export const setToken = (token: string) => {
  api = api.auth(`Bearer ${token}`);
};

const authToken = getAuthToken();

if (authToken) {
  setToken(authToken);
}

export type APIError<T> = Omit<WretchError, 'json'> & { json: T };
