import { Heading } from 'components/common/Heading';
import { LayersIcon } from 'components/common/Icons';
import type { ComponentType } from 'react';

type SectionProps = {
  icon?: ComponentType<any>;
  title: string;
  children: React.ReactNode;
  required?: boolean;
};

export const Section = ({ icon: Icon = LayersIcon, title, children, required }: SectionProps) => {
  return (
    <section className="bg-neutral-3 pb-2">
      <div className="flex items-center gap-2 border-b border-neutral-a5 px-4 py-2">
        <Icon />
        <Heading size="2" weight="regular">
          {title}
          {required && <span className="ml-1 text-red-9">*</span>}
        </Heading>
      </div>

      <div className="flex flex-col gap-2 p-4">{children}</div>
    </section>
  );
};
