import type { components } from 'types/schemas/api-schema';

import { Badge } from 'components/common/Badge';
import { useTranslate } from 'services/i18n/useTranslate';

type StatusBadgeProps = {
  status: components['schemas']['user']['status'];
  passwordResetRequested: components['schemas']['user']['passwordResetRequested'];
};

export const UserStatusBadge = ({ status, passwordResetRequested }: StatusBadgeProps) => {
  const t = useTranslate();

  if (passwordResetRequested) {
    return (
      <Badge size="1" variant="soft" color="orange">
        {t('settings.common.password_reset_requested')}
      </Badge>
    );
  }

  if (status === 'suspended') {
    return (
      <Badge size="1" variant="soft" color="red">
        {t('settings.common.suspended')}
      </Badge>
    );
  }

  if (status === 'invited') {
    return (
      <Badge size="1" variant="soft" color="sky">
        {t('settings.common.invited')}
      </Badge>
    );
  }

  return (
    <Badge size="1" variant="soft" color="green">
      {t('settings.common.active')}
    </Badge>
  );
};
