import { useEffect, type ReactNode, type ComponentProps } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { decodeAuthToken } from 'utils/token';
import { useStore } from 'services/store';
import { useLogout } from 'services/api/auth';
import { useCurrentUser } from 'services/api/auth';

import { AuthLayout } from 'components/layouts/AuthLayout';
import { Login } from 'components/scenes/Auth/Login';

import { MainMenu, COLLAPSED_WIDTH, EXPANDED_WIDTH } from './MainMenu';
import { PageHeaderLayout } from './PageHeaderLayout';

const zoomInDefinition = {
  initial: { scale: 0.99, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.99, opacity: 0 },
  transition: { duration: 0.15 },
};

const ZoomIn = (props: ComponentProps<typeof motion.div>) => {
  return <motion.div {...zoomInDefinition} {...props} />;
};

export function AppLayout({ displayHeader = true, children }: { displayHeader?: boolean; children: ReactNode }) {
  const currentUserQuery = useCurrentUser();
  const logoutMutation = useLogout();
  const { authToken, expanded } = useStore((state) => ({
    authToken: state.auth.authToken,
    expanded: state.menu.expanded,
  }));

  useEffect(() => {
    if (!authToken || logoutMutation.isPending) {
      return;
    }

    const payload = decodeAuthToken(authToken);

    const timeoutId = setTimeout(
      () => {
        // eslint-disable-next-line no-console
        console.log('Token expired, logging out...');

        logoutMutation.mutate();
      },
      payload.exp * 1000 - Date.now(),
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [authToken, logoutMutation]);

  if (currentUserQuery.isLoading) {
    return (
      <AnimatePresence>
        <ZoomIn className="flex h-screen w-screen items-center justify-center text-center text-2xl font-semibold">
          <div>
            Checking to see if you're logged in...
            <br />
            This should be making a call to get the current user if required
          </div>
        </ZoomIn>
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {currentUserQuery.data ? (
        <ZoomIn key="app" className="bg-[inherit]">
          <div className="flex min-h-full">
            <MainMenu />

            <div
              className="relative flex w-full flex-col overflow-auto transition-[margin] duration-200 ease-in-out"
              style={{
                marginLeft: expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH,
              }}
              id="content"
            >
              {displayHeader && <PageHeaderLayout />}

              <div className="h-full w-full overflow-auto">{children}</div>
            </div>
          </div>
        </ZoomIn>
      ) : (
        <AuthLayout key="auth">
          <Login />
        </AuthLayout>
      )}
    </AnimatePresence>
  );
}
