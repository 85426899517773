import { type ComponentProps, forwardRef } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { cva } from 'class-variance-authority';

import { CheckIcon, DividerHorizontalIcon } from 'components/common/Icons';

import { cn } from 'utils/styles';

type CheckboxSize = '1' | '2';
type CheckboxVariant = 'classic' | 'surface' | 'soft';

export type CheckboxProps = ComponentProps<typeof RadixCheckbox.Root> & {
  className?: string;
  size?: CheckboxSize;
  variant?: CheckboxVariant;
  highContrast?: boolean;
};
const disabledStyles = () =>
  cn(
    `data-[disabled][disabled]:cursor-not-allowed data-[disabled][disabled]:border data-[disabled][disabled]:border-neutral-a6 data-[disabled][disabled]:bg-neutral-a3 data-[disabled][disabled]:text-neutral-a8 data-[disabled][disabled]:hover:border-neutral-a6 data-[disabled][disabled]:hover:bg-neutral-a3 data-[disabled][disabled]:hover:text-neutral-a8 data-[disabled][disabled]:hover:opacity-100`,
  );

const buttonStyles = cva(
  `flex items-center justify-center outline-none focus:ring-2 focus:ring-accent-a8 focus:ring-offset-2 focus:ring-offset-accent-3 ${disabledStyles()}`,
  {
    variants: {
      size: {
        '1': 'h-4 w-4 rounded-1',
        '2': 'h-6 w-6 rounded-2',
      },
      variant: {
        classic:
          'border border-neutral-a7 bg-surface shadow shadow-neutral-1 hover:border-neutral-a8 active:border-neutral-a9',
        surface:
          'border border-neutral-a7 bg-surface shadow shadow-neutral-1 hover:border-neutral-a8 active:border-neutral-a9',
        soft: 'bg-accent-a5 text-accent-11 hover:bg-accent-a6 active:bg-accent-a7',
      },
      highContrast: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: ['classic', 'surface'],
        highContrast: false,
        className:
          'data-[state=checked]:border-0 data-[state=checked]:bg-accent-9 data-[state=checked]:text-contrast-accent data-[state=checked]:hover:bg-accent-10 data-[state=checked]:active:bg-accent-10',
      },
      {
        variant: ['classic', 'surface'],
        highContrast: true,
        className:
          'data-[state=checked]:border-0 data-[state=checked]:bg-accent-12 data-[state=checked]:text-neutral-1 data-[state=checked]:hover:opacity-90 data-[state=checked]:active:opacity-80',
      },
      {
        variant: ['classic', 'surface'],
        highContrast: false,
        className:
          'data-[state=indeterminate]:border data-[state=indeterminate]:border-accent-a7 data-[state=indeterminate]:bg-surface-accent data-[state=indeterminate]:text-accent-a11 data-[state=indeterminate]:hover:border-accent-a8 data-[state=indeterminate]:hover:opacity-90 data-[state=indeterminate]:active:border-accent-a8 data-[state=indeterminate]:active:bg-accent-a3 data-[state=indeterminate]:active:opacity-80',
      },
      {
        variant: ['classic', 'surface'],
        highContrast: true,
        className:
          'data-[state=indeterminate]:border data-[state=indeterminate]:border-accent-a7 data-[state=indeterminate]:bg-surface-accent data-[state=indeterminate]:text-accent-a12 data-[state=indeterminate]:hover:border-accent-a8 data-[state=indeterminate]:hover:opacity-90 data-[state=indeterminate]:active:border-accent-a8 data-[state=indeterminate]:active:bg-accent-a3 data-[state=indeterminate]:active:opacity-80',
      },
      { variant: 'soft', highContrast: false, className: 'data-[state=checked]:active:opacity-90' },
      {
        variant: 'soft',
        highContrast: true,
        className:
          'data-[state=checked]:text-accent-12 data-[state=checked]:hover:opacity-90 data-[state=checked]:active:opacity-80',
      },
      {
        variant: 'soft',
        highContrast: false,
        className:
          'data-[state=indeterminate]:border data-[state=indeterminate]:border-accent-a6 data-[state=indeterminate]:bg-surface data-[state=indeterminate]:hover:bg-surface data-[state=indeterminate]:hover:opacity-90 data-[state=indeterminate]:active:bg-surface data-[state=indeterminate]:active:opacity-80',
      },
      {
        variant: 'soft',
        highContrast: true,
        className:
          'data-[state=indeterminate]:border data-[state=indeterminate]:border-accent-a6 data-[state=indeterminate]:bg-surface data-[state=indeterminate]:text-accent-12 data-[state=indeterminate]:hover:bg-surface data-[state=indeterminate]:hover:opacity-90 data-[state=indeterminate]:active:bg-surface data-[state=indeterminate]:active:opacity-80',
      },
    ],
  },
);

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ className, size = '1', variant = 'surface', highContrast = false, ...props }, ref) => {
    return (
      <RadixCheckbox.Root ref={ref} className={cn(buttonStyles({ size, variant, highContrast }), className)} {...props}>
        <RadixCheckbox.Indicator className="data-[state=indeterminate]:hidden">
          <CheckIcon width={size === '1' ? 14 : 24} height={size === '1' ? 14 : 24} />
        </RadixCheckbox.Indicator>

        <RadixCheckbox.Indicator className="data-[state=indeterminate]:block data-[state=checked]:hidden data-[state=unchecked]:hidden">
          <DividerHorizontalIcon width={size === '1' ? 14 : 24} height={size === '1' ? 14 : 24} />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    );
  },
);
