import { Suspense, useEffect, type ReactNode } from 'react';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import englishTranslations from 'static/translations/en.json';
import { useStore } from 'services/store';

void i18n
  .use(initReactI18next)
  // TODO uncomment when we get another language
  // when switching to fetching translations, make sure the fix the cacheing issue
  // .use(Backend)
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: englishTranslations,
      },
    },
  });

const I18nProviderLoader = ({ children }: { children: ReactNode }) => {
  const language = useStore((s) => s.i18n.language);

  useEffect(() => {
    // TODO uncomment when we get another language
    // void i18n.changeLanguage(language);
    // i18n.addResources(language, 'translation', {
    //   translation: englishTranslations,
    // });
  }, [language]);

  return children;
};

const Fallback = () => {
  // TODO add a spinner once we support multiple languages
  // for now english will always be loaded as it's the fallback language
  return null;
};

export const I18nProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<Fallback />}>
      <I18nextProvider i18n={i18n}>
        <I18nProviderLoader>{children}</I18nProviderLoader>
      </I18nextProvider>
    </Suspense>
  );
};
