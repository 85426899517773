import { useState, type ReactNode } from 'react';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from '@tanstack/react-router';
import { zodResolver } from '@hookform/resolvers/zod';

import type { components } from 'types/schemas/api-schema';

import { cn } from 'utils/styles';
import { useTenants } from 'services/api/tenants';
import * as errorMessages from 'consts/errorMessages';
import { useTranslate } from 'services/i18n/useTranslate';
import { useCreatePipeline, useEditPipeline, useFullPipeline } from 'services/api/pipelines';

import { Button } from 'components/common/Button';
import { Select } from 'components/common/Select';
import { Callout } from 'components/common/Callout';
import { Heading } from 'components/common/Heading';
import { TextField } from 'components/common/TextField';
import { CodeIcon, ReaderIcon, Share1Icon } from 'components/common/Icons';
import { PipelineStatusBadge } from 'components/common/Badge/PipelineStatusBadge';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/common/Form';

import { Section } from '../../components/Section';

type PipelineData = components['schemas']['fullPipeline'];

const pipelineSchema = z.object({
  name: z.string().min(1, errorMessages.required),
  description: z.string().optional(),
  tenantId: z.string().min(1, errorMessages.required),
  source: z.object({
    name: z.string(),
    bootstrapServers: z.string().min(1, errorMessages.required),
    topicName: z
      .string()
      .min(1, { message: errorMessages.required })
      .regex(/^[a-zA-Z0-9 _-]+$/),
    type: z.literal('kafka'),
    authentication: z.discriminatedUnion('type', [
      z.object({
        type: z.literal('plain'),
        userName: z.string().min(1, errorMessages.required),
        password: z.string().min(1, errorMessages.required),
      }),
      z.object({
        type: z.literal('none'),
      }),
    ]),
  }),
  format: z.object({
    name: z.string().min(1, errorMessages.required),
    type: z.literal('json'),
  }),
  translation: z.object({
    name: z.string(),
    type: z.literal('zeek'),
  }),
});

const InfoCallout = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslate();
  return (
    <Callout variant="soft" status="info" size="1" className="mb-2" onClose={onClose}>
      {t('settings.tabs.platform.subtabs.pipelines.info_callout')}
    </Callout>
  );
};

const DataSourceConfigCallout = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslate();
  return (
    <Callout variant="outline" status="warning" size="1" className="mb-2" onClose={onClose}>
      {t('settings.tabs.platform.subtabs.pipelines.data_source_config_callout')}
    </Callout>
  );
};

type PipelineFormFields = z.infer<typeof pipelineSchema>;

type SectionContentProps = {
  columns: 1 | 2;
  children: ReactNode;
};

const SectionContent = ({ columns, children }: SectionContentProps) => {
  return <div className={cn(`flex gap-4`, columns === 1 ? 'w-[400px] flex-col' : 'w-[816px]')}>{children}</div>;
};

export const PipelineForm = ({
  pipeline,
  isLoading,
  error,
  onSubmit,
  viewMode,
  onCloseCallout,
}: {
  pipeline?: PipelineData;
  isLoading?: boolean;
  error?: components['schemas']['errorEnvelope'];
  onSubmit: (data: PipelineFormFields) => void;
  viewMode: 'create' | 'edit';
  onCloseCallout?: () => void;
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { data: tenants } = useTenants();
  const [infoCalloutVisible, setInfoCalloutVisible] = useState(viewMode === 'create');
  const [dataSourceConfigCalloutVisible, setdataSourceConfigCalloutVisible] = useState(viewMode === 'edit');

  const form = useForm<PipelineFormFields>({
    resolver: zodResolver(pipelineSchema),
    defaultValues: {
      name: pipeline?.name ?? '',
      description: pipeline?.description ?? '',
      tenantId: pipeline?.tenantId ?? '',
      source: (pipeline?.source as PipelineFormFields['source']) ?? {
        name: 'Kafka',
        bootstrapServers: '',
        topicName: 'conn log',
        type: 'kafka',
        authentication: pipeline?.source?.authentication ?? { type: 'none' },
      },
      format: pipeline?.format ?? {
        type: 'json',
        name: 'Single line JSON',
      },
      translation: pipeline?.translation ?? {
        type: 'zeek',
        name: 'OS Zeek',
      },
    },
  });

  const authType = form.watch('source.authentication.type');

  const handleCancel = () => {
    void navigate({
      search: undefined,
    });
  };

  return (
    <div className="flex h-full flex-col space-y-2">
      {viewMode === 'create' && infoCalloutVisible && <InfoCallout onClose={() => setInfoCalloutVisible(false)} />}

      <Form {...form}>
        <form className="flex flex-1 flex-col gap-2" onSubmit={form.handleSubmit(onSubmit)}>
          <Section icon={ReaderIcon} title={t('settings.tabs.platform.subtabs.pipelines.form.pipeline_information')}>
            {viewMode === 'edit' && pipeline && (
              <div>
                <Heading size="2" weight="regular">
                  {t('common.status')}
                </Heading>
                <PipelineStatusBadge status={pipeline.status} />
              </div>
            )}

            <SectionContent columns={1}>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.pipeline_name.label')}</FormLabel>
                    <FormControl>
                      <TextField
                        placeholder={t('settings.tabs.platform.subtabs.pipelines.form.pipeline_name.placeholder')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.description.label')}</FormLabel>
                    <FormControl>
                      <TextField
                        placeholder={t('settings.tabs.platform.subtabs.pipelines.form.description.placeholder')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tenantId"
                render={({ field }) => {
                  const viewTenant = tenants?.data?.find((tenant) => tenant.id === field.value);
                  return (
                    <FormItem>
                      <FormLabel>{t('common.tenant')}</FormLabel>
                      <FormControl>
                        <Select.Root defaultValue={field.value} onValueChange={field.onChange}>
                          <Select.Trigger
                            className="w-full"
                            placeholder={t('settings.tabs.platform.subtabs.pipelines.form.tenant.placeholder')}
                          />
                          <Select.Content position="popper">
                            {viewMode === 'create' &&
                              tenants?.data?.map((tenant) => (
                                <Select.Item key={tenant.id} value={tenant.id}>
                                  {tenant.name}
                                </Select.Item>
                              ))}

                            {viewMode === 'edit' && viewTenant && (
                              <Select.Item value={viewTenant.id}>{viewTenant?.name}</Select.Item>
                            )}
                          </Select.Content>
                        </Select.Root>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </SectionContent>
          </Section>

          <Section icon={Share1Icon} title={t('settings.tabs.platform.subtabs.pipelines.form.data_source.label')}>
            {viewMode === 'edit' && dataSourceConfigCalloutVisible && (
              <DataSourceConfigCallout onClose={() => setdataSourceConfigCalloutVisible(false)} />
            )}
            <SectionContent columns={2}>
              <div className="flex flex-1 flex-col gap-4">
                <FormField
                  control={form.control}
                  name="source.name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.data_source.label')}</FormLabel>
                      <FormControl>
                        <TextField
                          readOnly
                          placeholder={t('settings.tabs.platform.subtabs.pipelines.form.data_source.placeholder')}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="source.authentication.type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.authentication.label')}</FormLabel>
                      <FormControl>
                        <Select.Root value={field.value} onValueChange={field.onChange}>
                          <Select.Trigger
                            className="w-full"
                            placeholder={t('settings.tabs.platform.subtabs.pipelines.form.authentication.placeholder')}
                          />
                          <Select.Content position="popper">
                            <Select.Item value="plain">Plain</Select.Item>
                            <Select.Item value="none">None</Select.Item>
                          </Select.Content>
                        </Select.Root>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {authType === 'plain' && (
                  <>
                    <FormField
                      control={form.control}
                      name="source.authentication.userName"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>{t('common.username')}</FormLabel>
                            <FormControl>
                              <TextField placeholder={t('common.username')} className="mt-2" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name="source.authentication.password"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>{t('common.password')}</FormLabel>
                            <FormControl>
                              <TextField placeholder={t('common.password')} className="mt-2" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </>
                )}
              </div>

              <SectionContent columns={1}>
                <FormField
                  control={form.control}
                  name="source.bootstrapServers"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('settings.tabs.platform.subtabs.pipelines.form.kafka_broker_address.label')}
                      </FormLabel>
                      <FormControl>
                        <TextField
                          placeholder={t(
                            'settings.tabs.platform.subtabs.pipelines.form.kafka_broker_address.placeholder',
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="source.topicName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.topic.label')}</FormLabel>
                      <FormControl>
                        <TextField
                          placeholder={t('settings.tabs.platform.subtabs.pipelines.form.topic.placeholder')}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </SectionContent>
            </SectionContent>
          </Section>

          <Section icon={CodeIcon} title={t('settings.tabs.platform.subtabs.pipelines.form.data_type.label')}>
            <SectionContent columns={2}>
              <FormField
                control={form.control}
                name="translation.name"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.translation.label')}</FormLabel>
                    <FormControl>
                      <TextField
                        readOnly
                        placeholder={t('settings.tabs.platform.subtabs.pipelines.form.translation.placeholder')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="format.name"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t('settings.tabs.platform.subtabs.pipelines.form.format.label')}</FormLabel>
                    <FormControl>
                      <TextField
                        readOnly
                        placeholder={t('settings.tabs.platform.subtabs.pipelines.form.format.placeholder')}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </SectionContent>
          </Section>

          <div className="flex-1" />

          {error && (
            <Callout status="error" onClose={onCloseCallout} className="mb-2">
              {error?.errors?.[0]?.message}
            </Callout>
          )}

          <div className="mb-2 flex justify-end gap-2">
            <Button variant="soft" type="button" color="gray" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
            <Button isLoading={isLoading} type="submit">
              {viewMode === 'create' ? t('common.save') : t('common.update')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export type PipelineContentProps = {
  onSuccess: (pipeline: PipelineData) => void;
  viewMode: 'create';
};

export const PipelineCreateContent = ({ onSuccess, viewMode }: PipelineContentProps) => {
  const navigate = useNavigate();
  const createPipelineMutation = useCreatePipeline();

  const handleSubmit = async (data: PipelineFormFields) => {
    await createPipelineMutation.mutateAsync(
      {
        name: data.name,
        description: data.description || '',
        tenantId: data.tenantId,
        source: {
          ...data.source,
          description: '',
        },
        format: {
          ...data.format,
          description: '',
          name: 'JSON (single-record)',
        },
        translation: {
          ...data.translation,
          description: '',
          supportedLogs: ['conn', 'dns'],
        },
      },
      {
        onSuccess: (response) => {
          onSuccess(response.data);
        },
      },
    );

    void navigate({
      search: undefined,
    });
  };

  return (
    <PipelineForm
      isLoading={createPipelineMutation.isPending}
      error={createPipelineMutation.error?.json}
      onCloseCallout={createPipelineMutation.reset}
      onSubmit={handleSubmit}
      viewMode={viewMode}
    />
  );
};

export const PipelineEditContent = ({
  pipeline,
  viewMode,
}: {
  pipeline: PipelineData;
  viewMode: 'edit';
  onSuccess: PipelineContentProps['onSuccess'];
}) => {
  const editPipelineMutation = useEditPipeline(pipeline.id);
  const fullPipelineQuery = useFullPipeline(pipeline.id);

  if (fullPipelineQuery.isLoading) {
    return null;
  }

  const handleSubmit = () => {
    alert('Edit doesnt work yet.');
  };

  return (
    <PipelineForm
      isLoading={editPipelineMutation.isPending}
      error={editPipelineMutation.error?.json || fullPipelineQuery.error?.json}
      onCloseCallout={editPipelineMutation.reset}
      pipeline={fullPipelineQuery.data?.data}
      onSubmit={handleSubmit}
      viewMode={viewMode}
    />
  );
};
