import i18next from 'i18next';

import { type AvailableLanguages } from 'types/translations';

import { type Slice } from './_utils';

export type I18nSlice = {
  i18n: {
    language: AvailableLanguages;
    changeLanguage: (language: AvailableLanguages) => void;
  };
};

export const i18nSlice: Slice<I18nSlice> = {
  createSlice: (set) => ({
    i18n: {
      language: 'en',
      changeLanguage: (language: AvailableLanguages) => {
        set((prev) => ({
          i18n: {
            ...prev.i18n,
            language,
          },
        }));

        void i18next.changeLanguage(language);
      },
    },
  }),
  persist: (state) => ({
    i18n: {
      language: state.i18n.language,
    },
  }),
};
