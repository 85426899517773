import { useMemo, useState, type SVGProps } from 'react';
import { format } from 'date-fns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Rectangle,
  CartesianGrid,
  type TooltipProps as RTooltipProps,
  ReferenceArea,
} from 'recharts';

import { cn } from 'utils/styles';
import type { Prettify } from 'types/helpers';
import { timeFormatSimple, dateTimeFormatWithoutSeconds } from 'consts/dateFormats';

import { Translate } from 'components/common/Translate';

import { CHART_HEIGHT } from './consts';

const CURSOR_WIDTH = 1;
const CURSOR_DOT_SIZE = 6;

function numberOrZero<T>(value: T): number {
  return typeof value === 'number' ? value : 0;
}

const CustomCursor = (props: SVGProps<SVGRectElement>) => {
  const x = numberOrZero(props.x);
  const y = numberOrZero(props.y);
  const width = numberOrZero(props.width);
  const height = numberOrZero(props.height);

  return (
    <>
      <rect
        fill="var(--black)"
        stroke="var(--black)"
        x={x + width / 2 - CURSOR_DOT_SIZE / 2}
        y={height / 2}
        width={CURSOR_DOT_SIZE}
        height={CURSOR_DOT_SIZE}
        ry={CURSOR_DOT_SIZE}
        rx={CURSOR_DOT_SIZE}
      />
      <rect
        fill="var(--black)"
        stroke="var(--black)"
        x={x + width / 2 - CURSOR_WIDTH / 2}
        y={y}
        width={CURSOR_WIDTH}
        height={height}
      />
    </>
  );
};

const YTick = ({
  x,
  y,
  payload,
  tickFormatter,
  isLoading,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
  tickFormatter?: (value: string | number) => string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return null;
  }

  const label = payload?.value || '';
  return (
    <text x={x} y={y} dx={-20} dy={3} fontSize={12} textAnchor="middle" className="select-none fill-neutral-8">
      {tickFormatter ? tickFormatter(label) : label}
    </text>
  );
};

const XTick = ({
  x,
  y,
  payload,
  tickFormatter,
  isLoading,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
  tickFormatter?: (value: string | number) => string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return null;
  }

  const label = payload?.value || '';
  return (
    <text x={x} y={y} dy={10} fontSize={12} textAnchor="middle" className="select-none fill-neutral-11">
      {tickFormatter ? tickFormatter(label) : label}
    </text>
  );
};

export const TooltipContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="rounded-1 bg-[var(--black)] px-1 py-0.5 text-xs">{children}</div>;
};

const CHART_MARGIN = {
  top: 10,
  right: 24,
  left: -12,
  bottom: 0,
};

export type HistogramTooltipProps = RTooltipProps<string, number>;

type TimePoint = {
  time: string;
};

export type HistogramDataPoint<T extends Record<string, number>> = Prettify<
  {
    data: T;
  } & TimePoint
>;

type HistogramChartProps<T extends Record<string, number>> = {
  isLoading: boolean;
  interval: number;
  data: HistogramDataPoint<T>[];
  barDefinitions: Record<keyof T, { color: string; activeColor: string }>;
  TooltipContent?: (props: HistogramTooltipProps) => JSX.Element;
  onChange?: (startTime: string, endTime: string) => void;
};

const LOADING_POINTS = 30;

const LOADING_BAR_DEFINITIONS = { count: { color: '', activeColor: '' } };
const LOADING_DATA = Array.from({ length: LOADING_POINTS }, (_, i) => {
  return {
    time: new Date(Date.now() - (LOADING_POINTS - i) * 60 * 60 * 1000).toISOString(),
    data: {
      count: Math.floor(Math.sin(i * 0.7) * 100 + 100 + Math.random() * i * 30),
    },
  } as HistogramDataPoint<{ count: number }>;
});

const EmptyTooltip = () => null;

const initialState = {
  refAreaStart: null,
  refAreaEnd: null,
} as {
  refAreaStart: string | null;
  refAreaEnd: string | null;
};

const calculateStartAndEnd = (start: string | null, end: string | null, interval: number) => {
  if (!start || !end) {
    return null;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate.getTime() === endDate.getTime()) {
    const startDateTime = startDate;
    const endDateTime = new Date(startDate.getTime() + interval);
    return {
      startLabel: format(startDateTime, dateTimeFormatWithoutSeconds),
      startDate: startDateTime,
      endLabel: format(endDateTime, dateTimeFormatWithoutSeconds),
      endDate: endDateTime,
    };
  }

  if (startDate.getTime() > endDate.getTime()) {
    const startDateTime = endDate;
    const endDateTime = new Date(new Date(startDate).getTime() + interval);
    return {
      startLabel: format(startDateTime, dateTimeFormatWithoutSeconds),
      startDate: startDateTime,
      endLabel: format(endDateTime, dateTimeFormatWithoutSeconds),
      endDate: endDateTime,
    };
  }

  const startDateTime = startDate;
  const endDateTime = new Date(new Date(endDate).getTime() + interval);
  return {
    startLabel: format(startDateTime, dateTimeFormatWithoutSeconds),
    startDate: startDateTime,
    endLabel: format(endDateTime, dateTimeFormatWithoutSeconds),
    endDate: endDateTime,
  };
};

const calculateChartReferenceArea = (start: string | null, end: string | null) => {
  if (!start || !end) {
    return null;
  }

  if (start === end) {
    return { start, end };
  }

  if (new Date(start) > new Date(end)) {
    return { start: end, end: start };
  }

  return { start, end };
};

export function HistogramChart<T extends Record<string, number>>({
  isLoading,
  TooltipContent,
  interval,
  onChange,
  ...props
}: HistogramChartProps<T>) {
  const barDefinitions = isLoading ? LOADING_BAR_DEFINITIONS : props.barDefinitions;
  const data = isLoading ? LOADING_DATA : props.data;
  const [brushState, setBrushState] = useState({ ...initialState });

  const barDefinitionsList = useMemo(
    () =>
      Object.entries(barDefinitions).map(([dataKey, { color, activeColor }]) => ({
        dataKey: 'data.' + dataKey,
        color,
        activeColor,
      })),
    [barDefinitions],
  );

  const handleMouseDown = (e: { activeLabel?: string }) => {
    if (isLoading || !e || !e.activeLabel) {
      return;
    }
    setBrushState({
      refAreaStart: e.activeLabel,
      refAreaEnd: e.activeLabel,
    });
  };

  const handleMouseMove = (e: { activeLabel?: string }) => {
    if (isLoading || !e || !brushState.refAreaStart || !e.activeLabel) {
      return;
    }

    const currentTick = e.activeLabel;
    setBrushState((prevState) => {
      return {
        refAreaStart: prevState.refAreaStart,
        refAreaEnd: currentTick,
      };
    });
  };

  const times = calculateStartAndEnd(brushState.refAreaStart, brushState.refAreaEnd, interval);
  const refAreaTimes = calculateChartReferenceArea(brushState.refAreaStart, brushState.refAreaEnd);

  const handleMouseUp = () => {
    if (isLoading || !times) {
      return;
    }

    setBrushState({
      ...initialState,
    });

    onChange?.(times.startDate.toISOString(), times.endDate.toISOString());
  };

  return (
    <div>
      <div className="mb-0.5 ml-3 h-4 select-none text-sm text-gray-11">
        {brushState.refAreaStart && times && (
          <Translate
            tkey="histogram.timerange_selection"
            values={{
              from: times.startLabel,
              to: times.endLabel,
            }}
            components={{}}
          />
        )}
      </div>
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <BarChart
          data={data}
          margin={CHART_MARGIN}
          onMouseDown={onChange && handleMouseDown}
          onMouseMove={onChange && handleMouseMove}
          onMouseUp={onChange && handleMouseUp}
        >
          <XAxis
            dataKey={'time' satisfies keyof Pick<TimePoint, 'time'>}
            tickFormatter={(x: TimePoint['time']) => {
              return format(new Date(x), timeFormatSimple);
            }}
            tick={<XTick isLoading={isLoading} />}
          />

          <YAxis tick={<YTick isLoading={isLoading} />} />

          <CartesianGrid vertical={false} stroke="var(--neutral-8)" />

          {barDefinitionsList.map(({ dataKey, color, activeColor }) => (
            <Bar
              key={dataKey}
              isAnimationActive={!isLoading}
              dataKey={dataKey}
              stackId="histogramRangeStack"
              fill={color}
              activeBar={<Rectangle fill={activeColor} />}
            >
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={color} className={cn(isLoading && 'animate-skeleton-fill-pulse')} />
              ))}
            </Bar>
          ))}

          {refAreaTimes ? (
            <ReferenceArea
              x1={refAreaTimes.start}
              x2={refAreaTimes.end}
              fill="var(--neutral-8)"
              stroke="var(--accent-9)"
              strokeOpacity={0.5}
            />
          ) : (
            <Tooltip
              cursor={<CustomCursor />}
              content={isLoading ? EmptyTooltip : TooltipContent ? <TooltipContent /> : EmptyTooltip}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
