import { Outlet, useMatchRoute, useNavigate } from '@tanstack/react-router';

import { SubtabTrigger, Subtabs, SubtabsList } from 'components/common/Subtabs';
import { useLazyHasAccess, type Policy } from 'services/auth/useHasAccess';
import { useTranslate } from 'services/i18n/useTranslate';
import type { FlattenedTranslationKeys } from 'types/translations';

import { TenantsTabActionButtons } from './Tenants';
import { PipelinesTabActionButtons } from './Pipelines';

type RouteDefinition = {
  to: string;
  label: FlattenedTranslationKeys;
  policy: Policy;
};

const SUBTAB_ROUTES = [
  {
    to: '/settings/platform/tenants',
    label: 'common.tenants',
    policy: {
      scope: 'tenant:read',
    },
  },
  {
    to: '/settings/platform/pipelines',
    label: 'common.pipelines',
    policy: {
      scope: 'pipeline:read',
    },
  },
] as const satisfies RouteDefinition[];

export function Platform() {
  const t = useTranslate();
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();
  const getHasAccess = useLazyHasAccess();

  const matchedRoute = SUBTAB_ROUTES.find((route) => matchRoute({ to: route.to })) || SUBTAB_ROUTES[0];

  function handleSubtabChange(to: string) {
    void navigate({ to });
  }

  return (
    <Subtabs value={matchedRoute.to} onValueChange={handleSubtabChange} className="my-1">
      <div className="mr-3 flex items-center justify-between" data-test-id="settings-subtabs-container">
        <SubtabsList data-test-id="settings-subtabs">
          {SUBTAB_ROUTES.map((route) => {
            if (!getHasAccess(route.policy)) {
              return null;
            }

            return (
              <SubtabTrigger key={route.to} value={route.to}>
                {t(route.label)}
              </SubtabTrigger>
            );
          })}
        </SubtabsList>

        {matchedRoute.to === '/settings/platform/tenants' && <TenantsTabActionButtons />}
        {matchedRoute.to === '/settings/platform/pipelines' && <PipelinesTabActionButtons />}
      </div>

      <Outlet />
    </Subtabs>
  );
}
