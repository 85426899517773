import { create } from 'zustand';

import type { Prettify } from 'types/helpers';

import type { ToastProps } from 'components/common/Toast';

const DEFAULT_TOAST_DURATION = 2500;
const REMOVE_TOAST_DURATION = 500;

const generateId = (() => {
  let count = 0;

  return () => {
    count = (count + 1) % Number.MAX_SAFE_INTEGER;
    return count.toString();
  };
})();

type ToasterToast = Prettify<
  {
    id: string;
  } & Pick<ToastProps, 'open' | 'onOpenChange' | 'duration' | 'status' | 'content'>
>;

type State = {
  toasts: ToasterToast[];
  addToast: (toast: AddToastArgument) => void;
};

type AddToastArgument = Pick<ToasterToast, 'status' | 'content' | 'duration'>;

export const useToasterStore = create<State>((set) => ({
  toasts: [],
  addToast: (props) => {
    set((state) => {
      const id = generateId();

      const toast = {
        ...props,
        id,
        open: true,
        duration: props.duration || DEFAULT_TOAST_DURATION,
        onOpenChange: (open: boolean) => {
          if (!open) {
            set((state) => {
              return {
                ...state,
                toasts: state.toasts.map((t) => (t.id === id ? { ...t, open: false } : t)),
              };
            });
          }
        },
      };

      // Close the toast after the duration but keep it in the list for the exit animation
      setTimeout(() => {
        toast.onOpenChange(false);

        // Remove the toast from the list after the exit animation
        setTimeout(() => {
          set((state) => {
            return {
              ...state,
              toasts: state.toasts.filter((t) => t.id !== id),
            };
          });
        }, REMOVE_TOAST_DURATION);
      }, toast.duration);

      return {
        ...state,
        toasts: [toast, ...state.toasts],
      };
    });
  },
}));

export function toast(toast: AddToastArgument) {
  useToasterStore.getState().addToast(toast);
}
