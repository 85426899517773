import { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { useLocation } from '@tanstack/react-router';
import { zodResolver } from '@hookform/resolvers/zod';

import { toast } from 'utils/toast';
import * as errorMessages from 'consts/errorMessages';
import { useTranslate } from 'services/i18n/useTranslate';
import { useCreateSavedView } from 'services/api/settings';

import { Drawer } from 'components/common/Drawer';
import { Button } from 'components/common/Button';
import { PlusIcon } from 'components/common/Icons';
import { TextField } from 'components/common/TextField';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/common/Form';

const filterSchema = z.object({
  name: z.string().min(1, errorMessages.required),
});

type FilterForm = z.infer<typeof filterSchema>;

export function SaveFiltersButton({ isDisabled }: { isDisabled: boolean }) {
  const t = useTranslate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<FilterForm>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(filterSchema),
  });

  const createSavedViewMutation = useCreateSavedView('threats');

  const handleSubmit = (data: FilterForm) => {
    createSavedViewMutation.mutate(
      { ...data, query: location.searchStr },
      {
        onSuccess: () => {
          setIsOpen(false);
          form.reset();

          toast({
            status: 'success',
            content: t('threats.saved_filters.save_success_toast', {
              name: data.name,
            }),
          });
        },
        onError: (error) => {
          if (error.json.errors?.[0]?.context) {
            form.setError(error.json.errors[0].context as keyof FilterForm, {
              type: 'manual',
              message: error.json.errors[0].errorMsg,
            });
          } else {
            form.setError('name', {
              type: 'manual',
              message: error.json.errors?.[0]?.errorMsg || error.json.errors?.[0]?.message || error.message,
            });
          }
        },
      },
    );
  };

  return (
    <>
      <Button size="1" variant="soft" disabled={isDisabled} onClick={() => setIsOpen(true)}>
        <PlusIcon />
        {t('threats.saved_filters.save_filters')}
      </Button>

      <Drawer
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        mode="modal"
        side="right"
        title={t('threats.saved_filters.save_modal_title')}
        description={t('threats.saved_filters.save_modal_description')}
      >
        <Form {...form}>
          <form className="flex h-full flex-col" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => {
                return (
                  <FormItem className="w-full">
                    <FormLabel required>{t('common.name')}</FormLabel>
                    <FormControl>
                      <TextField {...field} placeholder={t('threats.saved_filters.name_placeholder')} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <div className="flex-1" />

            <div className="flex justify-end gap-2">
              <Button color="gray" variant="soft" type="button">
                {t('common.cancel')}
              </Button>
              <Button type="submit" isLoading={createSavedViewMutation.isPending}>
                {t('common.save')}
              </Button>
            </div>
          </form>
        </Form>
      </Drawer>
    </>
  );
}
