import { forwardRef, useState } from 'react';

import { TextField, type TextFieldProps } from 'components/common/TextField';
import { IconButton } from 'components/common/IconButton';
import { EyeNoneIcon, EyeOpenIcon } from 'components/common/Icons';

import { useTranslate } from 'services/i18n/useTranslate';

type PasswordFieldProps = Omit<TextFieldProps, 'rightIcon' | 'type'>;

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>((props, ref) => {
  const t = useTranslate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      ref={ref}
      rightIcon={
        <IconButton
          type="button"
          variant="ghost"
          color="gray"
          onClick={() => setPasswordVisible((x) => !x)}
          aria-label={passwordVisible ? t('common.hide_password') : t('common.show_password')}
        >
          {passwordVisible ? <EyeNoneIcon aria-hidden /> : <EyeOpenIcon aria-hidden />}
        </IconButton>
      }
      type={passwordVisible ? 'text' : 'password'}
      {...props}
    />
  );
});
