import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import { fullDateTimeFormat } from 'consts/dateFormats';
import { useTenants } from 'services/api/tenants';
import { useCurrentUser } from 'services/api/auth';
import { usePipelines } from 'services/api/pipelines';
import { useTranslate } from 'services/i18n/useTranslate';
import { useHasAccess } from 'services/auth/useHasAccess';
import { useIsMutatingThreatWorkflow, useThreatAssignableUsers, useUpdateThreatWorkflow } from 'services/api/threats';
import type { components } from 'types/schemas/api-schema';
import type { FlattenedTranslationKeys } from 'types/translations';
import { cn } from 'utils/styles';
import { extractEntitiesFromThreat } from 'utils/detections';

import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { Badge } from 'components/common/Badge';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { Skeleton } from 'components/common/Skeleton';
import { Breadcrumb } from 'components/common/Breadcrumb';
import { CardStackIcon, LoadingIcon, PersonIcon } from 'components/common/Icons';

import {
  ThreatEntityBadge,
  ThreatPipelineBadge,
  ThreatRiskScoreBadge,
  ThreatTenantBadge,
} from '../Threats/common/ThreatsBadges';

const WORKFLOW_STATUS_OPTIONS: {
  value: 'open' | 'under_review' | 'acknowledged' | 'closed';
  tkey: FlattenedTranslationKeys;
}[] = [
  { value: 'open', tkey: 'common.workflow_status_options.open' },
  { value: 'under_review', tkey: 'common.workflow_status_options.under_review' },
  { value: 'acknowledged', tkey: 'common.workflow_status_options.acknowledged' },
  { value: 'closed', tkey: 'common.workflow_status_options.closed' },
];

const WorkflowStatusSelect = ({ threat }: { threat: components['schemas']['threat'] }) => {
  const t = useTranslate();
  const updateThreatWorkflowStatusMutation = useUpdateThreatWorkflow(threat.id);
  const hasAccessToChangeWorkflow = useHasAccess({ scope: 'threat-workflow:write', tenantId: threat.tenantId });
  const isThreatBeingMutated = useIsMutatingThreatWorkflow(threat.id);

  const currentLabel = WORKFLOW_STATUS_OPTIONS.find((option) => option.value === threat?.workflow?.status)?.tkey;

  const handleSelectStatus = (status: string) => {
    updateThreatWorkflowStatusMutation.mutate({
      status,
    });
  };

  const options = useMemo(() => {
    if (threat.inactiveAt) {
      return WORKFLOW_STATUS_OPTIONS;
    }

    return WORKFLOW_STATUS_OPTIONS.filter((option) => option.value !== 'closed');
  }, [threat.inactiveAt]);

  return (
    <Select.Root
      defaultValue={threat?.workflow?.status}
      onValueChange={handleSelectStatus}
      size="2"
      disabled={!hasAccessToChangeWorkflow || isThreatBeingMutated}
    >
      <Select.Trigger variant="soft" color="gray" className="w-full">
        <div className="flex w-full items-center gap-2">{currentLabel && t(currentLabel)}</div>
      </Select.Trigger>
      <Select.Content position="popper">
        {options.map((option) => (
          <Select.Item key={option.value} value={option.value}>
            <div className="flex items-center gap-2">
              <CardStackIcon />
              {t(option.tkey)}
              {updateThreatWorkflowStatusMutation.isPending && <LoadingIcon className="size-3 animate-spin" />}
            </div>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const UNASSIGNED_USER = 'unassigned';

const AssignUserSelect = ({ isLoading, threat }: { isLoading?: boolean; threat: components['schemas']['threat'] }) => {
  const t = useTranslate();
  const [value, setValue] = useState<string>(threat.workflow?.assignedTo || UNASSIGNED_USER);
  const currentUser = useCurrentUser();
  const hasAccessToChangeWorkflow = useHasAccess({ scope: 'threat-workflow:write', tenantId: threat.tenantId });
  const updateThreatWorkflowStatusMutation = useUpdateThreatWorkflow(threat.id);
  const assignableUsersQuery = useThreatAssignableUsers(
    hasAccessToChangeWorkflow && !isLoading ? threat.id : undefined,
  );
  const isThreatBeingMutated = useIsMutatingThreatWorkflow(threat.id);

  useEffect(() => {
    setValue(threat.workflow?.assignedTo || UNASSIGNED_USER);
  }, [threat.workflow.assignedTo]);

  const isLoadingCombined = isLoading || assignableUsersQuery.isLoading;

  const options = useMemo(() => {
    if (assignableUsersQuery.isError) {
      return [
        {
          value: threat.workflow.assignedTo || UNASSIGNED_USER,
          label: `Error: ${assignableUsersQuery.error?.json?.errors?.[0]?.message}`,
        },
      ];
    }

    if (assignableUsersQuery.isLoading) {
      return [
        {
          value: threat.workflow?.assignedTo || UNASSIGNED_USER,
          label: 'Loading...',
        },
      ];
    }

    return [
      {
        value: UNASSIGNED_USER,
        label: t('common.unassigned'),
      },
      ...(assignableUsersQuery.data?.data?.users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) || []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignableUsersQuery.data?.data?.users,
    assignableUsersQuery.error?.json?.errors,
    assignableUsersQuery.isError,
    assignableUsersQuery.isLoading,
    threat.workflow?.assignedTo,
  ]);

  const handleAssignUser = (userId: string) => {
    setValue(userId);

    updateThreatWorkflowStatusMutation.mutate({
      assignedTo: userId === UNASSIGNED_USER ? null : userId,
    });
  };

  return (
    <div>
      <Skeleton isLoading={isLoadingCombined}>
        <div>
          <Select.Root
            value={value}
            defaultValue={threat?.workflow?.assignedTo || 'unassigned'}
            onValueChange={(val) => handleAssignUser(val)}
            size="2"
            disabled={!hasAccessToChangeWorkflow || isThreatBeingMutated}
          >
            <Select.Trigger variant="soft" color="gray" className="w-full" />
            <Select.Content position="popper">
              {options.map((option) => (
                <Select.Item key={option.value} value={option.value} className="group">
                  <div className="flex items-center gap-2">
                    <PersonIcon />
                    <div
                      className={cn(
                        'max-w-[90%] overflow-hidden text-ellipsis whitespace-nowrap group-[.group]:max-w-none',
                        updateThreatWorkflowStatusMutation.isPending && 'max-w-[80%]',
                      )}
                    >
                      {option.label}
                    </div>
                    {updateThreatWorkflowStatusMutation.isPending && <LoadingIcon className="size-3 animate-spin" />}
                  </div>
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </div>
      </Skeleton>

      {hasAccessToChangeWorkflow && (
        <Skeleton isLoading={isLoadingCombined}>
          <div className="inline-flex items-baseline border-b border-accent-7 hover:border-accent-8">
            <Button
              variant="ghost"
              size="1"
              className="font-light hover:bg-transparent"
              onClick={() => handleAssignUser(currentUser.data?.data.id || UNASSIGNED_USER)}
            >
              {t('threats.details_sidebar.assign_to_me')}
            </Button>
          </div>
        </Skeleton>
      )}
    </div>
  );
};

const FieldLabel = ({ children }: { children: React.ReactNode }) => {
  return <Label className="!mb-1.5 block font-medium">{children}</Label>;
};

type SidebarInfoPanel = {
  isLoading?: boolean;
  threat: components['schemas']['threat'];
};

export const ThreatMetadataPanel = ({ isLoading, threat }: SidebarInfoPanel) => {
  const t = useTranslate();

  const tenantsQueryData = useTenants();
  const pipelinesQueryData = usePipelines();

  const entities = useMemo(() => {
    return extractEntitiesFromThreat(threat);
  }, [threat]);

  const firstEvidenceCreatedAt = threat.evidence[0]!.createdAt;
  const lastEvidenceCreatedAt = threat.evidence[threat.evidence.length - 1]?.createdAt;

  const shouldDisplayLastEvidence = lastEvidenceCreatedAt && firstEvidenceCreatedAt !== lastEvidenceCreatedAt;

  const isDetailsLoading = isLoading || tenantsQueryData.isLoading || pipelinesQueryData.isLoading;

  const getPipelineName = (pipelineId: string) => {
    return (pipelinesQueryData.data?.data || [])?.find((pipeline) => pipeline.id === pipelineId)?.name ?? pipelineId;
  };

  const getTenantName = (tenantId: string) => {
    return (tenantsQueryData.data?.data || [])?.find((tenant) => tenant.id === tenantId)?.name ?? tenantId;
  };

  return (
    <div className="flex flex-1 flex-col gap-2 border-b border-l border-r border-neutral-a3 bg-neutral-4 p-4">
      <Breadcrumb />

      <Skeleton isLoading={isDetailsLoading}>
        <Text size="4">{threat.name}</Text>
      </Skeleton>

      <div className="space-y-4 py-4">
        <div>
          <div className="flex justify-between">
            <FieldLabel>{t('common.workflow_status')}</FieldLabel>
          </div>
          <Skeleton isLoading={isDetailsLoading}>
            <div>
              <WorkflowStatusSelect threat={threat} />
            </div>
          </Skeleton>
        </div>

        <div>
          <div className="flex justify-between">
            <FieldLabel>{t('threats.details_sidebar.assignee')}</FieldLabel>
          </div>
          <AssignUserSelect isLoading={isLoading} threat={threat} />
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <FieldLabel>{t('common.risk_score')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <ThreatRiskScoreBadge riskScore={threat.riskScore.score} />
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.tenant')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <ThreatTenantBadge>{getTenantName(threat.tenantId)}</ThreatTenantBadge>
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.pipeline')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            {threat.pipelineIds.map((pipelineId) => (
              <ThreatPipelineBadge key={pipelineId}>{getPipelineName(pipelineId)}</ThreatPipelineBadge>
            ))}
          </Skeleton>
        </div>

        <div>
          <FieldLabel>{t('common.entities')}</FieldLabel>
          <div className="grid place-items-start gap-2">
            {entities.map((entity) => {
              return (
                <Skeleton key={entity.value} isLoading={isDetailsLoading}>
                  <ThreatEntityBadge entity={entity} />
                </Skeleton>
              );
            })}
          </div>
        </div>

        <div>
          <FieldLabel>{t('threats.details_sidebar.time.label')}</FieldLabel>
          <div className="space-y-2">
            <Skeleton isLoading={isDetailsLoading}>
              <Badge variant="surface" color="gray">
                {t('threats.details_sidebar.time.first_evidence', {
                  date: format(new Date(firstEvidenceCreatedAt), fullDateTimeFormat),
                })}
              </Badge>
            </Skeleton>
            <Skeleton isLoading={isDetailsLoading}>
              <Badge variant="surface" color="gray">
                {t('threats.details_sidebar.time.threat_created', {
                  date: format(new Date(threat.createdAt), fullDateTimeFormat),
                })}
              </Badge>
            </Skeleton>
            {shouldDisplayLastEvidence && (
              <Skeleton isLoading={isDetailsLoading}>
                <Badge variant="surface" color="gray">
                  {t('threats.details_sidebar.time.last_evidence', {
                    date: format(new Date(lastEvidenceCreatedAt), fullDateTimeFormat),
                  })}
                </Badge>
              </Skeleton>
            )}
            {threat.inactiveAt && (
              <Skeleton isLoading={isDetailsLoading}>
                <Badge variant="surface" color="gray">
                  {t('threats.details_sidebar.time.threat_complete', {
                    date: format(new Date(threat.inactiveAt), fullDateTimeFormat),
                  })}
                </Badge>
              </Skeleton>
            )}
          </div>
        </div>

        <div>
          <FieldLabel>{t('threats.details_sidebar.id')}</FieldLabel>
          <Skeleton isLoading={isDetailsLoading}>
            <Badge highContrast color="gray">
              {threat.id}
            </Badge>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
