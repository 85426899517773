import * as React from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as ToastPrimitives from '@radix-ui/react-toast';

import { cn } from 'utils/styles';

import { Callout } from 'components/common/Callout';
import { IconButton } from 'components/common/IconButton';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'fixed left-[50%] top-[10%] z-[1000] flex max-h-screen w-full -translate-x-1/2 flex-col gap-2 md:max-w-[445px]',
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  Omit<
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & React.ComponentProps<typeof Callout>,
    'children' | 'content'
  > & {
    content: React.ReactNode;
  }
>(({ status, content, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      {...props}
      className="w-full rounded-lg bg-[--white] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-20 data-[state=closed]:slide-out-to-top-24 data-[state=open]:slide-in-from-bottom-10 dark:bg-[--black]"
    >
      <Callout
        status={status}
        customCloseButton={
          <ToastPrimitives.Close asChild>
            <IconButton type="button" size="1" variant="ghost" toast-close="" className={cn('!mt-0 !text-neutral-12')}>
              <Cross2Icon />
            </IconButton>
          </ToastPrimitives.Close>
        }
      >
        {content}
      </Callout>
    </ToastPrimitives.Root>
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

export { type ToastProps, ToastProvider, ToastViewport, Toast };
