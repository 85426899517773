import { useState } from 'react';
import { MinusCircledIcon } from '@radix-ui/react-icons';
import { defaultParseSearch, useNavigate } from '@tanstack/react-router';

import { toast } from 'utils/toast';
import { useTranslate } from 'services/i18n/useTranslate';
import { useDeleteSavedView, useSavedViews } from 'services/api/settings';

import { Button } from 'components/common/Button';
import { Skeleton } from 'components/common/Skeleton';
import { Translate } from 'components/common/Translate';
import { IconButton } from 'components/common/IconButton';
import { AlertDialog } from 'components/common/AlertDialog';

const SavedView = ({
  isLoading,
  id,
  name,
  query,
}: {
  isLoading?: boolean;
  id: string;
  name: string;
  query: string;
}) => {
  const t = useTranslate();
  const navigate = useNavigate({ from: '/threats' });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deleteSavedViewMutation = useDeleteSavedView('threats', id);

  const handleClickOnSavedView = () => {
    void navigate({ search: defaultParseSearch(query) });
  };

  const handleDelete = () => {
    deleteSavedViewMutation.mutate(undefined, {
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        toast({
          status: 'success',
          content: t('threats.saved_filters.delete_success_toast', { name }),
        });
      },
    });
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4 px-2">
        <Skeleton isLoading={isLoading}>
          <Button
            size="1"
            variant="ghost"
            color="gray"
            className="flex-1 !justify-start"
            onClick={handleClickOnSavedView}
            disabled={isLoading}
          >
            {name}
          </Button>
        </Skeleton>

        <IconButton
          disabled={isLoading}
          size="1"
          variant="ghost"
          color="gray"
          aria-label={t('threats.tabs.delete_filter_label')}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <MinusCircledIcon />
        </IconButton>
      </div>

      <AlertDialog.Root open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <AlertDialog.Content size="2" className="!max-w-[300px]">
          <AlertDialog.Title size="3">{t('threats.saved_filters.delete_modal_title')}</AlertDialog.Title>
          <AlertDialog.Description size="2">
            <Translate
              tkey="threats.saved_filters.delete_modal_description"
              values={{ name }}
              components={{ em: <em /> }}
            />
          </AlertDialog.Description>

          <div className="mt-3 flex justify-end gap-2">
            <Button size="1" variant="soft" color="gray" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>

            <Button size="1" color="red" isLoading={deleteSavedViewMutation.isPending} onClick={handleDelete}>
              Confirm
            </Button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};

export function SavedFilters() {
  const savedViewsQuery = useSavedViews('threats');

  if (savedViewsQuery.isLoading) {
    return (
      <div className="mx-1.5 mt-4 space-y-1.5">
        {[...new Array<number>(10)].map((_, index) => (
          <SavedView key={index} isLoading id="loading" name="Loading..." query="" />
        ))}
      </div>
    );
  }

  if (!savedViewsQuery.data?.data.length) {
    return (
      <div className="mt-4 text-center text-sm">
        <Translate tkey="threats.tabs.no_saved_filters" components={{}} />
      </div>
    );
  }

  return (
    <div className="mx-1.5 mt-4 space-y-1.5">
      {savedViewsQuery.data?.data.map((savedView) => {
        return <SavedView key={savedView.id} id={savedView.id} name={savedView.name} query={savedView.query} />;
      })}
    </div>
  );
}
