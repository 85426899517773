import { useState } from 'react';
import { format } from 'date-fns';
import type { SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table';

import { type components } from 'types/schemas/api-schema';

import { dateFormatMedium } from 'consts/dateFormats';
import { useRoles } from 'services/api/roles';
import { useTranslate } from 'services/i18n/useTranslate';

import { Table, SortableColumnHeaderCell } from 'components/common/Table';
import { Text } from 'components/common/Text';
import { Heading } from 'components/common/Heading';
import { ReadScopeBadge, WriteScopeBadge, ScopeBadges } from 'components/common/Badge/ScopeBadge';

const roleColumnHelper = createColumnHelper<components['schemas']['role']>();

export function ManageRoles() {
  const t = useTranslate();
  const rolesQuery = useRoles();
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = [
    roleColumnHelper.accessor('name', {
      header: () => <Text>{t('settings.tabs.usersAndAccess.subtabs.manage_roles.table.headers.role')}</Text>,
      cell: (info) => (
        <div className="flex flex-col">
          <Text>{info.row.original.name}</Text>
          <Text className="text-neutral-a11">{info.row.original.description}</Text>
        </div>
      ),
    }),
    roleColumnHelper.accessor('scopes', {
      header: () => <Text>{t('settings.tabs.usersAndAccess.subtabs.manage_roles.table.headers.permission')}</Text>,
      cell: (info) => <ScopeBadges scopes={info.getValue()} />,
      enableSorting: false,
    }),
    roleColumnHelper.accessor('updatedAt', {
      header: () => <Text className="whitespace-nowrap">{t('common.last_updated')}</Text>,
      cell: (info) => <Text>{format(info.getValue(), dateFormatMedium)}</Text>,
    }),
  ];

  const table = useReactTable({
    data: rolesQuery.data?.data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (rolesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (rolesQuery.isError) {
    return <div>Error...</div>;
  }

  return (
    <div className="mt-3 p-3">
      <Heading size="3" weight="medium" className="!mb-3 text-neutral-a11">
        {t('settings.tabs.usersAndAccess.subtabs.manage_roles.title')}
      </Heading>

      <Table.Root variant="surface" data-test-id="settings-roles-table">
        <Table.Header className="bg-neutral-a2">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <SortableColumnHeaderCell key={header.id} header={header} />
              ))}
            </Table.Row>
          ))}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map((row) => {
            return (
              <Table.Row key={row.id} align="center">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>

      <div className="mt-4 flex gap-2">
        <ReadScopeBadge scopeName={t('settings.common.read_access')} />
        <WriteScopeBadge scopeName={t('settings.common.write_access')} />
      </div>
    </div>
  );
}
