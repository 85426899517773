import type { ReactNode } from 'react';

import { useHasAccess, type Policy } from 'services/auth/useHasAccess';

type AuthorizeProps = {
  policy: Policy;
  children: ReactNode;
  fallback?: ReactNode;
};

export function Authorize({ policy, children, fallback = null }: AuthorizeProps) {
  const hasAccess = useHasAccess(policy);

  return hasAccess ? children : fallback;
}
