import { useState } from 'react';

import { useTranslate } from 'services/i18n/useTranslate';
import { useDeleteUser } from 'services/api/users';

import { Button } from 'components/common/Button';
import { Callout } from 'components/common/Callout';
import { Checkbox } from 'components/common/Checkbox';
import { InlineDialog } from 'components/common/InlineDialog';

type DeleteUserInlineDialogProps = {
  userId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const DeleteUserInlineDialog = ({ userId, onCancel, onSuccess }: DeleteUserInlineDialogProps) => {
  const t = useTranslate();
  const [isChecked, setIsChecked] = useState(false);
  const deleteUserMutation = useDeleteUser(userId);

  const handleDeleteUser = () => {
    deleteUserMutation.mutate(undefined, {
      onSuccess,
    });
  };

  return (
    <InlineDialog
      title={t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.delete_user_prompt.title')}
      className="mb-3"
      footer={
        <>
          <Button size="1" color="gray" variant="soft" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            size="1"
            color="red"
            disabled={!isChecked}
            isLoading={deleteUserMutation.isPending}
            onClick={handleDeleteUser}
          >
            {t('common.permanently_delete')}
          </Button>
        </>
      }
    >
      <label className="mt-3 flex items-center gap-2">
        <Checkbox onCheckedChange={(checked: boolean) => setIsChecked(checked)} />
        {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.delete_user_prompt.confirm_text')}
      </label>

      {deleteUserMutation.isError && (
        <Callout className="mt-3" variant="soft" status="error" size="1" onClose={() => deleteUserMutation.reset()}>
          {t('settings.tabs.usersAndAccess.subtabs.manage_users.sidebar.delete_user_prompt.error')}
        </Callout>
      )}
    </InlineDialog>
  );
};
